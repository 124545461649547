import {
    GET_REPORTED_USERS, GET_REPORTEDUSERS_SUCCESS, GET_REPORTEDUSERS_FAILURE,
    GET_REPORTEDPROFILES, GET_REPORTEDPROFILES_SUCCESS,
    IGNORE_USERREPORT, IGNORE_USERREPORT_SUCCESS,
    GET_REPORTEDPCOMMENTS, GET_REPORTEDPCOMMENTS_SUCCESS,
    IGNORE_REPORTEDPROFILE, IGNORE_REPORTEDCOMMENTS,
    BAN_USER, DELETE_PROFILE, DELETE_COMMENT
} from '../constants'

export default class ReportedUser_Actions {

    static Get_ReportedUsers(payload) {

        return {
            type: GET_REPORTED_USERS,
            data: payload
        }
    }

    static Get_ReportedUsers_Success(payload) {

        return {
            type: GET_REPORTEDUSERS_SUCCESS,
            payload
        }
    }

    // ----------------IGNORE USER REPORT------------------

    static Ignore_UserReport(payload) {

        return {
            type: IGNORE_USERREPORT,
            data: payload
        }
    }

    // --------------BAN USER ------------------

    static Ban_User(payload) {

        return {
            type: BAN_USER,
            data: payload
        }
    }

    static BAN_USER_SUCCESS(payload) {

        return {
            type: BAN_USER,
            data: payload
        }
    }

    // --------------REPORTED PROFILES ------------------

    static Get_ReportedProfile(payload) {

        return {
            type: GET_REPORTEDPROFILES,
            data: payload
        }
    }

    static Get_ReportedProfile_Success(payload) {

        return {
            type: GET_REPORTEDPROFILES_SUCCESS,
            payload
        }
    }

    static Ignore_ProfileReport(payload) {

        return {
            type: IGNORE_REPORTEDPROFILE,
            data: payload
        }
    }

    static Delete_Profile(payload) {

        return {
            type: DELETE_PROFILE,
            data: payload
        }
    }

    // --------------REPORTED COMMENTS ------------------

    static Get_ReportedComments(payload) {

        return {
            type: GET_REPORTEDPCOMMENTS,
            data: payload
        }
    }

    static Get_ReportedComments_Success(payload) {

        return {
            type: GET_REPORTEDPCOMMENTS_SUCCESS,
            payload
        }
    }

    static Ignore_CommentsReport(payload) {

        return {
            type: IGNORE_REPORTEDCOMMENTS,
            data: payload
        }
    }

    static Delete_Comment(payload) {

        return {
            type: DELETE_COMMENT,
            data: payload
        }
    }


}