export const SIGNIN = "SIGNIN"
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS"
export const SIGNIN_FAILURE = "SIGNIN_FAILURE"

export const LOGOUT = "LOGOUT"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAILURE = "LOGOUT_FAILURE"

export const FORGETPASSWORD = "FORGETPASSWORD"
export const FORGETPASSWORD_SUCCESS = "FORGETPASSWORD_SUCCESS"
export const FORGETPASSWORD_FAILURE = "FORGETPASSWORD_FAILURE"

export const RESETPASSWORD = "RESETPASSWORD"
export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS"
export const RESETPASSWORD_FAILURE = "RESETPASSWORD_FAILURE"

export const VERIFYCODE = "VERIFYCODE"
export const VERIFYCODE_SUCCESS = "VERIFYCODE_SUCCESS"
export const VERIFYCODE_FAILURE = "VERIFYCODE_FAILURE"

export const GETUSERSCARDCOUNT = "GETUSERSCARDCOUNT"
export const GETUSERSCARDCOUNT_SUCCESS = "GETUSERSCARDCOUNT_SUCCESS"
export const GETUSERSCARDCOUNT_FAILURE = "GETUSERSCARDCOUNT_FAILURE"

export const GETORDERSCARDCOUNT = "GETORDERSCARDCOUNT"
export const GETORDERSCARDCOUNT_SUCCESS = "GETORDERSCARDCOUNT_SUCCESS"
export const GETORDERSCARDCOUNT_FAILURE = "GETORDERSCARDCOUNT_FAILURE"



export const USER_GRAPH = "USER_GRAPH"
export const USER_GRAPH_SUCCESS = "USER_GRAPH_SUCCESS"
export const USER_GRAPH_ERROR = "USER_GRAPH_ERROR"

export const ANDROID_GRAPH = "ANDROID_GRAPH"
export const ANDROID_GRAPH_SUCCESS = "ANDROID_GRAPH_SUCCESS"
export const ANDROID_GRAPH_ERROR = "ANDROID_GRAPH_ERROR"

export const LOADER_TRUE = 'LOADER_TRUE';
export const LOADER_FALSE = 'LOADER_FALSE';

// -----------------------------------------------------------------------------------------
export const GETUSERS = "GETUSERS"
export const GETUSERS_SUCCESS = "GETUSERS_SUCCESS"
export const GETUSERS_FAILURE = "GETUSERS_FAILURE"

export const DELETE_USER_PROFILE = "DELETE_USER_PROFILE"
export const DELETE_USERPROFILE_SUCCESS = "DELETE_USERPROFILE_SUCCESS"
export const DELETE_USERPROFILE_FAILURE = "DELETE_USERPROFILE_FAILURE"

export const CREATE_NEWS = "CREATE_NEWS"
export const CREATENEWS_SUCCESS = "CREATENEWS_SUCCESS"
export const CREATENEWS_FAILURE = " CREATENEWS_FAILURE"

export const GET_NEWS = "GET_NEWS"
export const GETNEWS_SUCCESS = "GETNEWS_SUCCESS"
export const GETNEWS_FAILURE = "GETNEWS_FAILURE"

export const GETNEWS_DETAILS = "GETNEWS_DETAILS"
export const GETNEWSDETAILS_SUCCESS = "GETNEWSDETAILS_SUCCESS"
export const GETNEWSDETAILS_FAILURE = "GETNEWSDETAILS_FAILURE"

export const DELETE_NEWS = "DELETE_NEWS"
export const DELETENEWS_SUCCESS = "DELETENEWS_SUCCESS"
export const DELETENEWS_FAILURE = "DELETENEWS_FAILURE"

export const GETUSERDETAILS = "GETSINGLE_USER"
export const GETUSERDETAILS_SUCCESS = "GETSINGLEUSER_SUCCESS"
export const GETUSERDETAILS_FAILURE = "GETSINGLEUSER_FAILURE"

export const GETSINGLE_USER = "GETSINGLE_USER"
export const GETSINGLEUSER_SUCCESS = "GETSINGLEUSER_SUCCESS"
export const GETSINGLEUSER_FAILURE = "GETSINGLEUSER_FAILURE"

export const UPDATEUSER_STATUS = "UPDATEUSER_STATUS"
export const UPDATEUSERSTATUS_SUCCESS = "UPDATEUSERSTATUS_SUCCESS"
export const UPDATEUSERSTATUS_FAILURE = "UPDATEUSERSTATUS_FAILURE"

export const GET_MESSAGES = "GET_MESSAGES"
export const GETMESSAGES_SUCCESS = "GETMESSAGES_SUCCESS"
export const GETMESSAGES_FAILURE = "GETMESSAGES_FAILURE"

export const DELETE_MESSAGE = "DELETE_MESSAGE"
export const DELETEMESSAGE_SUCCESS = "DELETEMESSAGE_SUCCESS"
export const DELETEMESSAGE_FAILURE = "DELETEMESSAGE_FAILURE"

export const SEND_MESSAGE = "SEND_MESSAGE"
export const SENDMESSAGE_SUCCESS = "SENDMESSAGE_SUCCESS"
export const SENDMESSAGE_FAILURE = "SENDMESSAGE_FAILURE"

export const GET_REPORTED_USERS = "GET_REPORTED_USERS"
export const GET_REPORTEDUSERS_SUCCESS = "GET_REPORTEDUSERS_SUCCESS"
export const GET_REPORTEDUSERS_FAILURE = "GET_REPORTEDUSERS_FAILURE"

export const GET_REPORTEDPROFILES = "GET_REPORTEDPROFILES"
export const GET_REPORTEDPROFILES_SUCCESS = "GET_REPORTEDPROFILES_SUCCESS"
export const GET_REPORTEDPROFILES_FAILURE = "GET_REPORTEDPROFILES_FAILURE"

export const IGNORE_USERREPORT = "IGNORE_USERREPORT"
export const IGNORE_USERREPORT_SUCCESS = "IGNORE_USERREPORT_SUCCESS"
export const IGNORE_USERREPORT_FAILURE = "IGNORE_USERREPORT_FAILURE"

export const IGNORE_REPORTEDPROFILE = "IGNORE_REPORTEDPROFILE"
export const IGNORE_REPORTEDPROFILE_SUCCESS = "IGNORE_REPORTEDPROFILE_SUCCESS"
export const IGNORE_REPORTEDPROFILE_FAILURE = "IGNORE_REPORTEDPROFILE_FAILURE"

export const GET_REPORTEDPCOMMENTS = "GET_REPORTEDPCOMMENTS"
export const GET_REPORTEDPCOMMENTS_SUCCESS = "GET_REPORTEDPCOMMENTS_SUCCESS"
export const GET_REPORTEDPCOMMENTS_FAILURE = "GET_REPORTEDPCOMMENTS_FAILURE"

export const IGNORE_REPORTEDCOMMENTS = "IGNORE_REPORTEDCOMMENTS"
export const IGNORE_REPORTEDCOMMENTS_SUCCESS = "IGNORE_REPORTEDCOMMENTS_SUCCESS"
export const IGNORE_REPORTEDCOMMENTS_FAILURE = "IGNORE_REPORTEDCOMMENTS_FAILURE"

export const BAN_USER = "BAN_USER"
export const BAN_USER_SUCCESS = "BAN_USER_SUCCESS"
export const BAN_USER_FAILURE = "BAN_USER_FAILURE"

export const DELETE_PROFILE = "DELETE_PROFILE"
export const DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS"
export const DELETE_PROFILE_FAILURE = "DELETE_PROFILE_FAILURE"

export const DELETE_COMMENT = "DELETE_COMMENT"
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS"
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE"

export const EDIT_NEWS = "EDIT_NEWS"
export const EDIT_NEWS_SUCCESS = "EDIT_NEWS_SUCCESS"
export const EDIT_NEWS_FAILURE = "EDIT_NEWS_FAILURE"

export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE"
