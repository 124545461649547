import {
    GET_MESSAGES, GETMESSAGES_SUCCESS, DELETE_MESSAGE, SEND_MESSAGE
} from '../constants';

const initialState = {
    isLoading: false,
    messages: [],
    meta_data: {}
}

export default function MessageReducer(state = initialState, action) {

    switch (action.type) {
        case GET_MESSAGES:
            state = {
                ...state,
                isLoading: true
            }
            break;

        case GETMESSAGES_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                messages: action.payload,
                meta_data: action.meta_data

            }
            break;

        case DELETE_MESSAGE:
            state = {
                ...state,
                isLoading: false,
            }
            break;

        case SEND_MESSAGE:
            state = {
                ...state,
                isLoading: false,

            }
            break;

        default:
            break;
    }

    return state;
}