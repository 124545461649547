import React, { Component } from 'react'
import { connect } from "react-redux";
import { Row, Col, Pagination, Card, Modal, Avatar, Button, Input, message } from 'antd'
import { AiOutlineDelete } from "react-icons/ai";
import MessageAction from '../../store/actions/Messages';
import { Layout } from '../../components'
import { Images } from '../../config'
import { Utils } from "../../config";



const { Meta } = Card;
const { TextArea } = Input;

class AllMessages extends Component {
    constructor() {
        super()
        this.state = {
            msgModal: false,
            msgData: {},
            replyModal: false,
            replyMessage: '',
            msgError: { isMsgError: false, errorMsg: '' },
            delModal: false,
            msgId: ''
        }
    }

    componentDidMount() {
        const { Messages } = this.props
        const getMsg = {
            page: 1,
            limit: 8
        }
        Messages(getMsg);
    }

    showModal = (data) => {
        this.setState({ msgData: data, msgModal: true })
    };

    handleOk = () => {
        this.setState({ msgModal: false })
    };

    handleCancel = () => {
        this.setState({ msgModal: false })
    };

    handleOpenDelModal = (item) => {
        const { msgData } = this.state
        this.setState({ delModal: true, msgId: item?.id || msgData?.id, msgModal: false })
    }

    handleCloseDelModal = () => {
        this.setState({
            delModal: false
        })
    }

    // Message Modal
    showReplyModal = (data) => {
        this.setState({
            msgData: data ? data : this.state.msgData,
            replyModal: true,
            msgModal: false
        })
    };

    handleReplyOk = () => {
        this.setState({ replyModal: false, msgData: {} })
    };

    handleReplyCancel = () => {
        this.setState({ replyModal: false, msgModal: false, replyMessage: '' })
    };

    delMessage = () => {
        const { DeleteMsg, metaData } = this.props
        const { current_page } = metaData
        const { msgId } = this.state

        const delMsgData = {
            id: msgId,
            page: current_page
        }
        DeleteMsg(delMsgData)
        this.setState({ delModal: false, msgModal: false })
    }

    checkMsg = (e) => {
        if (e.target.value) {
            this.setState({ replyMessage: e.target.value, msgError: { isMsgError: false, errorMsg: '' } })
        }
        else {
            this.setState({ msgError: { isMsgError: true, errorMsg: 'Field can not be left empty' } })
        }
    }

    sendMessage = () => {
        const { msgError, replyMessage, msgData } = this.state
        const { metaData, SendMessage } = this.props
        const { current_page } = metaData

        const { isMsgError } = msgError

        if (isMsgError || !replyMessage) return Utils.showSnackBar({ message: 'Field cannot be left empty', severity: "error", });
        const sendResponse = {
            reply: replyMessage,
            userId: msgData.id,
            page: current_page,
            userEmail: msgData.user.email
        }
        SendMessage(sendResponse)
        this.setState({ replyMessage: '', replyModal: false })
    }

    handlePages = (pageNumber) => {
        const { Messages } = this.props
        const getMsg = {
            page: pageNumber,
            limit: 8
        }
        Messages(getMsg);
    };

    render() {
        const { allMessages, metaData } = this.props
        const { total, current_page } = metaData
        const { replyMessage, msgData } = this.state
        

        return (
            <>
                <Layout.DashboardHeader
                    columnSpacing={5}
                    rowGap={5}
                    customizeStyle={{
                        display: "flex",
                        padding: 25,
                        backgroundColor: "#fffcfc",
                        marginLeft: 2,
                        justifyContent: "flex-start",
                    }}
                >
                    <Col xs={24} style={{ textAlign: 'left' }}>
                        <h1 className='MainHeading' >Messages</h1>
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24} >
                        <Row gutter={[10, 10]} style={{ marginTop: '24px' }}>
                            {(allMessages || [])?.map(item => {
                                return <>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={6} >
                                        <Card bordered={false} style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => this.showModal(item)}>
                                            <Row >
                                                <Avatar
                                                    // src='https://joeschmoe.io/api/v1/random'
                                                    src={
                                                        item?.user?.profile_image_thumbnail
                                                            ? item?.user?.profile_image_thumbnail
                                                            : Images?.UserDefaultImg}
                                                />
                                                <h3 className='msgCardUsrName'>{item?.user?.full_name}</h3>
                                            </Row>
                                            <div className='msgEllipsisDiv'>
                                                <p>{item.message}</p>
                                            </div>
                                            <Row className='msgActions' gutter={[10, 10]}>
                                                <Col xs={24} sm={24} md={17}>
                                                    <Button
                                                        className='msgBtn msgBtnSolid'
                                                        disabled={item.status == 1 ? false : true}
                                                        onClick={() => this.showReplyModal(item)}>
                                                        {item.status == 1 ? 'Reply' : 'Responded'}
                                                    </Button>
                                                </Col>
                                                <Col xs={24} sm={24} md={6}>
                                                    <Button icon={<AiOutlineDelete />} className='msgBtn msgBtnDel'
                                                        onClick={() => this.handleOpenDelModal(item)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </>
                            })}
                        </Row>
                    </Col>
                    <Row style={{ width: '100%' }}>
                        <Col span={24}>
                            <Pagination
                                responsive="true"
                                showLessItems="true"
                                defaultCurrent={1}
                                current={current_page}
                                total={total ? total : 0}
                                onChange={(p) => {
                                    this.handlePages(p)
                                }}
                            />
                        </Col>
                    </Row>

                    <Modal
                        visible={this.state.delModal} footer={null} onCancel={this.handleCloseDelModal} className='delConfirmModal'
                    >
                        <Row>
                            <Col>
                                <h2 style={{ color: '#FFFFFF' }}>
                                    Are you sure you want to delete ?
                                </h2>
                            </Col>
                        </Row>
                        <Row gutter={[10, 10]}>
                            <Col span={24} style={{ display: 'flex', marginTop: '50px' }} >
                                <Col md={12}>
                                    <button className='btnModal' onClick={this.delMessage}>Yes</button>
                                </Col>
                                <Col md={12}>
                                    <button className='btnModal'
                                        onClick={this.handleCloseDelModal}
                                    >No</button>
                                </Col>
                            </Col>
                        </Row>
                    </Modal>
                    <Modal visible={this.state.msgModal} footer={null} onCancel={this.handleCancel} className='msgModal'>
                        <Row >
                            <Avatar
                                src={
                                    msgData?.user?.profile_image_thumbnail
                                        ? msgData?.user?.profile_image_thumbnail
                                        : Images?.UserDefaultImg}
                            />
                            <h3 className='msgCardUsrName'>{msgData?.user?.full_name}</h3>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col>
                                <p>{msgData?.message}</p>
                            </Col>
                        </Row>
                        <Row className='msgActions' gutter={[10, 10]}>
                            <Col xs={24} sm={24} md={17}>
                                <Button disabled={msgData?.status == 1 ? false : true} className='msgBtn msgBtnSolid' onClick={() => this.showReplyModal()}>
                                    {msgData?.status == 1 ? 'Reply' : 'Responded'}
                                </Button>
                            </Col>
                            <Col xs={24} sm={24} md={6}>
                                <Button icon={<AiOutlineDelete />} className='msgBtn msgBtnDel'
                                    onClick={() => this.handleOpenDelModal()}
                                />
                            </Col>
                        </Row>
                    </Modal>

                    <Modal visible={this.state.replyModal} footer={null} onCancel={this.handleReplyCancel} className='replyModal'>
                        <div sm={24} md={24} lg={24} className='replyModalHead'>
                            <h2>Reply to User</h2>
                        </div>
                        <Row className='replyInputs' gutter={[10, 10]} style={{ marginTop: '10px' }}>
                            <Col span={24} >
                                <Input type='text' value={msgData?.user?.email} disabled />
                            </Col>
                            <Col span={24} >
                                <TextArea
                                    rows={5}
                                    style={{ width: '100%' }}
                                    placeholder='Write Something...'
                                    value={replyMessage}
                                    onChange={
                                        this.checkMsg
                                    } />
                            </Col>
                            <Col><span>{this.state.msgError.isMsgError ? this.state.msgError.errorMsg : ''}</span></Col>
                        </Row>
                        <div className='replybtnSubmit' >
                            <button className='msgBtn msgBtnSolid' style={{ width: '50%' }} onClick={this.sendMessage}>Send</button>
                        </div>
                    </Modal>
                </Layout.DashboardHeader>
            </>
        )
    }
}

function mapStateToProps(state) {

    return {
        allMessages: state.MessageReducer.messages,
        metaData: state.MessageReducer.meta_data
    }
}

function mapDispatchToProps(dispatch) {
    return {
        Messages: (payload) => dispatch(MessageAction.GetMessages(payload)),
        DeleteMsg: (payload) => dispatch(MessageAction.DeleteMsg(payload)),
        SendMessage: (payload) => dispatch(MessageAction.SendMessage(payload))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AllMessages)