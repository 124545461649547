import {
    AuthMiddleware,
    UserMiddleware,
    DashboardMiddleware,
    NewsMiddleware,
    MessageMiddleware,
    ReportedUsersMiddleware
} from "../middlewares"
import { takeLatest, all, takeEvery } from 'redux-saga/effects'
import {
    LOGOUT, SIGNIN, FORGETPASSWORD, RESETPASSWORD, VERIFYCODE, ANDROID_GRAPH, GETUSERSCARDCOUNT, USER_GRAPH,
    GETUSERS,
    DELETE_NEWS, DELETENEWS_SUCCESS, DELETENEWS_FAILURE,
    GET_NEWS, CREATE_NEWS, GETUSERDETAILS, UPDATEUSER_STATUS, GETNEWS_DETAILS,
    GET_MESSAGES, DELETE_MESSAGE, SEND_MESSAGE,
    GET_REPORTED_USERS, GET_REPORTEDPROFILES,
    IGNORE_USERREPORT, GET_REPORTEDPCOMMENTS,
    IGNORE_REPORTEDPROFILE, IGNORE_REPORTEDCOMMENTS,
    BAN_USER, DELETE_PROFILE, DELETE_COMMENT,
    EDIT_NEWS, DELETE_USER_PROFILE, CHANGE_PASSWORD
} from "../constants"


export function* Sagas() {
    yield all([
        yield takeLatest(SIGNIN, AuthMiddleware.SignIn),
        yield takeLatest(LOGOUT, AuthMiddleware.Logout),
        yield takeLatest(FORGETPASSWORD, AuthMiddleware.ForgetPassword),
        yield takeLatest(RESETPASSWORD, AuthMiddleware.ResetPassword),
        yield takeLatest(VERIFYCODE, AuthMiddleware.VerifyCode),
        // yield takeLatest(GETUSERS, UserMiddleware.GetUsers),
        yield takeEvery(GETUSERSCARDCOUNT, DashboardMiddleware.GetUserCardCount),
        yield takeLatest(USER_GRAPH, DashboardMiddleware.UserGraph),
        yield takeLatest(ANDROID_GRAPH, DashboardMiddleware.AndroidGraph),

        // -------------------------------------------------------------------------
        yield takeLatest(GETUSERS, UserMiddleware.GetUsers),
        yield takeLatest(GETUSERDETAILS, UserMiddleware.GetUserDetails),
        yield takeLatest(UPDATEUSER_STATUS, UserMiddleware.UpdateUser_Status),
        yield takeLatest(DELETE_USER_PROFILE, UserMiddleware.deleteUserProfile),

        yield takeLatest(GET_NEWS, NewsMiddleware.GetNews),
        yield takeLatest(DELETE_NEWS, NewsMiddleware.DeleteNews),
        yield takeLatest(GETNEWS_DETAILS, NewsMiddleware.GetNewsDetails),
        yield takeLatest(CREATE_NEWS, NewsMiddleware.Add_News),
        yield takeLatest(EDIT_NEWS, NewsMiddleware.EditNew),


        yield takeLatest(GET_MESSAGES, MessageMiddleware.GetMessages),
        yield takeLatest(DELETE_MESSAGE, MessageMiddleware.DeleteMessage),
        yield takeLatest(SEND_MESSAGE, MessageMiddleware.SendMessage),

        yield takeLatest(GET_REPORTED_USERS, ReportedUsersMiddleware.GetReportedUser),
        yield takeLatest(GET_REPORTEDPROFILES, ReportedUsersMiddleware.GetReportedProfiles),
        yield takeLatest(IGNORE_USERREPORT, ReportedUsersMiddleware.IgnoreUserReport),
        yield takeLatest(GET_REPORTEDPCOMMENTS, ReportedUsersMiddleware.GetReportedComments),
        yield takeLatest(IGNORE_REPORTEDPROFILE, ReportedUsersMiddleware.IgnoreProfileReport),
        yield takeLatest(IGNORE_REPORTEDCOMMENTS, ReportedUsersMiddleware.IgnoreCommentsReports),
        yield takeLatest(BAN_USER, ReportedUsersMiddleware.BanUser),
        yield takeLatest(DELETE_PROFILE, ReportedUsersMiddleware.DeleteProfile),
        yield takeLatest(DELETE_COMMENT, ReportedUsersMiddleware.Delete_Comment),
        yield takeLatest(CHANGE_PASSWORD, AuthMiddleware.ChangePassword),


    ])
}