import { AuthAction, UserAction } from "../actions";
import { ApiCaller } from "../../config";
import { put, delay } from "redux-saga/effects";
import { Utils } from "../../config";
import {
  GETUSERDETAILS_ENDPOINT,
  GETUSERS_ENDPOINT,
  // --------------------------------
  UPDATEUSERSTATUS_ENDPOINT,
  DELETEPROFILE_ENDPOINT
} from "../../config/variables";

export default class AppMiddleware {

  static *GetUsers(params) {
    const { like, status, page } = params;

    try {
      let res = yield ApiCaller.Get(
        `${GETUSERS_ENDPOINT}?search=${like}&status=${status}&page=${page}`
      );

      if (res.status == 200) {
        yield put(UserAction.GetUsersSuccess(res.data.data));
      }
      else {
        yield put(UserAction.GetUsersFailure());
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(UserAction.GetUsersFailure());
    }
  }

  static *GetUserDetails(payload) {
    const { id, cb } = payload
    try {
      let res = yield ApiCaller.Get(`${GETUSERDETAILS_ENDPOINT}/${id}`);


      if (res.status == 200) {
        yield put(UserAction.GetUserDetails_Success(res.data.data));
      }
      else {
        yield put(UserAction.GetUserDetails_Failure());
        Utils.showSnackBar({ message: res.data.message, severity: "error" })
        // cb() && cb()
      }
    } catch (err) {

    }
  }

  static *UpdateUser_Status(data) {

    let { payload } = data


    const { userID, status, page, filterStatus } = payload
    let userStats = status == 1 ? 'active' ? status == 2 : 'inactive' : status

    try {
      let res = yield ApiCaller.Put(`${UPDATEUSERSTATUS_ENDPOINT}/${userID}`, {
        status: status,
      });

      if (res.status == 200) {
        yield put(UserAction.GetUserDetails(userID))
        yield put(UserAction.GetUsers('', filterStatus, page));

        Utils.showSnackBar({
          message: res.data.message,
          severity: "success",
        });
      }
      else {
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
    }
  }

  static *deleteUserProfile(data) {
    
    const { id, profileID } = data
    try {
      let res = yield ApiCaller.Delete(`${DELETEPROFILE_ENDPOINT}/${profileID}`);
      

      if (res.status == 200) {
        yield put(UserAction.Delete_UserProfile_Success())
        yield put(UserAction.GetUserDetails(id))

        Utils.showSnackBar({
          message: res.data.message,
          severity: "success",
        });
      }
      else {
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
    }
  }
}
