import React, { useEffect, useState } from "react";
import { Switch } from '../../components'
import TableContainer from '@mui/material/TableContainer';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import StatusBar from "../StatusBar";
import { UserAction } from "../../store/actions";
import { Pagination } from "..";
import { useDispatch } from "react-redux";

const Simple = ({ data, withoutPagination, className, offset, limit, like, isUsersTable, tableStyle, headStyle, onClick, bodyStyle, rowStyle, cellStyle, alignTitle, alignContent, pageSize, rowsPerPageOptions, containerStyle, paginationStyle, paginationCount, paginationColor, onChangePage, defaultPage, props }) => {
    const [userStatus, setUserStatus] = useState(false);
    const dispatch = useDispatch();
    return (
        <>
            <TableContainer style={containerStyle} {...props}>
                <Table style={tableStyle} {...props} className={className}>
                    <TableHead style={headStyle} {...props}>
                        <TableRow style={rowStyle} {...props}>
                            {data.map((column, index) => (
                                <TableCell

                                    style={cellStyle}
                                    key={index}
                                    align={alignTitle}
                                >
                                    {column.name}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody {...props} style={bodyStyle} onClick={(e) => {
                    }}>
                        {data[0]?.values.map((_, rowIndex) => (
                            <TableRow style={rowStyle} key={rowIndex} onClick={() => {
                                isUsersTable ? onClick(data[1].values[rowIndex], data[2].values[rowIndex], rowIndex) : console.log();
                            }
                            }>
                                {data.map((row, colIndex) => (
                                    <TableCell
                                        style={cellStyle}
                                        key={colIndex}
                                        align={alignContent}
                                    >
                                        {isUsersTable ? colIndex == 5 ? <StatusBar status={row.values[rowIndex] == "Active" ? "Active" : "In-Active"} /> : colIndex == 6 ?
                                            <div style={{ display: "flex" }} onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}>
                                                {/* <input onClick={(e) => {
                                                    dispatch(UserAction.UpdateUserStatus(data[6].values[rowIndex], like, limit, offset, () => {

                                                    }))
                                                }} type="checkbox" checked={row.values[rowIndex] === "Active"}
                                                /> */}

                                                <Switch.IOSwitch
                                                    sx={{
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            color: "#DB5C5C",

                                                        },
                                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                                            backgroundColor: "#1E2D4A",

                                                        },

                                                    }}
                                                    checked={row.values[rowIndex] === "Active"}
                                                    onClick={(e) => {
                                                        dispatch(UserAction.UpdateUserStatus(data[7].values[rowIndex], like, limit, offset, () => {

                                                        }))
                                                    }}
                                                    containerStyle={{ margin: 5 }}
                                                />
                                                <p style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{row.values[rowIndex] == "Active" ? "User is Active" : "User is In-Active"}</p> </div> : row.values[rowIndex] : row.values[rowIndex]}

                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                withoutPagination ? null : <Pagination.Basic
                    count={paginationCount}
                    color={paginationColor || "primary"}
                    paginationStyle={{ padding: "20px 0", ...paginationStyle }}
                    onChange={onChangePage}
                    defaultPage={defaultPage || 1}
                />
            }
        </>
    )
}

export default Simple;
