import { NewsAction } from "../actions";
import { ApiCaller } from "../../config";
import { put, delay } from "redux-saga/effects";
import { Utils } from "../../config";
import { CREATENEWS_ENDPOINT, GETNEWS_ENDPOINT, DELETENEWS_ENDPOINT, GETNEWSDETAILS_ENDPOINT, EDITNEWS_ENDPOINT } from "../../config/variables";


export default class NewsMiddleware {

    static *GetNews({ payload }) {
        const { search, pageNumber } = payload
        try {
            let res = yield ApiCaller.Get(
                `${GETNEWS_ENDPOINT}?search=${search}&page=${pageNumber}`
            );
            const objGetNew = {
                data: res.data.data.data,
                meta: res.data.data.meta
            }
            if (res.status == 200) {
                yield put(NewsAction.GetNews_Success(objGetNew));
            }
            else {

            }
        }
        catch (err) {
            // yield put(AuthAction.SignInFailure());
        }
    }

    static *GetNewsDetails(params) {

        const { payload, cb } = params
        try {
            let res = yield ApiCaller.Get(
                `${GETNEWSDETAILS_ENDPOINT}/${payload}`
            );
            if (res.status == 200) {
                yield put(NewsAction.GetNewsDetails_Success(res.data.data));
            }
            else {

                Utils.showSnackBar({ message: res.data.message, severity: "error" })
                yield put(NewsAction.GetNewsDetails_Failure());
                cb() && cb()

            }
        }
        catch (err) {
            // yield put(AuthAction.SignInFailure());
        }
    }

    static *DeleteNews({ payload }) {
        const { pageNumber, id, search } = payload
        const objdel = {
            pageNumber,
            search
        }
        try {
            let res = yield ApiCaller.Delete(`${DELETENEWS_ENDPOINT}/${id}`);
            if (res.status == 200) {
                yield put(NewsAction.GetNews(objdel));
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "success",
                });
            } else {
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "error",
                });
            }
        }
        catch (err) {
            // yield put(AuthAction.SignInFailure());
        }
    }

    static *Add_News(data) {


        const { cb } = data

        const userID = JSON.parse(localStorage.getItem('ratemydate'))


        const newsDetail = data.payload[0].map((item, index) => {
            return { [`newsDetail[${index}][description]`]: item.description }
        })

        const newMedia = data.payload[1].map((v, i) => {
            return { [`newsMedia[media${i + 1}]`]: v[`media${i + 1}`] }
        })

        let formData = new FormData()

        newsDetail.forEach(item => {
            const key = Object.keys(item)
            formData.append(key[0], item[key[0]])

        })

        newMedia.forEach(item => {


            const key = Object.keys(item)


            item[key[0]].forEach((v, i) => {

                Object.values(v).forEach(c => {
                    formData.append(`${key[0]}[]`, c)
                })

            })

        })

        formData.append('user_id', userID.id)
        formData.append('shortDesc', data.payload[2].shortDesc)
        formData.append('title', data.payload[2].title)
        formData.append('image', data.payload[2].image)


        try {
            let res = yield ApiCaller.Post(`${CREATENEWS_ENDPOINT}`, formData);
            if (res.status == 200) {
                Utils.showSnackBar({ message: res.data.message, severity: "success" })
                yield put(NewsAction.CreateNews_Success());
                cb() && cb()
            }
            else {
                // 
                Utils.showSnackBar({ message: res.data.message, severity: "error" })
                yield put(NewsAction.CreateNews_Failure());
            }
        }
        catch (err) {
            // yield put(AuthAction.SignInFailure());


        }
    }




    static *EditNew(data) {

        const { newsId, cb, isTitleImageChange } = data
        const userID = JSON.parse(localStorage.getItem('ratemydate'))

        const newsDetail = data.payload[0].map((item, index) => {
            return { [`newsDetail[${index}][id]`]: item.id, [`newsDetail[${index}][description]`]: item.description }
        })


        const newMedia = data.payload[1].map((v, i) => {
            return { [`newsMedia[media${i + 1}]`]: v[`media${i + 1}`] }
        })


        let formData = new FormData()

        newsDetail.forEach(item => {
            const key = Object.keys(item)
            if (item[key[0]] === undefined) {
                formData.append(key[1], item[key[1]])

            } else {
                formData.append(key[0], item[key[0]])
                formData.append(key[1], item[key[1]])


            }

        })


        newMedia.forEach((item, index) => {
            const key = Object.values(item) //  
            const k = Object.keys(item) //[newsMedia[media1],newsMedia[media2],newsMedia[media3],newsMedia[media4]]
            
            
            let file ;
            if (key[0][0]) {

                file = key[0][0].name ? key[0][0] : key[0][0][0]
            }


            if (!key[0][0].name) {

                for (var i = 1; i < 4; i++) {

                    if (key[0][0][i]) {

                        formData.append(`${k[0]}[]`, key[0][0][i])
                    }

                }



            }

            key.forEach((v, i) => {
                v.forEach((a, i) => {
                    formData.append(`${k[0]}[]`, file)
                })
            })
        })


        formData.append('user_id', userID.id)
        formData.append('shortDesc', data.payload[2].shortDesc)
        formData.append('title', data.payload[2].title)
        if (isTitleImageChange) return formData.append('image', data.payload[2].image)



        try {
            let res = yield ApiCaller.Put(`${EDITNEWS_ENDPOINT}/${newsId}`, formData);
            if (res.status == 200) {
                yield put(NewsAction.EditNews_Success());
                cb() && cb()
                Utils.showSnackBar({
                    message: 'News updated successfully',
                    severity: "success",
                });
            }
            else {

                yield put(NewsAction.EditNews_Failure());
                cb() && cb()
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "error",
                });
            }
        }
        catch (err) {

            // yield put(AuthAction.SignInFailure());

        }
    }


}