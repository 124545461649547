import {
    SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAILURE,
    GET_POSTS, GET_POSTS_SUCCESS, GET_POSTS_FAILURE,
    LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILURE,
    LOADER_TRUE, LOADER_FALSE,
    RESETPASSWORD, RESETPASSWORD_SUCCESS, RESETPASSWORD_FAILURE
} from '../constants';

const initialState = {
    user: localStorage.getItem("ratemydate") ? JSON.parse(localStorage.getItem("ratemydate")) : {},
    loader: false,
    resetLoader: false,
    posts: [],
    isLoading: false
}

export default function AppReducer(state = initialState, action) {
    switch (action.type) {

        case SIGNIN:
            state = {
                ...state,
                isLoading: true
            }
            break;
        case SIGNIN_SUCCESS:
            state = {
                ...state,
                user: action.payload,
                isLoading: false
            }
            break;
        case SIGNIN_FAILURE:
            state = {
                ...state,
                isLoading: false
            }
            break;


        case RESETPASSWORD:
            state = {
                ...state,
                resetLoader: true
            }
            break;
        case RESETPASSWORD_SUCCESS:
            state = {
                ...state,
                // user: action.payload,
                resetLoader: false
            }
            break;
        case RESETPASSWORD_FAILURE:
            state = {
                ...state,
                resetLoader: false
            }
            break;

        case LOGOUT:
            state = {
                ...state,
                isLoading: true
            }
            break;
        case LOGOUT_SUCCESS:
            state = {
                user: {},
                posts: [],
                isLoading: false
            }
            break;
        case LOGOUT_FAILURE:
            state = {
                ...state,
                isLoading: false
            }
            break;
        // ------------------------------------------------------------------------------------


        case LOADER_TRUE:
            state = {
                ...state,
                loader: true
            }
            break;

        case LOADER_FALSE:
            state = {
                ...state,
                loader: false
            }
            break;

        default:
            break;
    }

    return state;
}