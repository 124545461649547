import React, { Component } from 'react'
import { Grid } from "@mui/material";
import { Row, Col, Pagination } from 'antd'
import { connect } from 'react-redux'
import { Layout, TextField } from "../../components";
import { Colors, Images } from "../../config";
import { HiOutlineSearch } from "react-icons/hi";
import NewsCard from './NewsCard.js'
import { NewsAction } from '../../store/actions';
import { AiOutlinePlus, AiOutlineUpload } from 'react-icons/ai'



class News extends Component {
    constructor() {
        super()
        this.state = {
            page: 1,
            current: 1,
            search: "",
        };
    }
    componentDidMount() {
        const { GetNews } = this.props
        GetNews({ search: "", pageNumber: 1 });
    }

    handlePages = (pageNumber) => {
        const { GetNews } = this.props;
        const limit = 10;
        let offset = (pageNumber - 1) * limit;
        const { search } = this.state;
        GetNews({ search, pageNumber });
        this.setState({ offset: offset, current: pageNumber, page: pageNumber });
    };

    handleSearch = (search) => {
        const { GetNews } = this.props;
        GetNews({ search, pageNumber: 1 });
        this.setState({ search, current: 1 });
    }

    getCardId = (id) => {
        const {history} = this.props
        history.push(`/news/editnews/${id}`)
        
        

    }
    render() {
        const { current } = this.state;
        const { history, allNews, newsMeta } = this.props
        // const { total } = newsMeta
        
        return (
            <>
                <Layout.DashboardHeader
                    columnSpacing={5}
                    rowGap={5}
                    customizeStyle={{
                        display: "flex",
                        padding: 25,
                        backgroundColor: "#fffcfc",
                        marginLeft: 2,
                        justifyContent: "flex-start",
                    }}
                >
                    <Grid container style={{ justifyContent: "space-between", elevation: 10 }}>
                        <Grid xs={12} sm={12} lg={7} style={{ textAlign: 'left' }}>
                            <h1 className='MainHeading'>News </h1>
                        </Grid>
                        <Grid container item xs={12} sm={12} lg={5} style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Grid xs={12} sm={12} lg={8}>
                                <TextField.SearchTextField
                                    style={{ width: "100%" }}
                                    className='searchBar'
                                    sm={24}
                                    md={24}
                                    // multiline
                                    //options={["web", "android", "iOS"]}
                                    containerStyle={{ marginBottom: 10 }}
                                    InputProps={{
                                        maxlength: 250,
                                        startAdornment: (
                                            <HiOutlineSearch size={20} color={Colors.Secondary} />
                                        )
                                    }}
                                    sx={{
                                        borderRadius: "10px",
                                        border: `2px solid ${Colors.Primary}`,
                                        // borderRadius: 2,
                                        width: "270px",
                                        backgroundColor: "#1C2B3F",
                                        "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                                        {
                                            color: Colors.White,

                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            // (default alpha is 0.38)
                                        },
                                        ".MuiInputBase-adornedStart": {
                                            color: "white !important"
                                        },
                                        "&.css-12vqcob-MuiInputBase-input-MuiOutlinedInput-input": {
                                            color: "white"
                                        },

                                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                        {

                                            display: "none",
                                        },

                                        "& .MuiOutlinedInput-root:hover": {
                                            "& > fieldset": {
                                                display: "none",
                                            },
                                        }
                                    }}
                                    placeholder="Search"

                                    dropdownIcon
                                    onChange={(e) => {
                                        //
                                        this.handleSearch(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} sm={12} lg={3}>
                                <div className='btnAddNews' onClick={() => history.push('/news/addnews')}>
                                    <div className='btnIcons'><AiOutlinePlus /></div>
                                    <div>Add New</div>
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Row gutter={[10, 10]} justify='center' style={{ width: '100%' }}>
                        {(allNews || []).map((item) => {

                            return (
                                <Col span={24}>
                                    <NewsCard getCardId={this.getCardId} details={item} {...this.props}  currentPage={this.state.current}/>
                                </Col>
                            )
                        })
                        }
                    </Row>
                    <Row style={{ width: '100%' }}>
                        <Col span={24} style={{ marginTop: '3rem' }}>
                            <Pagination
                                responsive="true"
                                showLessItems="true"
                                current={current}
                                defaultCurrent={1}
                                total={newsMeta?.total ? newsMeta?.total : 0}
                                onChange={(p) => {
                                    this.handlePages(p)
                                }}
                            />
                        </Col>
                    </Row>

                </Layout.DashboardHeader>

            </>
        )
    }
}
function mapStateToProps(state) {
    


    return {
        allNews: state.NewsReducer.news.data,
        newsMeta: state.NewsReducer.news.meta,
        // meta: state.NewsReducer.newsDetails.meta
        // newsCount: state.NewsReducer.newsCount
    };
}

function mapDispatchToProps(dispatch) {
    return {
        GetNews: (payload) => dispatch(NewsAction.GetNews(payload))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(News)