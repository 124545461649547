import {
    GET_NEWS, GETNEWS_SUCCESS, GETNEWS_FAILURE,
    CREATE_NEWS, CREATENEWS_SUCCESS, CREATENEWS_FAILURE,
    GETNEWS_DETAILS, GETNEWSDETAILS_SUCCESS, GETNEWSDETAILS_FAILURE,
    EDIT_NEWS, EDIT_NEWS_SUCCESS, EDIT_NEWS_FAILURE,
} from '../constants';

const initialState = {
    isLoading: false,
    news: [],
    newsCount: [],
    singleNews_Details: []
}

export default function NewsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_NEWS:
            state = {
                ...state,
                isLoading: true
            }
            break;

        case GETNEWS_SUCCESS:
            if (action.data) {
                
                state = {
                    ...state,
                    isLoading: false,
                    news: action.data
                }
            }
            break;

        case CREATE_NEWS:
            state = {
                ...state,
                isLoading: true
            }
            break;

        case CREATENEWS_SUCCESS:
            state = {
                ...state,
                isLoading: false
            }
            break;

        case CREATENEWS_FAILURE:
            state = {
                ...state,
                isLoading: false
            }
            break;



        case GETNEWS_DETAILS:
            state = {
                ...state,
                singleNews_Details: {},
                isLoading: true
            }
            break

        case GETNEWSDETAILS_SUCCESS:

            state = {
                ...state,
                singleNews_Details: action.payload,
                isLoading: false
            }
            break

        case GETNEWSDETAILS_FAILURE:

            state = {
                ...state,
                isLoading: false
            }
            break

        case EDIT_NEWS:

            state = {
                ...state,
                isLoading: true
            }
            break

        case EDIT_NEWS_SUCCESS:

            state = {
                ...state,
                isLoading: false
            }
            break
        case EDIT_NEWS_FAILURE:

            state = {
                ...state,
                isLoading: false
            }
            break


        default:
            break;
    }
    return state;
}