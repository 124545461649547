import React from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const SelectField = ({ variant, data, placeholder, size, id, type, disabled, required, value, label, defaultValue, error, helperText, onChange, onClick, startIcon, endIcon, containerStyle, selectStyle, sx, ...props }) => {
    
    
    return (
        <div style={containerStyle}>
            <TextField
                id={id} //outlined-basic //filled-basic //standard-basic //outlined-read-only-input
                variant={variant} //outlined //filled //standard
                type={type}
                size={size}
                disabled={disabled}
                required={required}
                label={label}
                value={value}
                defaultValue={defaultValue}
                error={error}
                helperText={helperText}
                onChange={onChange}
                placeholder={placeholder}
                style={selectStyle}
                sx={sx}
                {...props}
                select
            >
                {(data || []).map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>

        </div>
    )

}


export default SelectField;
