import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Layout,
  Charts,
  View,
  Picker,
  UserCard,
  MonthRangePicker
} from "../../components";
import CalenderFilterIcon from "../../assets/images/userCalenderFilter.svg";
import { AuthAction, DashboardAction } from "../../store/actions";
import { DashboardReducer } from "../../store/reducers";
import styles from "./styles";
import { RiCalendarLine } from "react-icons/ri";
import { HiOutlineSearch } from "react-icons/hi";
import Grid from "@mui/material/Grid";
import { Colors, Images } from "../../config";
import TextField from "@mui/material/TextField";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";
import Box from "@mui/material/Box";
import axios from "axios";
const CalenderIcon = Images.CalenderFilterIcon;
class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      value: [moment().subtract(4, "months"), moment()],
      value1: [moment().subtract(4, "months"), moment()],
      currentMonth: moment(),
      currentMonthVoiceChat: moment(),
      currentMonthUsersType: moment(),
      selectedMonth: moment(),
      monthPickerOpen: false,
      selectedMonthRange: [moment().subtract(4, "months"), moment()],
      selectedMonthRangeVoiceChat: [moment().subtract(4, "months"), moment()],
      selectedMonthRangeUsersType: [moment().subtract(4, "months"), moment()],
      dateHasChanged: false,
      datesHasChanged: false,
      datesHasChangedUser: false,
      userStatsLoading: false,
      gameStatsLoading: false,
      monthlyUserStatsLoading: false,
      isOpen: false,
      isOpen1: false,
      isDivClicked: false,
    };
  }
  navigate = () => {
    this.props.history.push("/users");
  };


  componentDidMount() {
    const { UserGraphData, AndroidGraphData, NewUserCount, androidGraph } = this.props;

    let currentDate = moment().format("MM-YYYY");
    let previousDate = (moment().subtract(1, "months")).format("MM-YYYY");
    NewUserCount(currentDate, previousDate, "newUsers");
    let currentDate1 = moment().format("MM-YYYY");

    UserGraphData(previousDate, currentDate1, (res) => {
    }
    );
    let previousDate1 = (moment().subtract(1, "months")).format("MM-YYYY");


    AndroidGraphData(currentDate1, previousDate, (res) => {
    }
    )


  }

  handlePanelChange = (range) => {
    const { NewUserCount } = this.props;
    const valueOfInput1 = range ? range[0] : '';
    const valueOfInput2 = range ? range[1] : '';
    NewUserCount(moment(valueOfInput2).format("YYYY-MM-DD"), moment(valueOfInput1).format("YYYY-MM-DD"), "newUsers");
    const startDate = new Date(valueOfInput1);
    const endDate = new Date(valueOfInput2);
    const startDate1 = startDate.getDate()
    const endDate2 = endDate.getDate()
    const startMonth = startDate.getMonth() + 1;
    const startYear = startDate.getFullYear();
    const endMonth = endDate.getMonth() + 1;
    const endYear = endDate.getFullYear();
    const startYearCase = startYear.toString().substr(-2);
    const endYearCase = endYear.toString().substr(-2);
    const currentDate = `${moment(valueOfInput2).format("MM-YYYY")}`
    const previousDate = `${moment(valueOfInput1).format("MM-YYYY")}`
    const { UserGraphData, AndroidGraphData } = this.props
    this.setState({ value: [valueOfInput1, valueOfInput2] });
    UserGraphData(previousDate, currentDate)



  }

  handlePanelChange1 = (range) => {
    

    const valueOfInput1 = range ? range[0] : "";
    const valueOfInput2 = range ? range[1] : '';
    const startDate = new Date(valueOfInput1);
    const endDate = new Date(valueOfInput2);
    const startDate1 = startDate.getDate()
    const endDate2 = endDate.getDate()
    const startMonth = startDate.getMonth() + 1;
    const startYear = startDate.getFullYear();
    const endMonth = endDate.getMonth() + 1;
    const endYear = endDate.getFullYear();
    const startYearCase = startYear.toString().substr(-2);
    const endYearCase = endYear.toString().substr(-2);
    const currentDate = `${moment(valueOfInput2).format("MM-YYYY")}`
    const previousDate = `${moment(valueOfInput1).format("MM-YYYY")}`
    const { UserGraphData, AndroidGraphData } = this.props
    this.setState({ value1: [valueOfInput1, valueOfInput2] });
    AndroidGraphData(currentDate, previousDate)



  }



  render() {
    const { ShadowView } = View;
    const {
      // totalUserCount, 
      activeUserCount,
      bannedUserCount,
      // deletedUserCount, 
      newUserCount,
      NewUserCount,
      UserGraphData,
      androidGraph,
      userGraph
    } = this.props;
    
    return (
      <Layout.DashboardHeader
        columnSpacing={5}
        rowGap={5}
        customizeStyle={{
          display: "flex",
          padding: 25,
          backgroundColor: "#fffcfc",
          marginLeft: 2,
          justifyContent: "flex-start",
        }}
      >
        {/* <UserCard
          userImage={Images.TotalUsersIcon}
          userHeading="Total Users"
          isTotal
          userCount={totalUserCount ? totalUserCount : 0}
        /> */}

        <UserCard
          userImage={Images.ActiveUsersIcon}
          userHeading="Active Users"
          isActive
          userCount={activeUserCount ? activeUserCount : 0}
        />
        <UserCard
          userImage={Images.BannedDeletedUserIcon}
          userHeading="Banned Users"
          isBanned
          userCount={bannedUserCount ? bannedUserCount : 0}
        />
        {/* <UserCard
          userImage={Images.BannedDeletedUserIcon}
          userHeading="Deleted Users"
          isDeleted
          userCount={deletedUserCount ? deletedUserCount : 0}
        /> */}
        <Grid
          container
          columnSpacing={1}
          style={{
            display: "flex",
            width: "100%",
            margin: 5,
            justifyContent: "space-around",
          }}
        >
           
          <Grid item xs={12} sm={12} md={5.8} lg={5.8}>
            <div className="dashboardChartsDiv" >
              <div className="dashboardChartsHead" >
                <h2 style={{ color: Colors.Primary }}>Signup</h2>
              </div>
              <div>
                {/* <input
                  type="text"
                  value={this.state.value[0] + "-" + this.state.value[1]}
                  onFocus={() => this.setState({ isOpen: !this.state.isOpen })}
                  onBlur={() => this.setState({ isOpen: this.state.isDivClicked ? true : false })}
                ></input> */}
                <MonthRangePicker
                  allowClear={false}
                  onChange={(e) => {
                    this.handlePanelChange1(e);
                  }}
                  defaultValue={[moment().subtract(1, "months"), moment()]}
                  format={"MMMM YYYY"}
                  picker="month"
                  suffixIcon={
                    <RiCalendarLine
                      style={{ color: Colors.Primary, fontSize: "20px" }}
                    />
                  }
                />
                {/* <Picker.DatePicker
                  value={this.state.date}
                  onChange={(date) => this.setState({ date })}
                  containerStyle={{ margin: 5 }}
                /> */}
              </div>
            </div>
            <ShadowView
              // elevation={styles.SampleViewElevation.elevation}
              containerStyle={{ marginTop: 5 }}
            >
              <Charts.ResponsiveContainer
                style={{ margin: "27px 0px" }}
                height={350}
              >
                {(androidGraph || [])
                  .map((val) => val.apple || val.google)
                  .reduce((total, value) => total + value, 0) > 0 ? (
                  <Charts.LineChart
                    data={
                      androidGraph
                    }
                    lineColor={["#4BE5C0", "#4E5FF8"]}
                    loading={this.state.appDownloadsLoading}
                    margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                  />
                ) : (
                  <h4
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >

                  </h4>
                )}
              </Charts.ResponsiveContainer>
            </ShadowView>
          </Grid>
          <Grid item xs={12} sm={12} md={5.8} lg={5.8}>
            <div className="dashboardChartsDiv" >
              <div className="dashboardChartsHead">
                <h2 style={{ color: Colors.Primary }}>Profile</h2>
              </div>
              <div>
                <MonthRangePicker
                  allowClear={false}
                  onChange={(e) => {
                    this.handlePanelChange(e);
                  }}
                  defaultValue={[moment().subtract(1, "months"), moment()]}
                  format={"MMMM YYYY"}
                  picker="month"
                  suffixIcon={
                    <RiCalendarLine
                      style={{ color: Colors.Primary, fontSize: "20px" }}
                    />
                  }
                />
              </div>
            </div>
            <ShadowView
              elevation={styles.SampleViewElevation.elevation}
              containerStyle={{ marginTop: 5 }}
            >
              <Charts.ResponsiveContainer
                style={{ margin: "27px 0px" }}
                height={350}
              >
                {/* {[
                UserGraph
                ] */}
                {(userGraph || [])
                  .map((val) => val["Number of Profiles"])
                  .reduce((total, value) => total + value, 0) > 0 ? (
                  <Charts.LineChart
                    data={
                      userGraph
                        ? userGraph
                        : []
                    }
                    lineColor={["#4BE5C0", "#4E5FF8"]}
                    loading={this.state.appDownloadsLoading}
                    margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                  />
                ) : (
                  <h4
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >

                  </h4>
                )}
              </Charts.ResponsiveContainer>
            </ShadowView>
          </Grid>


        </Grid>
      </Layout.DashboardHeader>
    );
  }
}

function mapStateToProps(state) {
  
  return {
    userGraph: state.DashboardReducer.userGraphData,
    androidGraph: state.DashboardReducer.androidAndIosData,
    // totalUserCount: state.DashboardReducer.totalUserCount,
    activeUserCount: state.DashboardReducer.activeUserCount,
    bannedUserCount: state.DashboardReducer.bannedUserCount,
    // deletedUserCount: state.DashboardReducer.deletedUserCount,
    newUserCount: state.DashboardReducer.newUserCount
  };
}

function mapDispatchToProps(dispatch) {
  return {
    Logout: () => dispatch(AuthAction.Logout()),
    UserGraphData: (currentDate, previousDate) => dispatch(DashboardAction.userGraphResquest(currentDate, previousDate)),
    AndroidGraphData: (currentDate1, previousDate1) => dispatch(DashboardAction.androidGraphResquest(currentDate1, previousDate1)),
    NewUserCount: (currentDate,
      previousDate,
      userType) => dispatch(
        DashboardAction.GetUsersCardCount(
          currentDate,
          previousDate,
          userType
        )
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
