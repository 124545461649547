import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  Dashboard,
  Users,
  UsersDetail,
  News,
  AddNews,
  NewsDetails,
  Messages,
  Reported,
  ChangePassword
} from "../../containers";
class AppRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/users/user-detail" component={UsersDetail} />
          <Route exact path="/news" component={News} />
          <Route exact path="/news/addnews" component={AddNews} />
          <Route exact path="/news/editnews/:id" component={AddNews} />
          <Route exact path="/change-password" component={ChangePassword} />

          <Route exact path="/news/news-Details" component={NewsDetails} />
          <Route exact path="/messages" component={Messages} />
          <Route exact path="/reported" component={Reported} />
          <Route exact path="*" render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    );
  }
}

export default connect((state) => ({ storeState: state }))(AppRoutes);