import { ReportedUsersActions } from "../actions";
import { ApiCaller } from "../../config";
import { put, delay } from "redux-saga/effects";
import {
    GET_REPORTEDUSERS_ENDPOINT,
    GET_PROFILEREPORTS_ENDPOINT,
    IGNOREREPORT_ENDPOINT,
    GET_REPORTEDCOMMENTS_ENDPOINT,
    IGNOREREPORTRD_PROFILE_ENDPOINT,
    IGNOREREPORTRD_COMMENTS_ENDPOINT,
    UPDATEUSERSTATUS_ENDPOINT,
    DELETEPROFILE_ENDPOINT,
    DELETECOMMENT_ENDPOINT
} from "../../config/variables";
import { Utils } from "../../config";


export default class ReportedUsersMiddleware {
    static *GetReportedUser(data) {

        const { page, filterBy } = data.data
        try {
            let res = yield ApiCaller.Get(
                `${GET_REPORTEDUSERS_ENDPOINT}?page=${page ? page : data.data}&type=${filterBy}`
            );
            const reportedUsers = {
                reportedUser_data: res.data.data.data,
                reportedUser_meta: res.data.data.meta
            }

            if (res.status == 200) {
                yield put(ReportedUsersActions.Get_ReportedUsers_Success(reportedUsers));
            }
        } catch (error) {


        }
    }

    static *GetReportedProfiles(data) {

        const { page, filterBy } = data.data


        try {
            let res = yield ApiCaller.Get(
                `${GET_PROFILEREPORTS_ENDPOINT}?page=${page}&type=${filterBy}`
            );


            const reportedProfiles = {
                reportedProfile_data: res.data.data.data,
                reportedProfile_meta: res.data.data.meta
            }

            if (res.status == 200) {
                yield put(ReportedUsersActions.Get_ReportedProfile_Success(reportedProfiles));
            }
        } catch (error) {


        }
    }

    static *IgnoreUserReport(data) {

        const { id, page } = data.data
        try {
            let res = yield ApiCaller.Put(
                `${IGNOREREPORT_ENDPOINT}/${id}`
            );

            const filterdata = {
                page,
                filterBy: ''
            }
            if (res.status == 200) {
                yield put(ReportedUsersActions.Get_ReportedUsers(filterdata))
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "success",
                });
            }
            else {
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "error",
                });
            }
        } catch (error) {


        }
    }

    static *IgnoreProfileReport(data) {

        const { id, page } = data.data
        try {
            let res = yield ApiCaller.Put(
                `${IGNOREREPORTRD_PROFILE_ENDPOINT}/${id}`
            );

            const filterdata = {
                page,
                filterBy: ''
            }
            if (res.status == 200) {
                yield put(ReportedUsersActions.Get_ReportedProfile(filterdata))
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "success",
                });
            }
            else {
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "error",
                });
            }
        } catch (error) {


        }
    }

    static *GetReportedComments(data) {
        const { page, filterBy } = data.data
        try {
            let res = yield ApiCaller.Get(
                `${GET_REPORTEDCOMMENTS_ENDPOINT}?page=${page}&type=${filterBy}`
            );

            const reportedComments = {
                reportedComments_data: res.data.data.data,
                reportedComments_meta: res.data.data.meta
            }
            // 
            if (res.status == 200) {
                yield put(ReportedUsersActions.Get_ReportedComments_Success(reportedComments));
            }
        } catch (error) {


        }
    }

    static *IgnoreCommentsReports(data) {

        const { id, page } = data.data
        try {
            let res = yield ApiCaller.Put(
                `${IGNOREREPORTRD_COMMENTS_ENDPOINT}/${id}`
            );

            const filterdata = {
                page,
                filterBy: ''
            }
            if (res.status == 200) {
                yield put(ReportedUsersActions.Get_ReportedComments(filterdata))
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "success",
                });
            }
            else {
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "error",
                });
            }
        } catch (error) {


        }
    }

    static *BanUser(data) {

        const { id, status, page, filterBy } = data.data

        try {
            let res = yield ApiCaller.Put(`${UPDATEUSERSTATUS_ENDPOINT}/${id}`, {
                status: status,
                // current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
            });

            const objGetRepoUser = {
                page,
                filterBy
            }
            if (res.status == 200) {
                yield put(ReportedUsersActions.Get_ReportedUsers(objGetRepoUser))
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "success",
                });
            }
            else {
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "error",
                });
            }
        } catch (err) {
            // yield put(UserAction.UpdateUserStatusFailure());
        }
    }

    static *DeleteProfile(data) {

        const { id, page, filterBy } = data.data

        try {
            let res = yield ApiCaller.Delete(`${DELETEPROFILE_ENDPOINT}/${id}`);
            
            const objGetRepoPro = {
                page,
                filterBy
            }
            if (res.status == 200) {
                yield put(ReportedUsersActions.Get_ReportedProfile(objGetRepoPro))
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "success",
                });
            }
            else {
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "error",
                });
            }
        } catch (err) {
            // yield put(UserAction.UpdateUserStatusFailure());
        }

    }

    static *Delete_Comment(data) {

        const { filterBy, id, page } = data.data
        try {
            let res = yield ApiCaller.Delete(`${DELETECOMMENT_ENDPOINT}/${id}`);

            const objGetRepoComm = {
                page,
                filterBy
            }
            if (res.status == 200) {
                yield put(ReportedUsersActions.Get_ReportedComments(objGetRepoComm))
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "success",
                });
            }
            else {
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "error",
                });
            }
        } catch (err) {
            // yield put(UserAction.UpdateUserStatusFailure());
        }
    }
}