import moment from 'moment';
import {
  GETUSERSCARDCOUNT, GETUSERSCARDCOUNT_SUCCESS, GETUSERSCARDCOUNT_FAILURE,
  USER_GRAPH_SUCCESS, ANDROID_GRAPH_SUCCESS
} from '../constants';
const initialState = {
  totalUserCount: "",
  activeUserCount: "",
  bannedUserCount: "",
  deletedUserCount: "",
  userGraphData: "",
  androidAndIosData: [],
  newUserCount: ""
}

export default function DashboardReducer(state = initialState, action) {
  switch (action.type) {

    case GETUSERSCARDCOUNT:
      state = {
        ...state,
        loader: true
      }
      break;
    case GETUSERSCARDCOUNT_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          // totalUserCount: action.payload.total_users == 0 ? action.payload.total_users : action.payload.total_users ? action.payload.total_users : state.totalUserCount,
          activeUserCount: action.payload ? action.payload[0].count : state.activeUserCount,
          bannedUserCount: action.payload ? action.payload[1].count : state.bannedUserCount,
          // deletedUserCount: action.payload.deleted_users == 0 ? action.payload.deleted_users : action.payload.deleted_users ? action.payload.deleted_users : state.deletedUserCount,
          newUserCount: action.payload.new_users ? action.payload.new_users : state.newUserCount,
          loader: false
        }
        break;
      }
    }
    case GETUSERSCARDCOUNT_FAILURE:
      state = {
        ...state,
        loader: false
      }
      break;

    case USER_GRAPH_SUCCESS: {



      const androidGraph = Object.keys(action.payload).map(item => {
        return {

          name: item,
          "Number of Profiles": action.payload[item]['profiles'],
        }
      })

      state = {
        ...state,
        userGraphData: androidGraph,

      }
      return state

    }
    case ANDROID_GRAPH_SUCCESS: {

      let payloadAction = action.payload;
      const androidGraph = Object.keys(payloadAction).map(item => {

        return {

          name: item,
          apple: payloadAction[item]['apple'],
          google: payloadAction[item]['google']
        }
      })


      state = {
        ...state,
        androidAndIosData: androidGraph,

      }
      return state


    }

    default:
      break;
  }
  return state;
}