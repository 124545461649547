import {
  GETUSERS,
  GETUSERS_SUCCESS,
  GETUSERS_FAILURE,
  LOADER_TRUE,
  LOADER_FALSE,
  // ---------------------------
  GETUSERDETAILS, GETUSERDETAILS_SUCCESS, GETUSERDETAILS_FAILURE,
  UPDATEUSER_STATUS, UPDATEUSERSTATUS_SUCCESS, DELETE_USER_PROFILE,
  DELETE_USERPROFILE_SUCCESS
} from "../constants";

const initialState = {
  userLoader: false,
  loader: false,
  resetLoader: false,
  posts: [],
  users: [],
  userCount: "",
  userStatusLoader: false,
  // ----------------------
  isLoading: false,
  userDetails: {},
  profiles: []
};

export default function UserReducer(state = initialState, action) {
  switch (action.type) {

    case GETUSERS:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case GETUSERS_SUCCESS: {
      if (action.payload) {
        let userArr = action.payload.data;
        let userCount = action.payload.meta.total;
        state = {
          ...state,
          userCount: userCount,
          users: userArr,
          isLoading: false,
        };
      }

    }
      break

    case GETUSERS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GETUSERDETAILS:
      state = {
        ...state,
        isLoading: true,
      }
      break;

    case GETUSERDETAILS_SUCCESS:
      state = {
        ...state,
        userDetails: action?.payload,
        profiles: action?.payload?.profiles,
        isLoading: false
      }
      break
    case GETUSERDETAILS_FAILURE:
      state = {
        ...state,
        isLoading: false
      }
      break

    case UPDATEUSER_STATUS:
      state = {
        ...state,
        isLoading: false
      }
      break
    case UPDATEUSERSTATUS_SUCCESS:
      state = {
        ...state,
        userDetails: action.payload
      }
      break

    case DELETE_USER_PROFILE:
      state = {
        ...state,
        isLoading: true
      }
      break

    case DELETE_USERPROFILE_SUCCESS:
      state = {
        ...state,
        isLoading: false
      }
      break


    // ------------------------------------------------------------------------------------------

    case LOADER_TRUE:
      state = {
        ...state,
        loader: true
      }
      break;

    case LOADER_FALSE:
      state = {
        ...state,
        loader: false
      }
      break;


    default:
      break;
  }

  return state;
}
