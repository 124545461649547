import { AuthAction } from '../actions';
import { ApiCaller } from '../../config';
import { put, delay } from 'redux-saga/effects';
import { Utils } from "../../config"
import moment from "moment";
import {
  LOGIN_ENDPOINT, LOGOUT_ENDPOINT, RESETPASSWORD_ENDPOINT,
  FORGETPASSWORD_ENDPOINT, VERIFYEMAIL_ENDPOINT, VERIFY_CODE,
  RESEND_CODE, CHANGEPASSWORD_ENDPOINT
} from "../../config/variables";
export default class AppMiddleware {

  static *SignIn(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(LOGIN_ENDPOINT, payload);
      if (res.status == 200) {
        localStorage.setItem("ratemydate", JSON.stringify(res.data.data));
        Utils.showSnackBar({ message: res.data.message })
        if (cb) {
          cb(true);
        }
        yield put(AuthAction.SignInSuccess(res.data.data));
      }
      else {
        yield put(AuthAction.SignInFailure());
        //   Info("error", res.data.error.message);
        Utils.showSnackBar({ message: res.data.message, severity: "error" })
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(AuthAction.SignInFailure());
    }
  }

  static *Logout() {
    try {
      let res = yield ApiCaller.Post(
        `${LOGOUT_ENDPOINT}`
      )
      if (res.status == 200) {
        yield put(AuthAction.LogoutSuccess());
        localStorage.removeItem("ratemydate");
        Utils.showSnackBar({ message: "Successfully logout" })
      } else {
        yield put(AuthAction.LogoutFailure())
      }
    }
    catch (err) {
      yield put(AuthAction.LogoutFailure())
    }
  }

  static *VerifyCode(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(VERIFY_CODE, payload);
      if (res.status == 200) {
        Utils.showSnackBar({ message: res.data.message })
        if (cb) {
          cb(true, false);
        }
        yield put(AuthAction.VerifyCodeSuccess(res.data));
      } else {
        yield put(AuthAction.VerifyCodeFailure());
        Utils.showSnackBar({ message: res.data.message, severity: "error" });
        if (cb) {
          cb(false, true);
        }
      }
    } catch (err) {
      yield put(AuthAction.VerifyCodeFailure());
    }
  }

  static *ForgetPassword(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(VERIFYEMAIL_ENDPOINT, { email: payload });
      if (res.status == 200) {
        Utils.showSnackBar({ message: res.data.message })
        if (cb) {
          cb(true);
        }
        yield put(AuthAction.ForgetPasswordSuccess(res.data));
      } else {
        yield put(AuthAction.ForgetPasswordFailure());
        Utils.showSnackBar({ message: res.data.message, severity: "error" });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(AuthAction.ForgetPasswordFailure());
    }
  }


  static *ResetPassword(params) {
    const { payload, cb } = params;

    try {
      let res = yield ApiCaller.Post(RESETPASSWORD_ENDPOINT, payload);

      if (res.status == 200) {
        Utils.showSnackBar({ message: res.data.message })
        if (cb) {
          cb(true, false);
        }
        yield put(AuthAction.ResetPasswordSuccess(res.data.data));
      }
      else {
        yield put(AuthAction.ResetPasswordFailure());
        Utils.showSnackBar({ message: res.data.error.message, severity: "error" });
        if (cb) {
          cb(false, true);
        }
      }
    } catch (err) {
      yield put(AuthAction.ResetPasswordFailure());
    }
  }


  static *ChangePassword(params) {
    const { payload, cb } = params;

    try {
      let res = yield ApiCaller.Put(CHANGEPASSWORD_ENDPOINT, payload);

      if (res.status == 200) {
        Utils.showSnackBar({ message: res.data.message })
        cb() && cb()
        // yield put(AuthAction.changePasswordSuccess(res.data.data));
      }
      else {
        // yield put(AuthAction.ResetPasswordFailure());
        Utils.showSnackBar({ message: res.data.message, severity: "error" });
      }
    } catch (err) {
      // yield put(AuthAction.ResetPasswordFailure());
    }
  }



  static *ResendCode() {
    try {
      let res = yield ApiCaller.Post(RESEND_CODE);
    } catch (err) {
      yield put(AuthAction.ResetPasswordFailure());
    }
  }

  // ----------------------------------------------------------------------------------


}