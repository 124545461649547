import {
    GET_NEWS, GETNEWS_SUCCESS, GETNEWS_FAILURE,
    CREATE_NEWS, CREATENEWS_SUCCESS, CREATENEWS_FAILURE,
    DELETE_NEWS, DELETENEWS_SUCCESS, DELETENEWS_FAILURE,
    GETNEWS_DETAILS, GETNEWSDETAILS_SUCCESS, GETNEWSDETAILS_FAILURE,
    EDIT_NEWS, EDIT_NEWS_SUCCESS, EDIT_NEWS_FAILURE
} from '../constants'

export default class NewsAction {
    static GetNews(payload) {
        return {
            type: GET_NEWS,
            payload
        }
    }

    static GetNews_Success(payload) {
        return {
            type: GETNEWS_SUCCESS,
            data: payload
        }
    }

    static CreateNews(payload, cb) {

        return {
            type: CREATE_NEWS,
            payload,
            cb
        }
    }

    static CreateNews_Success(payload) {
        return {
            type: CREATENEWS_SUCCESS,
            payload
        }
    }
    static CreateNews_Success(payload) {
        return {
            type: CREATENEWS_SUCCESS,
            payload
        }
    }

    static CreateNews_Failure() {
        return {
            type: CREATENEWS_FAILURE
        }
    }

    static Delete_News(payload) {

        return {
            type: DELETE_NEWS,
            payload
        }
    }

    static DeleteNews_Success(payload) {
        return {
            type: DELETENEWS_SUCCESS,
            payload
        }
    }

    static DeleteNews_Failure() {
        return {
            type: DELETENEWS_FAILURE
        }
    }
    static GetNewsDetails(payload, cb) {
        return {
            type: GETNEWS_DETAILS,
            payload,
            cb
        }
    }

    static GetNewsDetails_Success(payload) {

        return {
            type: GETNEWSDETAILS_SUCCESS,
            payload
        }
    }

    static GetNewsDetails_Failure() {
        return {
            type: GETNEWSDETAILS_FAILURE
        }
    }


    static EditNews(payload, id, isTitleimage, cb) {
        

        return {
            type: EDIT_NEWS,
            payload,
            newsId: id,
            isTitleImageChange: isTitleimage,
            cb
        }
    }
    static EditNews_Success(payload) {
        return {
            type: EDIT_NEWS_SUCCESS,
            payload
        }
    }
   
    static EditNews_Failure(payload) {
        return {
            type: EDIT_NEWS_FAILURE,
            payload
        }
    }

}