import React, { Component } from 'react'
import { connect } from "react-redux";
import { Layout } from "../../components";
import UserAction from '../../store/actions/UserAction';
import { Images, Colors } from "../../config";
import { Row, Col, Card, Switch, Popover } from 'antd';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { AiOutlineDelete } from "react-icons/ai";
// import { DeleteOutlined  } from '@ant-design/icons';

const { Meta } = Card;

class UserDetails extends Component {
    constructor() {
        super();
        this.state = {
            isActiveUser: false,
            deleteModal: false,
            profileId: ''
        };
    }
    componentDidMount() {
        const { GetUserDetails, history } = this.props
        const { location: { state: { id } } } = history
        GetUserDetails(id,
            // () => history.push('/users')
        )
    }
    componentDidUpdate(preProps, preState) {
        if (preProps.userDetails !== this.props.userDetails) {
            this.setState({
                isActiveUser: this.props.userDetails.status == 1 ? false : true
            })

        }
    }
    handleChangeStatus = (value) => {

        const { userDetails, UpdateUser_Status } = this.props;
        this.setState({
            isActiveUser: value == true ? 2 : 1
        }, () => {
            UpdateUser_Status({
                userID: userDetails.id,
                status: this.state.isActiveUser
            })
        })
    }

    handleOpen = (id) => {
        this.setState({
            deleteModal: true, profileId: id
        })
    }
    handleClose = () => {
        this.setState({
            deleteModal: false, deleteModal: ''
        })
    }
    deleteProfile = () => {
        const { profileId, deleteModal } = this.state
        const { Delete_UserProfile, history } = this.props
        const { location: { state: { id } } } = history
        const objDelProfile = {
            profileId,
            id
        }
        Delete_UserProfile(objDelProfile)
        this.setState({ deleteModal: false })
    }

    render() {
        const { history, userDetails, profiles } = this.props;
        const { deleteModal, profileId } = this.state
        
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundImage: 'linear-gradient(#2ead9d, #115c54)',
            border: 'none',
            borderRadius: '11px',
            boxShadow: 24,
            p: 4,
        };
        return (
            <Layout.DashboardHeader
                columnSpacing={5}
                customizeStyle={{
                    display: "flex",
                    padding: 25,
                    backgroundColor: "#fffcfc",
                    marginLeft: 2,
                    width: '20rem'
                }}
            >
                <Col span={24} style={{ marginBottom: '10px' }}>
                    <div className='goBackFilled' onClick={() => history.replace('/users')}>
                        <img src={Images.GoBackFilled} />
                        <p className='goBack_para_text' style={{ color: Colors.Primary }}
                        >Back to Users Details</p>
                    </div>
                </Col>
                <Row className='userDetails_Box'>
                    <Col sm={24} md={24} lg={24} xl={8}>
                        <Card bordered={false} className='userDetails_Info'>
                            <Row className='userCard' >
                                <Col sm={24} lg={12}>
                                    <img className='usrDetail_Image' src={userDetails?.profile_image ? userDetails?.profile_image : Images.UserDefaultImg} />
                                </Col>
                                <Col sm={24} lg={12} className='userDetail_card'>
                                    <p className='userName_para'>{userDetails?.full_name}</p>
                                    <p className='userEmail_para'>{userDetails?.email}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='userDetails_Switch'>
                                    <p>User is {this.state.isActiveUser ? 'in-active' : 'active'}</p>
                                    <Switch checked={this.state.isActiveUser == 1 ? true : false} onChange={(e) => this.handleChangeStatus(e)} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={16} >
                        <Row style={{ marginTop: '24px' }}>
                            <Card className='userProfilesDiv' title="User Profiles">
                                <Row gutter={[10, 10]}>
                                    {(profiles ? profiles : []).map((posts, index) => {
                                        

                                        return (
                                            <Col key={index} xs={24} sm={24} md={12} lg={12} xl={6}>
                                                <Card
                                                    cover={<img src={posts.profile_image} style={{
                                                        width: '100%',
                                                        height: '13rem',
                                                        objectFit: 'cover'
                                                    }} />}>
                                                    <Popover content={
                                                        <div style={{ display: 'flex', fontSize: '14px', color: 'red', cursor: 'pointer' }}>
                                                            <div><AiOutlineDelete /></div>
                                                            <div onClick={() => this.handleOpen(posts?.id)}>Delete Profile</div>
                                                        </div>}
                                                    // onVisibleChange={() => alert('profile id -->', profiles.id)}
                                                    >
                                                        <span type="primary" >...</span>
                                                    </Popover>
                                                    {/* <span>...</span> */}
                                                    <Meta title={posts.username}
                                                        description={posts.location} />
                                                </Card>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Card>
                        </Row>
                    </Col>
                </Row>
                <Modal
                    open={deleteModal}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: '#FFFFFF' }}>
                            Are you sure you want to delete ?
                        </Typography>
                        <Grid span={12} style={{ display: 'flex', marginTop: '50px' }} >
                            <Col md={12}>
                                <button className='btnModal' onClick={() => this.deleteProfile()}>Yes</button>
                            </Col>
                            <Col md={12}>
                                <button className='btnModal' onClick={this.handleClose}>No</button>
                            </Col>
                        </Grid>
                    </Box>
                </Modal>
            </Layout.DashboardHeader>
        )
    }
}
function mapStateToProps(state) {

    return {
        userDetails: state.UserReducer.userDetails,
        profiles: state.UserReducer.profiles
    };
}

function mapDispatchToProps(dispatch) {
    return {
        GetUserDetails: (id) => dispatch(UserAction.GetUserDetails(id)),
        UpdateUser_Status: (payload) => dispatch(UserAction.UpdateUser_Status(payload)),
        Delete_UserProfile: (payload) => dispatch(UserAction.Delete_UserProfile(payload))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
