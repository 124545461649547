import {
    GET_MESSAGES, GETMESSAGES_SUCCESS, DELETE_MESSAGE, SEND_MESSAGE
} from '../constants'

export default class MessageAction {
    static GetMessages(payload) {
        return {
            type: GET_MESSAGES,
            payload
        }
    }
    static GetMessages_Success(payload, meta_data) {
        return {
            type: GETMESSAGES_SUCCESS,
            payload,
            meta_data
        }
    }

    static DeleteMsg(payload) {

        return {
            type: DELETE_MESSAGE,
            payload
        }
    }

    static SendMessage(payload) {

        return {
            type: SEND_MESSAGE,
            payload
        }
    }
}