import React from "react";
import TextField from '@mui/material/TextField';
import { Input } from 'antd';

const Basic = ({ variant, Outlined, id, type, disabled, required, label, multiline, placeholder, defaultValue, size, error, helperText, onChange, onClick, startIcon, endIcon, customStyle, containerStyle, fieldStyle, ...props }) =>
(

    <div style={containerStyle}>
        <Input
            sx={{ width: 200, ...customStyle}}
            id={id} //outlined-basic //filled-basic //standard-basic //outlined-read-only-input
            label={label}
            variant={variant} //outlined //filled //standard
            type={type}
            disabled={disabled}
            required={required}
            defaultValue={defaultValue}
            error={error}
            helperText={helperText}
            style={fieldStyle}
            onChange={onChange}
            placeholder={placeholder}
            multiline={multiline}
            size={size}
            {...props}
        />
        {/* </TextField> */}

    </div>
)

export default Basic;
