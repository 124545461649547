import { MessageActions } from "../actions";
import { ApiCaller } from "../../config";
import { put, delay } from "redux-saga/effects";
import { Utils } from "../../config";
import {
    GETALL_MESSAGES,
    DELETEMESSAGE_ENDPOINT,
    SENDMESSAGE_ENDPOINT
} from "../../config/variables";


export default class NewsMiddleware {
    static *GetMessages({ payload }) {
        const { page, limit } = payload
        try {
            let res = yield ApiCaller.Get(
                `${GETALL_MESSAGES}?page=${page}&limit=${limit}`
            );
            if (res.status == 200) {
                yield put(MessageActions.GetMessages_Success(res.data.data.data, res.data.data.meta));
            }
            // else {

            // }
        }
        catch (err) {
            // yield put(AuthAction.SignInFailure());
        }
    }

    static *DeleteMessage({ payload }) {
        const { id, page } = payload

        try {
            let res = yield ApiCaller.Delete(`${DELETEMESSAGE_ENDPOINT}/${id}`);
            
            if (res.status == 200) {
                const GetMsgData = {
                    page,
                    limit: 8
                }
                yield put(MessageActions.GetMessages(GetMsgData));
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "success",
                });
            }
            else {
                Utils.showSnackBar({
                    message: res.data.message,
                    severity: "error",
                });
            }
        }
        catch (err) {

            // yield put(AuthAction.SignInFailure());
        }
    }

    static *SendMessage({ payload }) {

        const { userId, reply, userEmail, page } = payload
        const userData = {
            email: userEmail,
            message: reply
        }

        try {
            let res = yield ApiCaller.Post(`${SENDMESSAGE_ENDPOINT}/${userId}/reply`, userData);
            if (res.status == 200) {
                const GetMsgData = {
                    page,
                    limit: 8
                }
                yield put(MessageActions.GetMessages(GetMsgData));
            }
        } catch (err) {

            // yield put(AuthAction.ResetPasswordFailure());
        }
    }

}