import {
    GET_REPORTED_USERS, GET_REPORTEDUSERS_SUCCESS, GET_REPORTEDUSERS_FAILURE,
    GET_REPORTEDPROFILES, GET_REPORTEDPROFILES_SUCCESS,
    IGNORE_USERREPORT, IGNORE_USERREPORT_SUCCESS,
    GET_REPORTEDPCOMMENTS, GET_REPORTEDPCOMMENTS_SUCCESS,
    IGNORE_REPORTEDCOMMENTS,
    BAN_USER, BAN_USER_SUCCESS
} from '../constants'

const initialState = {
    isLoading: false,
    reportedUsers: [],
    reportedProfiles: [],
    reportedComments: [],
}

export default function ReportedUsers(state = initialState, action) {
    switch (action.type) {
        case GET_REPORTED_USERS:
            state = {
                ...state,
                isLoading: true
            }
            break;

        case GET_REPORTEDUSERS_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                reportedUsers: action.payload
            }
            break;

        // -----------------------REPORTED PROFILES---------------------

        case GET_REPORTEDPROFILES:
            state = {
                ...state,
                isLoading: true,
            }
            break;

        case GET_REPORTEDPROFILES_SUCCESS:

            state = {
                ...state,
                isLoading: false,
                reportedProfiles: action.payload
            }
            break;
        // ------------------IGNORE USER REPORT ---------------------

        case IGNORE_USERREPORT:

            state = {
                ...state,
                isLoading: false,
            }
            break;

        // ------------------REPORTED COMMENTS  ---------------------

        case GET_REPORTEDPCOMMENTS:

            state = {
                ...state,
                isLoading: true,
            }
            break;

        case GET_REPORTEDPCOMMENTS_SUCCESS:

            state = {
                ...state,
                isLoading: false,
                reportedComments: action.payload
            }
            break;
        // ------------------IGNORE COMMENTS REPORT ---------------------

        case IGNORE_REPORTEDCOMMENTS:

            state = {
                ...state,
                isLoading: false,
            }
            break;

        // ------------------BAN USER ---------------------
        case BAN_USER:

            state = {
                ...state,
                isLoading: false,
            }
            break;


        default:
            break;
    }
    return state;

}