import React, { Component } from 'react'
import { connect } from "react-redux";
import { Layout } from '../../components'
import { Tabs, Select, Row, Col, Card, Modal, Pagination } from 'antd';
import { RiCheckLine } from 'react-icons/ri'
import ReportedUsersActions from '../../store/actions/ReportedUsersActions';
import moment from 'moment';
import { Images } from '../../config'


const { TabPane } = Tabs;
const { Option } = Select;
const { Meta } = Card;


class Reported extends Component {
    constructor() {
        super()
        this.state = {
            reportUser_card_modal: false,
            reportedProfile_Modal: false,
            reportedComm_Modal: false,
            reportedModal_Data: {},
            repUser_page: 1,
            repProfile_page: 1,
            repComments_page: 1,
            filterValue: '',
            user_confirmModal: false,
            profile_confirmModal: false,
            comment_confirmModal: false,

            repoUserType: '',
            repoProfileType: '',
            repoCommentType: '',

            userImage: '',
            profileImage: '',
        }
    }

    componentDidMount() {
        const { Get_ReportedUsers, Get_ReportedProfile, Get_ReportedComments, Ignore_CommentsReport } = this.props
        const { filterValue } = this.state

        const objReported_usr = {
            page: 1,
            filterBy: filterValue ? filterValue : ''
        }
        Get_ReportedUsers(objReported_usr);

        const objReported_prf = {
            page: 1,
            filterBy: filterValue ? filterValue : ''
        }

        Get_ReportedProfile(objReported_prf)

        const objReported_comm = {
            page: 1,
            filterBy: filterValue ? filterValue : ''
        }
        Get_ReportedComments(objReported_comm)

    }

    handleClose_ReportUserModal = () => {
        this.setState({
            reportUser_card_modal: false, reportedModal_Data: ''
        })
    }

    handleOpen_ReportUserModal = (data) => {
        this.setState({
            reportUser_card_modal: true,
            reportedModal_Data: data,
            userImage: data?.reportee?.profile_image
        })
    }

    handleOpen_ReportedPro_Modal = (data) => {
        this.setState({
            reportedProfile_Modal: true,
            reportedModal_Data: data,
            profileImage: data?.reportee?.profile_image
        })
    }

    handleOpen_ReportedComm_Modal = (data) => {

        this.setState({
            reportedComm_Modal: true,
            reportedModal_Data: data,
        })
    }

    handleClose_ReportedComm_Modal = (data) => {
        this.setState({
            reportedComm_Modal: false,
            reportedModal_Data: '',
        })
    }

    handleClose_ReportedPro_Modal = () => {
        this.setState({
            reportedProfile_Modal: false,
            reportedModal_Data: ''
        })
    }

    handlefilter = (value) => {
        const { Get_ReportedUsers, Get_ReportedProfile, Get_ReportedComments } = this.props;
        const { repUser_page, repProfile_page } = this.state;
        this.setState({
            filterValue: value
        })

        const objUser_Filter = {
            page: repUser_page,
            filterBy: value
        }

        const obProfile_Filter = {
            page: repProfile_page,
            filterBy: value
        }

        const obComments_Filter = {
            page: repProfile_page,
            filterBy: value
        }

        Get_ReportedUsers(objUser_Filter)
        Get_ReportedProfile(obProfile_Filter)
        Get_ReportedComments(obComments_Filter)

    }

    handleRep_UsrPages = (pageNumber) => {
        const { Get_ReportedUsers } = this.props
        const { filterValue } = this.state

        this.setState({ repUser_page: pageNumber })

        const objReported_usr = {
            page: pageNumber,
            filterBy: filterValue ? filterValue : ''
        }

        Get_ReportedUsers(objReported_usr);
    };

    handleRep_ProfilePages = (pageNumber) => {
        const { Get_ReportedProfile } = this.props
        const { filterValue } = this.state
        this.setState({ repProfile_page: pageNumber })
        const objReported_Pro = {
            page: pageNumber,
            filterBy: filterValue ? filterValue : ''
        }

        Get_ReportedProfile(objReported_Pro);
    };

    handleRep_CommentPage = (pageNumber) => {
        const { Get_ReportedComments } = this.props
        const { filterValue } = this.state

        this.setState({ repComments_page: pageNumber })

        const objReported_comment = {
            page: pageNumber,
            filterBy: filterValue ? filterValue : ''
        }

        Get_ReportedComments(objReported_comment)
    }

    ignoreUserRepo = () => {
        const { repUser_page, reportedModal_Data } = this.state
        const { Ignore_UserReport } = this.props
        const ignoreRepo_data = {
            id: reportedModal_Data?.id,
            page: repUser_page
        }
        Ignore_UserReport(ignoreRepo_data)

        this.setState({
            reportUser_card_modal: false,
            reportedModal_Data: ''
        })
    }

    handleOpen_ProfileConfirmModal = (type) => {
        this.setState({
            profile_confirmModal: true, reportedProfile_Modal: false, repoProfileType: type
        })
    }

    handleOpen_UserConfirmModal = (type) => {
        this.setState({
            user_confirmModal: true, reportUser_card_modal: false, repoUserType: type
        })
    }

    handleDelete_RepoUser = () => {
        const { repUser_page, reportedModal_Data } = this.state
        this.setState({
            reportUser_card_modal: false
        })

        const { Ignore_UserReport } = this.props
        const ignoreRepo_data = {
            id: reportedModal_Data?.id,
            page: repUser_page
        }
        Ignore_UserReport(ignoreRepo_data)
        this.setState({
            user_confirmModal: false
        })

    }

    handleBan_RepoUser = () => {
        const { Ban_User } = this.props
        const { repUser_page, reportedModal_Data, filterValue } = this.state
        const objBanUser = {
            id: reportedModal_Data?.id,
            status: 2,
            page: repUser_page,
            filterBy: filterValue
        }
        Ban_User(objBanUser)
        this.setState({
            user_confirmModal: false,
            reportedModal_Data: ''
        })

    }

    handleDelete_Profile = () => {
        const { repProfile_page, reportedModal_Data, filterValue } = this.state
        const { Delete_Profile } = this.props
        const objDel = {
            page: repProfile_page,
            filterBy: filterValue,
            id: reportedModal_Data?.profile_id
        }
        Delete_Profile(objDel)

        this.setState({
            reportedModal_Data: '',
            profile_confirmModal: false
        })
    }

    handleIgnore_RepoProfile = () => {
        const { repProfile_page, reportedModal_Data } = this.state
        this.setState({
            reportedProfile_Modal: false
        })

        const { Ignore_ProfileReport } = this.props

        const ignoreRepo_data = {
            id: reportedModal_Data?.id,
            page: repProfile_page
        }
        Ignore_ProfileReport(ignoreRepo_data)

        this.setState({
            profile_confirmModal: false,
            reportedModal_Data: ''
        })
        // 
    }

    handleOpen_CommConfirmModal = (type) => {

        this.setState({
            comment_confirmModal: true, reportedComm_Modal: false, repoCommentType: type
        })
    }

    handleIgnore_RepoComments = () => {
        const { Ignore_CommentsReport } = this.props
        const { repComments_page, reportedModal_Data } = this.state
        const ignoreRepo_data = {
            id: reportedModal_Data?.id,
            page: repComments_page
        }

        Ignore_CommentsReport(ignoreRepo_data)
        this.setState({
            comment_confirmModal: false,
            Get_ReportedUsers: false,
            reportedModal_Data: '',
        })
    }

    handelDelete_Comment = () => {
        const { Delete_Comment } = this.props
        const { repComments_page, reportedModal_Data, filterValue } = this.state
        const objDelComment = {
            id: reportedModal_Data?.comment_id,
            page: repComments_page,
            filterBy: filterValue
        }
        Delete_Comment(objDelComment)
        this.setState({
            reportedModal_Data: '',
            comment_confirmModal: false
        })
    }

    handleCloseConfirmModal = () => {
        this.setState({
            user_confirmModal: false,
            profile_confirmModal: false,
            comment_confirmModal: false,
            reportedModal_Data: ''
        })
    }

    render() {
        const { Reported_Users, Reported_Profiles, Reported_Comments } = this.props
        const {
            reportUser_card_modal,
            reportedProfile_Modal,
            reportedModal_Data,
            user_confirmModal,
            profile_confirmModal,
            comment_confirmModal,
            reportedComm_Modal,
            userImage,
            profileImage
        } = this.state


        const { reportedUser_data, reportedUser_meta } = Reported_Users
        const { reportedProfile_data, reportedProfile_meta } = Reported_Profiles
        const { reportedComments_data, reportedComments_meta } = Reported_Comments

        return (
            <Layout.DashboardHeader
                columnSpacing={5}
                rowGap={5}
                customizeStyle={{
                    display: "flex",
                    padding: 25,
                    backgroundColor: "#fffcfc",
                    marginLeft: 2,
                    justifyContent: "flex-start",
                }}
            >
                <div className="status_filter reported_filter">
                    <Select
                        className="statusSearch"
                        defaultValue=""
                        dropdownStyle={{ backgroundColor: '#1c2b3f', color: '#FFFFFF' }}
                        menuItemSelectedIcon={
                            <RiCheckLine
                                style={{ color: '#2BA596', height: "100%" }}
                            />
                        }
                        onChange={(e) => this.handlefilter(e)}
                    >
                        <Option value="">View All</Option>
                        <Option value="fake">Fake</Option>
                        <Option value="spam">Spam</Option>
                        <Option value="inappropriate">Inappropriate</Option>
                    </Select>
                </div>

                <Row style={{ width: '100%' }}>
                    <Tabs className='reportedTabs' defaultActiveKey="2" onChange={this.callback}>
                        <TabPane tab="Reported User" key="reported_User">
                            <Row gutter={[10, 10]}>
                                {(reportedUser_data || []).map(items => {
                                    return (
                                        <Col xs={24} sm={24} md={12} lg={8}>
                                            <Card
                                                bordered={false}
                                                className='userDetails_Info'
                                                onClick={() => this.handleOpen_ReportUserModal(items)}>

                                                <Row className='userCard reported_Cards' style={{ width: '100%', cursor: 'pointer' }}>
                                                    <Col sm={24} lg={11} >
                                                        <img className='usrDetail_Image reported_profile_img'
                                                            src={items?.reportee?.profile_image ? items?.reportee?.profile_image : Images?.UserDefaultImg} />

                                                    </Col>
                                                    <Col sm={24} lg={12} className='userDetail_card reported_Profile_Details' >
                                                        <table>
                                                            <tr>
                                                                <td colSpan={2} className='reported_profileName'>
                                                                    {items?.reportee?.full_name}
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td className='reported_para'><p>Category:</p></td>
                                                                <td className='reported_para'><p>{items?.type}</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td className='reported_para'><p>Email:</p></td>
                                                                <td className='reported_para'><p> {items?.reportee?.email}</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td className='reported_para'><p>Date:</p></td>
                                                                <td className='reported_para'><p>{moment(items?.created_at).format("Do MMM YYYY")}</p></td>
                                                            </tr>
                                                        </table>
                                                    </Col>
                                                    <Col sm={24} lg={24} className='reported_textDiv' style={{ textAlign: 'left' }}>
                                                        <table>
                                                            <tr>
                                                                <td className='lables'><p>Reported By:</p></td>
                                                                <td className='reported_para'><p>{items?.reporter?.full_name}</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td className='lables'>Reason Of Report:</td>
                                                                <td className='reported_para'><p style={{ marginLeft: '5px' }}>{items?.message}</p></td>
                                                            </tr>
                                                        </table>
                                                    </Col>
                                                    <div className='reported_CardActions'>
                                                        <div>
                                                            <button className='btn_report'>
                                                                Ignore Report
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button className='btn_report'>
                                                                Ban User
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Row>

                                            </Card>
                                        </Col>
                                    )
                                })}

                            </Row>
                            <Row style={{ width: '100%', marginTop: '3rem' }}>
                                <Col span={24}>
                                    <Pagination
                                        responsive="true"
                                        showLessItems="true"
                                        defaultCurrent={1}
                                        current={reportedUser_meta?.current_page}
                                        total={reportedUser_meta?.total ? reportedUser_meta.total : 0}
                                        onChange={(p) => {
                                            this.handleRep_UsrPages(p)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </TabPane>

                        {/* --------------------------------------------Reported Profile Tab------------------------------------------------- */}

                        <TabPane tab="Reported Profile" key="reported_Profile">

                            <Row gutter={[10, 10]}>
                                {(reportedProfile_data || []).map(items => {
                                    return (
                                        <Col xs={24} sm={24} md={12} lg={12}>
                                            <Card
                                                bordered={false}
                                                className='userDetails_Info'
                                                onClick={() => this.handleOpen_ReportedPro_Modal(items)}>

                                                <Row gutter={[10, 10]} className='userCard reported_Cards' style={{ width: '100%', cursor: 'pointer' }}>

                                                    <Col sm={24} lg={11} >
                                                        <img className='usrDetail_Image reported_profile_img' style={{ width: '100%', height: '100%' }}
                                                            src={items?.reportee?.profile_image ? items?.reportee?.profile_image : Images?.UserDefaultImg} />

                                                    </Col>
                                                    <Col sm={24} lg={12} className='userDetail_card reported_Profile_Details' >
                                                        <div>
                                                            <p style={{ fontSize: '20px', fontWeight: 'bold' }}> {items?.reportee?.username}</p>
                                                        </div>
                                                        <div className='repProfile_Div'>
                                                            <p>Dating Site:</p>
                                                            <p style={{ marginLeft: '5px' }}>{items?.reportee?.platform?.name}</p>
                                                        </div>
                                                        <div className='repProfile_Div'>
                                                            <p>Profile Created By:</p>
                                                            <p style={{ marginLeft: '5px' }}>{items?.reportee?.user?.full_name}</p>
                                                        </div>
                                                        <div className='repProfile_Div'>
                                                            <p>Location:</p>
                                                            <p style={{ marginLeft: '5px' }}>{items?.reportee?.location}</p>
                                                        </div>
                                                        <div className='repProfile_Div'>
                                                            <p>Category:</p>
                                                            <p style={{ marginLeft: '5px' }}>{items?.type}</p>
                                                        </div>
                                                        <div>
                                                            <p>{moment(items?.created_at).format('Do MMM YYYY')}</p>
                                                        </div>

                                                        {/* <table>
                                                            <tr>
                                                                <td colSpan={2} className='reported_profileName'>
                                                                    {items?.reportee?.username}
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td className='reported_para'><p>Dating Site:</p></td>
                                                                <td className='reported_para'><p>{items?.reportee?.platform?.name}</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td className='reported_para'><p>Profile Created By:</p></td>
                                                                <td className='reported_para'><p>{items?.reportee?.user?.full_name}</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td className='reported_para'><p>Location:</p></td>
                                                                <td className='reported_para'><p>{items?.reportee?.location}</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td className='reported_para'><p>Category:</p></td>
                                                                <td className='reported_para'><p>{items?.type}</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td className='reported_para'><p>{moment(items?.created_at).format('Do MMM YYYY')}</p></td>
                                                            </tr>
                                                        </table> */}
                                                    </Col>
                                                    <Col sm={24} lg={24} className='reported_textDiv' style={{ textAlign: 'left' }}>
                                                        <table>
                                                            <tr>
                                                                <td className='lables'><p>Reported By:</p></td>
                                                                <td className='reported_para'><p>{items?.reporter?.full_name}</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td className='lables'>Reason Of Report:</td>
                                                                <td className='reported_para'><p style={{ marginLeft: '5px' }}>{items?.message}</p></td>
                                                            </tr>
                                                        </table>
                                                    </Col>
                                                    <div className='reported_CardActions'>
                                                        <div>
                                                            <button className='btn_report'>
                                                                Ignore Report
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button className='btn_report'>
                                                                Delete Profile
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Row>

                                            </Card>
                                        </Col>
                                    )
                                })}

                            </Row>

                            <Row style={{ width: '100%', marginTop: '3rem' }}>
                                <Col span={24}>
                                    <Pagination
                                        responsive="true"
                                        showLessItems="true"
                                        defaultCurrent={1}
                                        current={reportedProfile_meta?.current_page}
                                        total={reportedProfile_meta?.total ? reportedProfile_meta.total : 0}
                                        onChange={(p) => {
                                            this.handleRep_ProfilePages(p)
                                        }}
                                    />
                                </Col>
                            </Row>

                        </TabPane>

                        {/* ---------------------------------------------Reported comments Tab----------------------------------------------- */}
                        <TabPane tab="Reported Comments" key="3">
                            <Row gutter={[10, 10]}>
                                {(reportedComments_data || []).map(items => {
                                    return (
                                        <Col xs={24} sm={24} md={12} lg={10}>
                                            <Card className='rep_comments_card'
                                                onClick={() => this.handleOpen_ReportedComm_Modal(items)} style={{ cursor: 'pointer' }}>
                                                <Col span={24} className='card_mainHeading'>
                                                    Comment Details
                                                </Col>
                                                <Col span={24}>
                                                    <div className='RCC_headerDetails'>
                                                        <Col xs={24} sm={24} md={24} lg={5} className='repo_header_img'>
                                                            <img src={items?.reportee?.user?.profile_image} style={{ borderRadius: '11px' }} />
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={19}>
                                                            <div className='RC_header_details'>
                                                                <Col>
                                                                    {items?.reportee?.user?.full_name}
                                                                </Col>
                                                                <Col>
                                                                    <span className='span-1'>
                                                                        {moment(items?.reportee?.created_at).format("Do MMM YYYY")}

                                                                    </span>
                                                                    <span className='span-2'>|</span>
                                                                    <span className='span-3'>
                                                                        {moment(items?.reportee?.created_at).format("h:mm")}

                                                                    </span>
                                                                </Col>
                                                            </div>
                                                            <div className='RC_header_para'>
                                                                <p>
                                                                    {items?.reportee?.comment}
                                                                </p>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </Col>

                                                <Col className='C_ON_Pro_Div'>
                                                    <Col span={24} className='card_mainHeading'>
                                                        Comment On Profile
                                                    </Col>
                                                    <Col span={24} className='comments_Details' >
                                                        <Col xs={24} sm={24} md={24} lg={8}>
                                                            <img src={items?.reportee?.user?.profile_image} style={{ width: '100%', borderRadius: '11px' }} />
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={16} className='repo_comm_profile'>
                                                            <div className='profile_Details'>
                                                                <p className='pro_Name'>
                                                                    {items?.reportee?.profile?.username}
                                                                </p>
                                                                <p className='pro_Date'>13, Nov 2022</p>
                                                            </div>
                                                            <div className='site_Details'>
                                                                <p className='dating_Site'>Dating Site:</p>
                                                                <p className='site_Name'>
                                                                    {items?.reportee?.profile?.platform?.name}
                                                                </p>
                                                            </div>
                                                            <div className='cate_Details'>
                                                                <p className='category'>Category:</p>
                                                                <p className='category_Name'>{items?.type}</p>
                                                            </div>
                                                        </Col>
                                                    </Col>
                                                </Col>

                                                <Col span={24} className='repo_com_reportedBy' >
                                                    <p className='card_mainHeading reported_By'>Reported By:</p>
                                                    <p className='reportedBy_mail'>{items?.reporter?.email}</p>
                                                </Col>

                                                <Col>
                                                    <div className='reported_CardActions'>
                                                        <div>
                                                            <button
                                                                className='btn_report'
                                                            >
                                                                Ignore Report
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button className='btn_report'>
                                                                Delete Comment
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>

                                            </Card>
                                        </Col>
                                    )
                                })}

                            </Row>
                            <Row style={{ width: '100%', marginTop: '3rem' }}>
                                <Col span={24}>
                                    <Pagination
                                        responsive="true"
                                        showLessItems="true"
                                        defaultCurrent={1}
                                        current={reportedComments_meta?.current_page}
                                        total={reportedComments_meta?.total ? reportedComments_meta.total : 0}
                                        onChange={(p) => {
                                            this.handleRep_CommentPage(p)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Row>


                {/* -------Reported User Modal------------ */}
                <Modal
                    visible={reportUser_card_modal} footer={null} onCancel={this.handleClose_ReportUserModal} className='delConfirmModal'
                >
                    <Row className='' style={{ width: '100%' }}>
                        <Col sm={24} lg={8} >
                            <img className='usrDetail_Image reported_profile_img' src={userImage ? userImage : Images?.UserDefaultImg} />
                        </Col>
                        <Col sm={24} lg={16} className='userDetail_card reported_Profile_Details' >
                            <div>
                                <p className='modal_userName'>
                                    {reportedModal_Data?.reportee?.full_name}
                                </p>
                            </div>
                            <div className='details_box'>
                                <p>
                                    {reportedModal_Data?.reportee?.email}
                                </p>
                            </div>
                            <div className='details_box'>
                                <p>
                                    {moment(reportedModal_Data?.created_at).format("Do MMM YYYY")}
                                </p>
                            </div>

                        </Col>
                        <Col sm={24} lg={24} style={{ textAlign: 'left' }}>
                            <div className='reported_Reason_div' >
                                <Col xs={24} sm={24} md={24} lg={8}>
                                    <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#FFFFFF' }}>
                                        Reason Of Report:
                                    </span>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16}>
                                    <p className='reason_Para'>
                                        {reportedModal_Data?.message}
                                    </p>
                                </Col>
                            </div>
                        </Col>

                        <div className='reported_CardActions'>
                            <div>
                                <button
                                    className='btn_report'
                                    onClick={() => this.handleOpen_UserConfirmModal("ignoreReport")}
                                >
                                    Ignore Report
                                </button>
                            </div>
                            <div>
                                <button className='btn_report'
                                    disabled={reportedModal_Data?.status == 1 ? false : true}
                                    onClick={() => this.handleOpen_UserConfirmModal('banUser')}
                                >
                                    Ban User
                                </button>
                            </div>
                        </div>
                    </Row>
                </Modal>

                {/* ------------Reported Profile Modal------------ */}

                <Modal
                    visible={reportedProfile_Modal} footer={null} onCancel={this.handleClose_ReportedPro_Modal} className='delConfirmModal'
                >
                    <Row className='reported_Cards' style={{ width: '100%' }}>
                        <Col sm={24} lg={8} >
                            <img className='usrDetail_Image reported_profile_img' src={profileImage ? profileImage : Images?.UserDefaultImg} />
                        </Col>
                        <Col sm={24} lg={16} className='userDetail_card reported_Profile_Details' >
                            <div>
                                <p className='modal_userName'>
                                    {reportedModal_Data?.reportee?.user?.full_name}
                                </p>
                            </div>
                            <div className='details_box'>
                                <p>
                                    {reportedModal_Data?.reportee?.user?.email}
                                </p>
                            </div>
                            <div className='details_box'>
                                <p>
                                    {moment(reportedModal_Data?.created_at).format("Do MMM YYYY")}
                                </p>
                            </div>
                            <div className='details_box'>
                                <p>
                                    Dating Site:
                                </p>
                                <p className='val'>
                                    {reportedModal_Data?.reportee?.platform?.name}
                                </p>
                            </div>

                        </Col>
                        <Col sm={24} lg={24} style={{ textAlign: 'left' }}>
                            <div className='reported_Reason_div' >
                                <Col xs={24} sm={24} md={24} lg={8}>
                                    <span className='lables'>
                                        Reason Of Report:
                                    </span>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16}>
                                    <p className='reason_Para'>
                                        {reportedModal_Data?.message}
                                    </p>
                                </Col>
                            </div>
                        </Col>
                        <div className='reported_CardActions'>
                            <div>
                                <button
                                    className='btn_report'
                                    onClick={() => this.handleOpen_ProfileConfirmModal('ignoreProfile')}
                                >
                                    Ignore Report
                                </button>
                            </div>
                            <div>
                                <button className='btn_report'
                                    // disabled={reportedModal_Data?.reportee !== '' || null ? true : false}
                                    onClick={() => this.handleOpen_ProfileConfirmModal('deleteProfile')}
                                >
                                    Delete Profile
                                </button>
                            </div>
                        </div>
                    </Row>
                </Modal>

                {/* ------------Reported Comments Modal------------ */}
                {}
                <Modal
                    visible={reportedComm_Modal} footer={null} onCancel={this.handleClose_ReportedComm_Modal} className='delConfirmModal'
                >
                    <Row gutter={[10, 10]} className='reported_Cards' style={{ width: '100%' }}>

                        <Col span={24} className='userDetail_card reported_Profile_Details' >
                            <Col span={24} className='card_mainHeading'>
                                Comment Details
                            </Col>
                            <Col span={24}>
                                <div className='RCC_headerDetails'>
                                    <Col xs={24} sm={24} md={24} lg={5} className='repo_header_img'>
                                        <img src={reportedModal_Data?.reportee?.user?.profile_image_thumbnail} style={{ borderRadius: '11px' }} />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={19}>
                                        <div className='RC_header_details'>
                                            <Col>
                                                {reportedModal_Data?.reportee?.user?.full_name}
                                            </Col>
                                            <Col>
                                                <span className='span-1'>
                                                    {moment(reportedModal_Data?.reportee?.created_at).format("Do MMM YYYY")}

                                                </span>
                                                <span className='span-2'>|</span>
                                                <span className='span-3'>
                                                    {moment(reportedModal_Data?.reportee?.created_at).format("h:mm")}

                                                </span>
                                            </Col>
                                        </div>
                                        <div className='RC_header_para'>
                                            <p>
                                                {reportedModal_Data?.reportee?.comment}
                                            </p>
                                        </div>
                                    </Col>
                                </div>
                            </Col>

                            <Col className='C_ON_Pro_Div'>
                                <Col span={24} className='card_mainHeading'>
                                    Comment On Profile
                                </Col>
                                <Col span={24} className='comments_Details' >
                                    <Col xs={24} sm={24} md={24} lg={8}>
                                        <img src={reportedModal_Data?.reportee?.user?.profile_image} style={{ width: '100%', borderRadius: '11px' }} />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={16} className='repo_comm_profile'>
                                        <div className='profile_Details'>
                                            <p className='pro_Name'>
                                                {reportedModal_Data?.reportee?.profile?.username}
                                            </p>
                                            <p className='pro_Date'>13, Nov 2022</p>
                                        </div>
                                        <div className='site_Details'>
                                            <p className='dating_Site'>Dating Site:</p>
                                            <p className='site_Name'>
                                                {reportedModal_Data?.reportee?.profile?.platform?.name}
                                            </p>
                                        </div>
                                        <div className='cate_Details'>
                                            <p className='category'>Category:</p>
                                            <p className='category_Name'>{reportedModal_Data?.type}</p>
                                        </div>
                                    </Col>
                                </Col>
                            </Col>

                            <Col span={24} className='repo_com_reportedBy' >
                                <p className='card_mainHeading reported_By'>Reported By:</p>
                                <p className='reportedBy_mail'>{reportedModal_Data?.reporter?.email}</p>
                            </Col>


                        </Col>
                        <div className='reported_CardActions'>
                            <div>
                                <button
                                    className='btn_report'
                                    onClick={() => this.handleOpen_CommConfirmModal('ignoreComment')}
                                >
                                    Ignore Report
                                </button>
                            </div>
                            <div>
                                <button className='btn_report'
                                    onClick={() => this.handleOpen_CommConfirmModal('deleteComment')}
                                >
                                    Delete Comment
                                </button>
                            </div>
                        </div>
                    </Row>
                </Modal>

                {/* -----------User Confirmation Modal ----------------- */}
                <Modal
                    visible={user_confirmModal} footer={null} onCancel={this.handleCloseConfirmModal} className='delConfirmModal'
                >
                    <Row>
                        <Col>
                            <h2 style={{ color: '#FFFFFF' }}>
                                Are you sure you want to ignore ?
                            </h2>
                        </Col>
                    </Row>
                    <Row gutter={[10, 10]}>
                        <Col span={24} style={{ display: 'flex', marginTop: '50px' }} >
                            <Col md={12}>
                                <button className='btnModal'
                                    onClick={() => this.state.repoUserType == 'ignoreReport' ? this.handleDelete_RepoUser() : this.handleBan_RepoUser()}>
                                    Yes
                                </button>
                            </Col>
                            <Col md={12}>
                                <button className='btnModal'
                                    onClick={this.handleCloseConfirmModal}
                                >No</button>
                            </Col>
                        </Col>
                    </Row>
                </Modal>

                {/* -----------Profile Confirmation Modal ----------------- */}
                <Modal
                    visible={profile_confirmModal} footer={null} onCancel={this.handleCloseConfirmModal} className='delConfirmModal'
                >
                    <Row>
                        <Col>
                            <h2 style={{ color: '#FFFFFF' }}>
                                Are you sure you want to delete ?
                            </h2>
                        </Col>
                    </Row>
                    <Row gutter={[10, 10]}>
                        <Col span={24} style={{ display: 'flex', marginTop: '50px' }} >
                            <Col md={12}>
                                <button className='btnModal'
                                    onClick={() => this.state.repoProfileType == 'ignoreProfile' ? this.handleIgnore_RepoProfile() : this.handleDelete_Profile()}>
                                    Yes
                                </button>
                            </Col>
                            <Col md={12}>
                                <button className='btnModal'
                                    onClick={this.handleCloseConfirmModal}
                                >No</button>
                            </Col>
                        </Col>
                    </Row>
                </Modal>

                {/* -----------Comments Confirmation Modal ----------------- */}
                <Modal
                    visible={comment_confirmModal} footer={null} onCancel={this.handleCloseConfirmModal} className='delConfirmModal'
                >
                    <Row>
                        <Col>
                            <h2 style={{ color: '#FFFFFF' }}>
                                Are you sure you want to delete ?
                            </h2>
                        </Col>
                    </Row>
                    <Row gutter={[10, 10]}>
                        <Col span={24} style={{ display: 'flex', marginTop: '50px' }} >
                            <Col md={12}>
                                <button className='btnModal'
                                    onClick={() => this.state.repoCommentType == 'ignoreComment' ? this.handleIgnore_RepoComments() : this.handelDelete_Comment()}>
                                    Yes
                                </button>
                            </Col>
                            <Col md={12}>
                                <button className='btnModal'
                                    onClick={this.handleCloseConfirmModal}
                                >No</button>
                            </Col>
                        </Col>
                    </Row>
                </Modal>
            </Layout.DashboardHeader>

        )
    }

}
function mapStateToProps(state) {


    return {
        Reported_Users: state.ReportedUser_Reducer.reportedUsers,
        Reported_Profiles: state.ReportedUser_Reducer.reportedProfiles,
        Reported_Comments: state.ReportedUser_Reducer.reportedComments,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        Get_ReportedUsers: (payload) => dispatch(ReportedUsersActions.Get_ReportedUsers(payload)),
        Get_ReportedProfile: (payload) => dispatch(ReportedUsersActions.Get_ReportedProfile(payload)),
        Ignore_UserReport: (payload) => dispatch(ReportedUsersActions.Ignore_UserReport(payload)),
        Get_ReportedComments: (payload) => dispatch(ReportedUsersActions.Get_ReportedComments(payload)),
        Ignore_ProfileReport: (payload) => dispatch(ReportedUsersActions.Ignore_ProfileReport(payload)),
        Ignore_CommentsReport: (payload) => dispatch(ReportedUsersActions.Ignore_CommentsReport(payload)),
        Ban_User: (payload) => dispatch(ReportedUsersActions.Ban_User(payload)),
        Delete_Profile: (payload) => dispatch(ReportedUsersActions.Delete_Profile(payload)),
        Delete_Comment: (payload) => dispatch(ReportedUsersActions.Delete_Comment(payload)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Reported)