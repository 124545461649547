const Colors = {
    PlaceHolder: (opacity = "0.5") => `rgba(235, 235, 235, ${opacity})`,
    BlackOpacity: (opacity = "0.5") => `rgba(0, 0, 0, ${opacity})`,
    WhiteOpacity: (opacity = "0.5") => `rgba(255, 255, 255, ${opacity})`,
    Black: "#000000",
    Transparent: "transparent",
    Primary: "#2BA596",
    Secondary: "#2BA596",
    DarkGray: "#1E1E22",
    DarkBlue:'#415465',
    White: "#ffffff",
    Text: "#ffffff",
    Danger: "#FF494C",
    Dark: "#1E2D4A",
    Border: "#D4D4D4",
    MainHeading: '#1E1E22'
}

export default Colors;