import * as React from 'react';
import Badge from '@mui/material/Badge';

const Basic = ({ badgeContent, color, max, variant, Icon, containerStyle, sx, ...props }) => {
    return (
        <div style={containerStyle}>
            <Badge
                badgeContent={badgeContent} //number
                color={color} //primary //secondary //success
                max={max} //number
                variant={variant}
                sx={sx}
            >
                <Icon />
            </Badge>
        </div>
    )
}

export default Basic;
