
import './App.css';
import React, { Component } from "react";
import { Loader, Popup } from "./components"
import { Colors, Utils } from "./config"
import AuthRoutes from "./config/routes/AuthRoutes"
import { connect } from "react-redux"
const { saveSnackbarRef } = Utils

class App extends Component {
  constructor() {
    super()
    this.state = {
      loader: false
    }
  }

  render() {
    
    if (this.props.loader) {
      document.body.style.overflow = 'hidden'
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else {

      document.body.style.overflow = 'auto'

    }
    return (
      <div>
        <AuthRoutes />
        <Popup.Snackbar ref={ref => saveSnackbarRef(ref)} />

        {this.props.loader ?
          <div style={{
            display: "flex",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Colors.BlackOpacity(0.7),
          }}>
            <Loader.Circular sx={{ color: Colors.WhiteOpacity(0.7) }} />
          </div>
          : null}
      </div>
    );

  }
}

function mapStateToProps(state) {
  
  return ({
    loader: state.UserReducer.isLoading ||
      state.MessageReducer.isLoading ||
      state.ReportedUser_Reducer.isLoading ||
      state.NewsReducer.isLoading
  })
}
function mapDispatchToProps(dispatch) {
  return ({
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

