// Dev base URL 
export const API_BASE_URL = "https://api.ratemydate.uk:3005/api";

// UAT base URL 
// baseUrl = "";

// Live URL
// baseUrl = "";

//AUTH ENDPOINTS
export const LOGIN_ENDPOINT = "/admin/login";
export const FORGETPASSWORD_ENDPOINT = "/admin/request-reset-password";
export const VERIFYEMAIL_ENDPOINT = "/admin/send-code";
export const RESETPASSWORD_ENDPOINT = "/admin/reset-password";
export const CHANGEPASSWORD_ENDPOINT = "/auth/change-password";
export const LOGOUT_ENDPOINT = "/admin/logout";
export const VERIFY_CODE = '/admin/verify-email'

export const RESEND_CODE = '/admin/send-code'

//APP ENDPOINTS
export const GETUSERCARDSCOUNT_ENDPOINT = "/admin/user-count";
export const GETUSERS_ENDPOINT = "/admin/all-users";
export const GETUSERDETAILS_ENDPOINT = "/user/profile";
export const UPDATEUSERSTATUS_ENDPOINT = "/admin/update-user";
// export const DELETE_USERPROFILE = "/profile";

export const CREATENEWS_ENDPOINT = "/admin/news";
export const NEWUSERSGRAPH_ENDPOINT = "/admin/profile-count";
export const ANDROIDGRAPH_ENDPOINT = "/admin/platform-user-count";
export const GETORDERCOUNT_ENDPOINT = "/admin/total-order"

export const GETNEWS_ENDPOINT = "/admin/news"
export const DELETENEWS_ENDPOINT = "/admin/news"
export const GETNEWSDETAILS_ENDPOINT = "/admin/news"
export const EDITNEWS_ENDPOINT = "/admin/news"

export const GETALL_MESSAGES = "/admin/message"
export const DELETEMESSAGE_ENDPOINT = "/admin/message"
export const SENDMESSAGE_ENDPOINT = "/admin/message"
export const GET_REPORTEDUSERS_ENDPOINT = "/admin/user-report"
export const GET_PROFILEREPORTS_ENDPOINT = "/admin/profile-report"
export const IGNOREREPORT_ENDPOINT = "/admin/user-report"
export const IGNOREREPORTRD_PROFILE_ENDPOINT = "/admin/profile-report"
export const GET_REPORTEDCOMMENTS_ENDPOINT = "/admin/comment-report"
export const IGNOREREPORTRD_COMMENTS_ENDPOINT = "/admin/comment-report"
export const DELETEPROFILE_ENDPOINT = "/profile"
export const DELETECOMMENT_ENDPOINT = "/comment"



const configVariables = {
    termsUrl: "",
    privacyUrl: "",
    contactSupportMail: "",
}

const constantVariables = {
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    messageForAppShare: "",
    subjectForAppShare: ""
}

export default {
    ...configVariables,
    ...constantVariables
}