import React from "react";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const Input = styled('input')({
    display: 'none',
});

const Upload = ({ variant, text, size, color, disabled, disableElevation, fullWidth, startIcon, endIcon, onChange, value, containerStyle, buttonStyle, sx, ...props }) => (
    <div style={containerStyle}>
        <label htmlFor="contained-button-file">
            <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={onChange} value={value} />
            <Button
                component="span"
                variant={variant} // text | contained | outlined
                size={size} // 'small'| 'medium'| 'large'
                color={color}
                startIcon={startIcon}
                endIcon={endIcon}
                disabled={disabled}
                disableElevation={disableElevation}
                fullWidth={fullWidth}
                style={buttonStyle}
                sx={sx}
                {...props}
            >
                {text || "Upload"}
            </Button>
        </label>
    </div>
)

export default Upload;
