import Logo from "../../assets/images/Logo.svg";
import BackgroundImage from "../../assets/images/BackgroundImage.svg";
import LogoSider from "../../assets/images/Logoooo.svg";
import UsersIcon from "../../assets/images/userIcon.png";
import TravelPlanIcon from "../../assets/images/travelPlanIcon.svg";
import OrdersIcon from "../../assets/images/ordersIcon.svg";
import Users from "../../assets/images/Users.svg";
import MsgsIcon from "../../assets/images/msgsIcon.svg";
import MsgsIconClicked from "../../assets/images/msgIconClicked.png";
import CalenderFilterIcon from "../../assets/images/userCalenderFilter.svg";
import TotalUsersIcon from "../../assets/images/totaluserpic.svg";
import ActiveUsersIcon from "../../assets/images/activeuserpic.svg";
import BannedDeletedUserIcon from "../../assets/images/banneddeleteduserpic.svg";
import DashboardIcon from "../../assets/images/dashboardIcon.png";
import GraphCalenderIcon from "../../assets/images/GraphCalenderIcon.svg";
import DashboardIconClicked from "../../assets/images/dashboardIconAfterClick.png";
import EditIcon from '../../assets/images/editIcon.svg'
import DeleteIcon from '../../assets/images/deleteIcon.svg'
import UserIconClicked from "../../assets/images/userIconClicked.png";
import FileList from '../../assets/images/fileList.svg'
import FileListClicked from '../../assets/images/fileListClicked.png'
import OrderIconClicked from "../../assets/images/orderIconClicked.svg";
import GoBack from '../../assets/images/goback.svg'
import GoBackFilled from '../../assets/images/goBackFilled.svg'
import NewsImg from '../../assets/images/newsImg.png'
import UploadImage from '../../assets/images/imgUpload.png'
import FlagIconClicked from '../../assets/images/flag-line.svg'
import FlagIcon from '../../assets/images/flagIconClicked.svg'
import UserDefaultImg from '../../assets/images/user-profile-default-img.png'
import UploadImgIcon from '../../assets/images/uploadImageIcon.jpg'

const Images = {
    Logo,
    BackgroundImage,
    LogoSider,
    UsersIcon,
    FileList,
    FileListClicked,
    TravelPlanIcon,
    OrdersIcon,
    MsgsIcon,
    MsgsIconClicked,
    CalenderFilterIcon,
    Users,
    TotalUsersIcon,
    ActiveUsersIcon,
    BannedDeletedUserIcon,
    DashboardIcon,
    DashboardIconClicked,
    GraphCalenderIcon,
    EditIcon,
    DeleteIcon,
    UserIconClicked,
    OrderIconClicked,
    GoBack,
    GoBackFilled,
    NewsImg,
    UploadImage,
    FlagIcon,
    FlagIconClicked,
    UserDefaultImg,
    UploadImgIcon
}

export default Images;