import {
  GETUSERSCARDCOUNT, GETUSERSCARDCOUNT_SUCCESS, GETUSERSCARDCOUNT_FAILURE,
  USER_GRAPH, USER_GRAPH_SUCCESS, USER_GRAPH_ERROR,
  ANDROID_GRAPH, ANDROID_GRAPH_SUCCESS,
  ANDROID_GRAPH_ERROR
} from '../constants'


export default class DashboardAction {
  static GetUsersCardCount(to, from, userType) {
    return {
      type: GETUSERSCARDCOUNT,
      to,
      from,
      userType
    }
  }
  static GetUsersCardCountSuccess(payload) {
    return {
      type: GETUSERSCARDCOUNT_SUCCESS,
      payload
    }
  }
  static GetUsersCardCountFailure() {
    return {
      type: GETUSERSCARDCOUNT_FAILURE
    }
  }

  //New User Graph
  //User stats actions
  static userGraphResquest(to, from) {

    return {
      type: USER_GRAPH,
      params: {
        to,
        from
      }, //Can send params to query here
    }
  }
  static userGraphSuccess(userData) {

    return {
      type: USER_GRAPH_SUCCESS,
      payload: userData,
    };
  }
  static userGraphError(errorInfo) {
    return {
      type: USER_GRAPH_ERROR,
      payload: errorInfo,
    };
  }
  //Android graph
  static androidGraphResquest(to, from) {
    //  alert(startMonth,startYear,endMonth,endYear,"kkjk")

    return {
      type: ANDROID_GRAPH,
      params: {
        startDate: from,
        endDate: to
      }, //Can send params to query here

    };
  }
  static androidGraphSuccess(payload) {

    return {
      type: ANDROID_GRAPH_SUCCESS,
      payload,
    };
  }
  static androidGraphError(errorInfo) {
    return {
      type: ANDROID_GRAPH_ERROR,
      payload: errorInfo,
    };
  }



}