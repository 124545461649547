import Basic from "./Basic"
import Upload from "./Upload"
import Floating from "./Floating"
import Toggle from "./Toggle"

export default {
    Basic,
    Upload,
    Floating,
    Toggle
};