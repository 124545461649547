import React from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const SearchTextField = ({ containerStyle, onChange, value, defaultValue, id, type, dropdownIcon, error, helperText, required, placeholder, options = [], sx, renderInput, ...props }) => {
  // Top 10 films as rated by IMDb users. http://www.imdb.com/chart/top
  
  return (
    <div style={containerStyle}>
      <Autocomplete
        //   disablePortal
        freeSolo={dropdownIcon}

        id="combo-box-demo" //combo-box-demo
        options={options}
        sx={{ width: 300, ...sx }}
        onChange={onChange}
        value={value}
    
        //renderInput={renderInput}


        renderInput={(params) => <TextField
   
          placeholder={placeholder}
          forcePopupIcon={true}
          required={required}
          error={error}
          type={type}
          helperText={helperText}
          {...params}
          {...props}
          onChange={onChange}
       
          InputLabelProps={{
            style: {
              color: "white"
            }
          }}

          defaultValue={defaultValue}
        />}
        {...props}
      />

    </div>
  )

}

export default SearchTextField;
