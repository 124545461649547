import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from "react-redux";

const Circular = ({ size, color, sx, loaderStyle, containerStyle, ...props }) => {
    return (
        <div style={containerStyle}>
            <CircularProgress
                sx={sx}
                size={size} //Number
                color={color} //secondary | success | inherit
                style={loaderStyle}
                {...props}
            />
        </div>
    );
}

export default Circular