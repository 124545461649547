import React from 'react';
import { Colors, Images } from "../../config"
import styled from "styled-components";
const Box = styled.div`
  background-image:url(${Images.BackgroundImage});
  border-color: ${Colors.Primary};
  display: flex; 
  height:100vh;
  justify-content: center;
  align-items: center;
  background-repeat:no-repeat;
  background-size: cover;

  `;
const LoginBox = (props) => {
  return (
    <>
      <Box  {...props}>
      </Box>
    </>);
}

export default React.memo(LoginBox)