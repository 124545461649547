import React, { Component } from 'react'
import { connect } from 'react-redux';
import { NewsAction } from "../../store/actions";
import { Layout } from "../../components";
import Grid from '@mui/material/Grid';
import { Images } from '../../config'
import { Row, Col } from 'antd';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


class NewsDetails extends Component {
    constructor() {
        super()
        this.state = {
            deleteModal: false
        }
    }

    componentDidMount() {
        const { history, GetNewsDetails } = this.props

        const { location: { state: { id } } } = history

        GetNewsDetails(id, () => history.push('/news'))
    }

    handleDelete = () => {
        this.setState({
            deleteModal: true
        })
    }
    handleDeleteNews = () => {
        const { history, Delete_News } = this.props;
        const objDeleteNews = {
            pageNumber: 1,
            search: '',
            id: history?.location?.state?.id
        }
        Delete_News(objDeleteNews)
        this.setState({
            deleteModal: false
        })
    }

    handleClose = () => {
        this.setState({
            deleteModal: false
        })
    }

    handleEdit = () => {
        const { singleNews_Details, history } = this.props
        history.push(`/news/editnews/${singleNews_Details?.id}`)
    }
    render() {
        const { history, singleNews_Details } = this.props
        const { deleteModal } = this.state

        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundImage: 'linear-gradient(#2ead9d, #115c54)',
            border: 'none',
            borderRadius: '11px',
            boxShadow: 24,
            p: 4,
        };

        return (
            <Layout.DashboardHeader
                columnSpacing={5}
                rowGap={5}
                customizeStyle={{
                    display: "flex",
                    padding: 25,
                    backgroundColor: "#fffcfc",
                    marginLeft: 2,
                    justifyContent: "flex-start",
                }}
            >
                <Grid sm={12} md={12}>
                    <h1 className='MainHeading'>News</h1>
                </Grid>
                <Grid sm={12} md={12} className='detailsDiv'>
                    <Row gutter={[10, 10]}>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <img src={singleNews_Details?.image} style={{ width: '100%' }} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} className='detailsDiv_headings'>
                            <h1 className='detialsDiv_MainHead'>{singleNews_Details?.title}</h1>
                            <h3 className='detailsSubHead'>{singleNews_Details?.short_desc}</h3>
                        </Col>
                        <Col sm={24} md={24} lg={2} className='detailsDiv_icon'>
                            <img style={{ cursor: 'pointer' }} src={Images.EditIcon} onClick={() => this.handleEdit()} />
                            <img style={{ cursor: 'pointer' }} src={Images.DeleteIcon} onClick={() => this.handleDelete()} />
                        </Col>
                    </Row>

                    <Row gutter={[10, 10]} style={{ marginTop: '10px' }} className='detailsDiv_Scroll'>
                        {(singleNews_Details?.newsDetails || []).map(details => {
                            return <Col span={24} className='detailsDiv_Scroll_img' style={{ width: '50%' }}>

                                <Col>
                                    <p>
                                        {details.description}
                                    </p>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} style={{ display: 'flex' }}>
                                    {
                                        (details?.newsMedia || []).map(media => {
                                            console.log('media: ', media);

                                            return <Col xs={24} sm={24} md={12} lg={6} className='detailsDiv_Scroll_img'
                                                style={{ marginTop: '10px' }}>
                                                {
                                                    media?.type == 'image' ?
                                                        <a href={media.media_url} target="_blank">
                                                            <img className='newDetails_imglist' src={media.media_url} style={{ width: '100%' }} />
                                                        </a>
                                                        :
                                                        <video width="320" height="240" controls>
                                                            <source src={media?.media_url} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                }

                                            </Col>
                                        })
                                    }
                                </Col>

                            </Col>
                        })}
                    </Row>
                    <Col span={24} style={{ marginTop: '5em' }}>
                        <button className='btnNewsClose' onClick={() => history.push('/news')}>Close</button>
                    </Col>
                    <div>
                        <Modal
                            open={deleteModal}
                            onClose={this.handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: '#FFFFFF' }}>
                                    Are you sure you want to delete ?
                                </Typography>
                                <Grid span={12} style={{ display: 'flex', marginTop: '50px' }} >
                                    <Col md={12}>
                                        <button className='btnModal' onClick={() => this.handleDeleteNews()}>Yes</button>
                                    </Col>
                                    <Col md={12}>
                                        <button className='btnModal' onClick={this.handleClose}>No</button>
                                    </Col>
                                </Grid>
                            </Box>
                        </Modal>
                    </div>
                </Grid>
            </Layout.DashboardHeader>
        )
    }
}
function mapStateToProps(state) {


    return {
        singleNews_Details: state.NewsReducer.singleNews_Details
    };
}

function mapDispatchToProps(dispatch) {
    return {
        GetNewsDetails: (id, cb) => dispatch(NewsAction.GetNewsDetails(id, cb)),
        Delete_News: (id) => dispatch(NewsAction.Delete_News(id)),

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewsDetails)