import {
    GETUSERS_SUCCESS, GETUSERS_FAILURE,
    LOADER_FALSE, LOADER_TRUE,
    // -----------------------------------------------
    GETUSERS,
    GETUSERDETAILS, GETUSERDETAILS_SUCCESS, GETUSERDETAILS_FAILURE,
    UPDATEUSER_STATUS, UPDATEUSERSTATUS_SUCCESS, UPDATEUSERSTATUS_FAILURE,
    DELETE_USER_PROFILE, DELETE_USERPROFILE_SUCCESS
} from '../constants'


export default class UserAction {

    static GetUserDetails(id, cb) {
        return {
            type: GETUSERDETAILS,
            id,
            // cb
        }
    }
    static GetUserDetails_Success(payload) {
        return {
            type: GETUSERDETAILS_SUCCESS,
            payload
        }
    }
    static GetUserDetails_Failure(payload) {
        return {
            type: GETUSERDETAILS_FAILURE,
            payload
        }
    }
    static UpdateUser_Status(payload) {


        return {
            type: UPDATEUSER_STATUS,
            payload
        }
    }
    static UpdateUserStatus_Success(payload) {

        return {
            type: UPDATEUSERSTATUS_SUCCESS,
            payload
        }
    }

    static Delete_UserProfile(payload) {
        
        return {
            type: DELETE_USER_PROFILE,
            id: payload.id,
            profileID: payload.profileId
        }
    }

    static Delete_UserProfile_Success() {

        return {
            type: DELETE_USERPROFILE_SUCCESS
        }
    }



    // -----------------------------------------------------------
    static GetUsers(like, status, page) {

        return {
            type: GETUSERS,
            like,
            status,
            page

        }
    }
    static GetUsersSuccess(payload) {

        return {
            type: GETUSERS_SUCCESS,
            payload
        }
    }
    static GetUsersFailure() {
        return {
            type: GETUSERS_FAILURE
        }
    }

    //travel plan

    static LoaderTrue() {
        return {
            type: LOADER_TRUE
        }
    }
    static LoaderFalse() {
        return {
            type: LOADER_FALSE
        }
    }

}