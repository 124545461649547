import React, { Component } from "react";
import { connect } from "react-redux";
import { Fonts, Utils, Images } from "../../config";
import Grid from '@mui/material/Grid';
import { View, TextField, Button, LoginBox } from '../../components'
import { AuthAction } from "../../store/actions";
import { Colors } from "../../config";
import { RiEyeLine, RiEyeCloseLine } from "react-icons/ri";
import { Input } from "antd";

class ChangePassword extends Component {
    constructor() {
        super()
        this.state = {
            oldPassword: '',
            checkOldPass: '',
            validOldPass: true,

            newPass: '',
            checkNewPass: '',
            validNewPass: true,

            confirmPass: '',
            checkConfirmPass: '',
            validConfirmPass: true,

            passErrMsg: "",
            oldPassType: false
        }
    }

    updatePass = () => {
        const { oldPassword, newPass, confirmPass } = this.state
        if (!oldPassword || !newPass || !confirmPass) {
            if (!oldPassword)
                this.setState({ checkOldPass: "Required fields can not be left empty.", validEmail: false, })
            if (!newPass)
                this.setState({ checkNewPass: "Please enter a valid email address." })
            if (!confirmPass)
                this.setState({ checkConfirmPass: "Required fields can not be left empty.", validPass: false })
        }
        else if (newPass !== confirmPass) return Utils.showSnackBar({ message: 'Confirm passowrd not match ', severity: "error" });
        else {
            this.props.changePassword({
                "oldPassword": oldPassword,
                newPassword: newPass
            }, () => this.props.history.push('/dashboard'))
        }
    }

    validateOldPass = (password) => {
        let validOldPass = Utils.passwordRegex.test(String(password));
        this.setState({ oldPassword: password, validOldPass, checkOldPass: "" })
    }
    validateNewPass = (password) => {
        let validNewPass = Utils.passwordRegex.test(String(password));
        this.setState({ newPass: password, validNewPass, checkNewPass: "" })
    }
    validateConfirmPass = (password) => {
        let validConfirmPass = Utils.passwordRegex.test(String(password));
        this.setState({ confirmPass: password, validConfirmPass, checkConfirmPass: "" })
    }

    render() {
        const {
            confirmPass,
            checkOldPass,
            checkNewPass,
            checkConfirmPass,
            newPass,
            oldPassType,
            oldPassword,
            validNewPass,
            validConfirmPass,
            validOldPass
        } = this.state

        const { ShadowView } = View
        const { history } = this.props;
        return (
            <LoginBox>
                <div className='goBack' onClick={() => history.replace('/login')}>
                    <img src={Images.GoBack} />
                    <p className='goBack_para_text'
                    >Back to login</p>
                </div>
                <Grid container item xl={4} lg={5} md={8} sm={10} xs={11}>
                    <ShadowView containerStyle={{ width: "100%", backgroundColor: Colors.White, borderRadius: 15 }}>
                        <Grid container spacing={2} style={{ flexDirection: "column", paddingBottom: 20, paddingTop: 20 }}>
                            <Grid item>
                                <p style={{
                                    fontFamily: Fonts["Poppins-SemiBold"],
                                    fontWeight: "bold",
                                    fontSize: 20,
                                    color: Colors.Secondary,
                                    marginTop: 10
                                }}>
                                    Change Password</p>
                                <label style={{
                                    fontFamily: Fonts["Poppins-Regular"],
                                    color: Colors.DarkBlue
                                }}>
                                    Choose a new password. Make sure its strong <br />and unique</label>
                            </Grid>
                            <Grid item>
                                <Input
                                    type={!oldPassType ? "password" : "text"}
                                    placeholder="Old password"
                                    name="oldPassword"
                                    value={oldPassword}
                                    style={{ width: "60%", borderRadius: 11, padding: '15px', border: '2px solid #D4D4D4' }}
                                    suffix={
                                        !oldPassType ? (
                                            <RiEyeCloseLine
                                                onClick={() => {
                                                    this.setState({ oldPassType: !oldPassType });
                                                }}
                                            />
                                        ) : (
                                            <RiEyeLine
                                                onClick={() => {
                                                    this.setState({ oldPassType: !oldPassType });
                                                }}
                                            />
                                        )
                                    }
                                    onChange={(e) => this.validateOldPass(e.target.value)}
                                />
                                <div style={{ color: Colors.Black }}> {checkOldPass} </div>
                            </Grid>

                            <Grid item>
                                <Input
                                    type={!validNewPass ? "password" : "text"}
                                    placeholder="Old password"
                                    name="oldPassword"
                                    value={newPass}
                                    style={{ width: "60%", borderRadius: 11, padding: '15px', border: '2px solid #D4D4D4' }}
                                    suffix={
                                        !validNewPass ? (
                                            <RiEyeCloseLine
                                                onClick={() => {
                                                    this.setState({ validNewPass: !validNewPass });
                                                }}
                                            />
                                        ) : (
                                            <RiEyeLine
                                                onClick={() => {
                                                    this.setState({ validNewPass: !validNewPass });
                                                }}
                                            />
                                        )
                                    }
                                    onChange={(e) => this.validateNewPass(e.target.value)}
                                />
                                <div style={{ color: Colors.Black }}> {checkNewPass} </div>
                            </Grid>

                            <Grid item>
                                <Input
                                    type={!validConfirmPass ? "password" : "text"}
                                    placeholder="Old password"
                                    name="oldPassword"
                                    value={confirmPass}
                                    style={{ width: "60%", borderRadius: 11, padding: '15px', border: '2px solid #D4D4D4' }}
                                    suffix={
                                        !validConfirmPass ? (
                                            <RiEyeCloseLine
                                                onClick={() => {
                                                    this.setState({ validConfirmPass: !validConfirmPass });
                                                }}
                                            />
                                        ) : (
                                            <RiEyeLine
                                                onClick={() => {
                                                    this.setState({ validConfirmPass: !validConfirmPass });
                                                }}
                                            />
                                        )
                                    }
                                    onChange={(e) => this.validateConfirmPass(e.target.value)}
                                />
                                <div style={{ color: Colors.Black }}> {checkConfirmPass} </div>
                            </Grid>


                            <Grid item>
                                <Button.Basic
                                    style={{
                                        backgroundColor: Colors.Secondary,
                                        textTransform: 'capitalize',
                                        color: Colors.White,
                                        width: "35%",
                                        borderRadius: "15px",
                                        borderColor: Colors.Secondary
                                    }}
                                    size="large"
                                    variant="outlined"
                                    text="Update"
                                    onClick={() => this.updatePass()}
                                    containerStyle={{ margin: "20px 0" }}
                                />
                            </Grid>
                        </Grid>
                    </ShadowView>
                </Grid>
            </LoginBox>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        changePassword: (payload, cb) => dispatch(AuthAction.changePassword(payload, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);