import React, { Component } from 'react'
import { connect } from "react-redux";
import { Row, Col, Input, message } from 'antd'
import { Layout } from '../../components'
import { Images, Colors } from '../../config'
import Basic from '../../components/TextField/Basic'
import Description from '../../components/TextField/Description'
import NewsActions from '../../store/actions/NewsActions';
import { Utils } from "../../config"
import { AiOutlinePlus, AiOutlineUpload, AiOutlineClose } from 'react-icons/ai'

const { TextArea } = Input;

class AddNews extends Component {
    multiFileArr = []
    constructor() {
        super()
        this.state = {

            descrip: [{ description: "", images: [] }],
            fileInputRef: "",

            payload: {
                image: '',
                title: "",
                shortDesc: "",
            },
            img: '',
            // newsDetail: [{ description: '' }, { description: '' }, { description: '' }],
            // newMedia: [], //{ media1: [1] }, { media3: [1] }

            newArr: [{ newsDetail: [{ description: '' }], }],
            newMedia: [{ media1: [] }],

            isPath: '',
            isTitleImgChange: false,
            detailImageLength: false,
            checkMediaSize: false
        }
    }
    componentDidMount() {
        const { match, GetNewDetails } = this.props
        if (match?.params?.id) {
            GetNewDetails(match?.params?.id)
        }

        const pathName = window.location.pathname;
        this.setState({
            isPath: pathName.includes("editnews") ? 'editnews' : 'addnews'
        })
    }

    componentDidUpdate(preProps) {

        if (preProps.NewsDetails !== this.props.NewsDetails) {
            this.setState({
                payload: {
                    ...this.state.payload,
                    image: this.props.NewsDetails.image,
                    title: this.props.NewsDetails.title,
                    shortDesc: this.props.NewsDetails.short_desc,
                },
                img: this.props.NewsDetails.image
            })
            this.setNewsDesMedia()
            this.createFile()
        }
    }

    createFile = async () => {
        const { NewsDetails } = this.props
        try {
            let response = await fetch(
                NewsDetails?.image
            );
            let data = await response.blob();
            let metadata = {
                type: "image/jpeg",
            };
            let file = new File([data], "test.jpg", metadata);
            // ... do something with the file or return it
            this.setState({
                payload: { ...this.state.payload, image: file }
                // fileObj: file,
            });

        } catch (error) {

        }

    };

    setNewsDesMedia = async () => {
        let { NewsDetails } = this.props
        this.setState({
            newMedia: [],
            newArr: [{ newsDetail: [] }]
        })
        if (NewsDetails?.newsDetails) {

            (NewsDetails?.newsDetails || []).forEach((e, index) => {
                var abc = []

                e.newsMedia?.forEach(async (v, i) => {
                    try {
                        let response = await fetch(
                            v.media_url
                        );
                        let data = await response.blob();
                        let metadata = {
                            type: "image/jpeg",
                        };
                        let file = new File([data], "test.jpg", metadata);
                        abc.push(file)
                    } catch (error) {

                    }

                })
                this.state.newArr[0].newsDetail.push({ description: e.description, id: e.id })

                this.state.newMedia.push({ [`media${index + 1}`]: abc })

                this.setState({
                    newMedia: this.state.newMedia,
                    newArr: this.state.newArr
                })
            });
        }


    }

    handleCreateNews = () => {
        const { payload, newArr, newMedia, checkMediaSize } = this.state


        const { CreateNews, history } = this.props
        if (checkMediaSize) return Utils.showSnackBar({ message: 'File size not supported', severity: "error" });

        const maximumImages = 4
        if (!payload?.image?.name || !payload?.title?.trim().length || !payload?.shortDesc?.trim().length) {
            if (!payload?.image.name) {
                Utils.showSnackBar({ message: 'Please select title image', severity: "error" });
            }
            if (!payload?.title) {
                Utils.showSnackBar({ message: 'News title cannot be left empty', severity: "error" });
            }
            if (!payload?.shortDesc) {
                Utils.showSnackBar({ message: 'Title description cannot be left empty', severity: "error" });
            }
            return
        }

        if (Array.from(newMedia).length > maximumImages) return Utils.showSnackBar({ message: 'Cannot upload more than 4 files', severity: "error" });

        CreateNews([newArr[0].newsDetail, newMedia, payload], () => history.push('/news'))
    }

    createNewMediaSectin = (newsData, ind) => {

        const { newArr, newMedia, detailImageLength } = this.state
        if (detailImageLength) return Utils.showSnackBar({ message: 'Cannot upload more than 4 files', severity: "error" });
        const newDesArr = [...newArr]
        const newMediaArr = [...newMedia]
        if (!newsData.description) return Utils.showSnackBar({ message: 'Description cannot be left empty', severity: "error" });
        newDesArr[0].newsDetail.push({ description: "" })
        if (newMedia.length > 3) return Utils.showSnackBar({ message: 'Media can not be uploded more then 4', severity: "error" });
        newMediaArr.push({ [`media${newMediaArr.length + 1}`]: [] })
        this.setState({ newArr: newDesArr, newMedia: newMediaArr })

    }

    onHandleChange = (desValue, index) => {

        const details = [...this.state.newArr]
        details[0].newsDetail[index].description = desValue
        this.setState({
            newArr: details
        })

    }

    handelImageChange = (file, index) => {
        const { checkMediaSize } = this.state
        this.setState({
            checkMediaSize: false
        })
        let limitofVideo = 104857600 //104857600
        let limitofPhoto = 15728640  //15728640

        const forImage = 'image'
        const forVideo = 'video'

        const checkType = Object.values(file.target.files)
        
        var size = Object.keys(file.target.files).length;



        checkType?.forEach(element => {


            if (element.type.includes(forImage) && element.size > 5 * 1024 * 1024) {
                this.setState({
                    checkMediaSize: true
                })
                return Utils.showSnackBar({ message: 'The image you are tyring to upload is greater than 15mb', severity: "error" });
            }
            else if (element.type.includes(forVideo) && element.size > limitofVideo) {
                this.setState({
                    checkMediaSize: true
                })
                return Utils.showSnackBar({ message: 'The video you are tyring to upload is greater than 100mb', severity: "error" });
            }
            return
        });






        if (size < 5) {
            for (let i = 0; i < size; i++) {

                if (file.target.files[i].size <= limitofVideo) {
                    this.multiFileArr = []
                    this.multiFileArr.push(file.target.files)


                    const arr = [...this.state.newMedia]

                    arr[index][`media${index + 1}`] = this.multiFileArr

                    this.setState({ newMedia: arr })

                    this.setState({ detailImageLength: false, checkMediaSize: false })

                }
            }
        }
        else {
            Utils.showSnackBar({ message: 'you can upload only 4 items', severity: "error" });
            this.setState({ detailImageLength: true })
        }



    }

    onChangeField = (e, key) => {
        this.setState({
            payload: {
                ...this.state.payload,
                [key]: e.target.value
            }
        })
    }

    cancleImage = (mainIndex, i, imageIndex) => {
        const { newMedia } = this.state
        this.state.newMedia = newMedia[mainIndex][`media${mainIndex + 1}`].splice(imageIndex, 1);
        this.setState({ newMedia })
    }

    updateNews = () => {
        const { EditNew, match, history } = this.props
        const { newArr, newMedia, payload, isTitleImgChange } = this.state



        const newsId = match?.params?.id
        if (isTitleImgChange) {
            EditNew([newArr[0].newsDetail, newMedia, payload], newsId, isTitleImgChange, () => history.push('/news'))
        }
        else {
            const newObjPayload = { ...payload }
            delete newObjPayload.image
            EditNew([newArr[0].newsDetail, newMedia, newObjPayload], newsId, isTitleImgChange, () => history.push('/news'))
        }
    }

    checkTitleImg = (e) => {
        let filename = e?.target?.files[0]?.name;
        let filesize = e?.target?.files[0]?.size;
        let fileExtension = filename.split(".").pop();
        this.setState({ isTitleImgChange: true })

        if (fileExtension == 'jpeg' || fileExtension == 'jpg' || fileExtension == 'png') {
            this.setState({
                img: URL.createObjectURL(e.target.files[0]),
                payload: {
                    ...this.state.payload,
                    image: e.target.files[0]
                }
            })
        }

        else if (filesize > 15000) return Utils.showSnackBar({ message: 'File should not be bigger then 15mbs', severity: "error" });
        else {
            Utils.showSnackBar({ message: 'Invalid file extension', severity: "error" });
        }
    }

    render() {
        const { history } = this.props
        const { newArr, payload, img, isPath, checkMediaSize } = this.state


        var binaryData = [];
        binaryData.push(payload?.image);

        return (
            <>
                <Layout.DashboardHeader
                    columnSpacing={5}
                    rowGap={5}
                    customizeStyle={{
                        display: "flex",
                        padding: 25,
                        backgroundColor: "#fffcfc",
                        marginLeft: 2,
                        justifyContent: "flex-start",
                    }}
                >
                    <Row >
                        <Col xs={24} style={{
                            display: 'flex',
                            cursor: 'pointer'
                        }}
                            onClick={() => history.push('/news')}
                        >
                            <img src={Images.GoBackFilled} />
                            <p className='goBack_para_text' style={{ color: Colors.Primary }}
                            >Back to News</p>
                        </Col>

                    </Row>
                    <Col xs={24} className='centerIt'>
                        <h1 className='MainHeading' >
                            {isPath == 'addnews' ? 'Add News' : 'Edit News'}
                        </h1>
                    </Col>
                    <Row gutter={[10, 20]} className='addNews' style={{ display: 'flex', alignItems: 'center' }}>
                        <Col className='uploadImgSection' xs={24} md={9} xl={7}>
                            <input
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                                style={{ display: "none" }}
                                ref={(ref) => (this.state.fileInputRef = ref)}
                                onChange={(e) => this.checkTitleImg(e)}
                            />
                            <img className='uploadImage'

                                src={payload?.image ? img : Images.UploadImage}
                                onClick={() => this.state.fileInputRef.click()} />

                        </Col>
                        <Col md={24} lg={15} xl={17}
                        >
                            <Row gutter={[10, 24]}>
                                <Col span={24}>
                                    <Basic style={{
                                        border: '1px solid #2BA596',
                                        borderRadius: 6,
                                        padding: 7,
                                        fontSize: 20
                                    }}
                                        value={payload?.title}
                                        placeholder={'Add Title Here'}
                                        showCount={true}
                                        maxLength={50}
                                        onChange={(e) => {
                                            this.onChangeField(e, 'title')
                                        }} />
                                </Col>
                                <Col span={24}>
                                    <Basic style={{
                                        border: '1px solid #2BA596',
                                        borderRadius: 6,
                                        padding: 7,
                                        fontSize: 20
                                    }}
                                        value={payload?.shortDesc}
                                        placeholder={'Add Description Here'}
                                        showCount
                                        // value='short description'
                                        maxLength={120}
                                        onChange={(e) => {
                                            this.onChangeField(e, 'shortDesc')
                                        }} />
                                </Col>
                            </Row>
                        </Col>
                        {(newArr?.[0].newsDetail || []).map((e, ind) => {

                            return (
                                <>
                                    <Col span={24}>
                                        <Description
                                            className='txtDescription'
                                            placeholder={'Type Here...'}
                                            showCount
                                            value={e?.description}
                                            maxLength={500}
                                            size={'large'}
                                            onChange={(e) => {
                                                this.onHandleChange(e.target.value, ind)

                                                // const description = [...this.state.descrip]
                                                // descrip[ind].description = e.target.value
                                                // this.setState({ description })
                                            }}
                                        />
                                    </Col>

                                    <div className='editNews_imgSec'>
                                        {newArr?.[0].newsDetail.length !== 4 && checkMediaSize !== true ?
                                            <div className='btnDashed' onClick={() => this.createNewMediaSectin(e, ind)}>
                                                <div className='btnIcons'>
                                                    <AiOutlinePlus />
                                                </div>
                                                <div>Add New</div>
                                            </div>
                                            :
                                            <div></div>
                                        }
                                        <div>
                                            <div className='btnDashed btn_uploadImg'>
                                                <div className='btnIcons'><AiOutlineUpload /></div>
                                                <div>
                                                    <input
                                                        type='file' multiple
                                                        accept="image/png, image/jpg, image/jpeg, video/mp4"
                                                        onChange={(e) => {
                                                            this.handelImageChange(e, ind)
                                                        }}
                                                    />
                                                    <br />
                                                    {/* Upload Image */}
                                                </div>
                                            </div>

                                            {isPath == 'editnews' ?
                                                <div>
                                                    {(this.state.newMedia[ind][`media${ind + 1}`] || []).map((i, index) => {

                                                        return (
                                                            <div className='uploaded_img'>
                                                                <p>
                                                                    {i?.name}
                                                                </p>
                                                                <div className='cancel_icon' onClick={() => this.cancleImage(ind, i, index)}>
                                                                    <AiOutlineClose />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                                :
                                                ''
                                            }
                                        </div>
                                    </div>
                                </>
                            )
                        })}





                        <Col
                            xs={24} md={24}
                        // span={12} offset={6}
                        >
                            {isPath == 'addnews' ?
                                <button
                                    className='btnSolid'
                                    // disabled={checkMediaSize ? true : false}
                                    onClick={() => this.handleCreateNews()}
                                >Add</button>
                                :

                                <button
                                    className='btnSolid'
                                    // disabled={checkMediaSize ? true : false}
                                    onClick={() => this.updateNews()}
                                >Update</button>
                            }

                        </Col>
                    </Row>
                </Layout.DashboardHeader>
            </>
        )
    }
}
function mapStateToProps(state) {

    return {
        NewsDetails: state.NewsReducer.singleNews_Details,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        GetNewDetails: (payload) => dispatch(NewsActions.GetNewsDetails(payload)),
        CreateNews: (payload, cb) => dispatch(NewsActions.CreateNews(payload, cb)),
        EditNew: (payload, id, isTitleimage, cb) => dispatch(NewsActions.EditNews(payload, id, isTitleimage, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNews)