import React from "react";
import Button from '@mui/material/Button';

const Basic = ({ variant, text, size, color, disabled, disableElevation, fullWidth, href, onClick, startIcon, endIcon, containerStyle, buttonStyle, sx, ...props }) => (
    <div style={containerStyle}>
        <Button
            variant={variant} // text | contained | outlined
            size={size} // 'small'| 'medium'| 'large'
            color={color}
            startIcon={startIcon}
            endIcon={endIcon}
            disabled={disabled}
            disableElevation={disableElevation}
            fullWidth={fullWidth}
            href={href}
            style={buttonStyle}
            onClick={onClick}
            sx={sx}

            {...props}
        >
            {text}
        </Button>
    </div>
)

export default Basic;
