import React from "react";
import Grid from "@mui/material/Grid";
import { Colors, Images } from "../../config";
import { Link } from "react-router-dom";
const Footer = ({ ...props }) => {
  const pathName = window.location.pathname;
  const isUser = pathName.includes("users");
  const isOrder = pathName.includes("orders");
  const isNews = pathName.includes("news")
  const isMessage = pathName.includes("message")
  const isReported = pathName.includes("reported")

  const isDashboard = !isUser && !isOrder && !isNews && !isMessage && !isReported;
  return (
    <Grid container item xs={12} sm={12} md={12} lg={12}>
      <Grid
        container
        columnSpacing={2}
        className="footer"
      >
        <Grid item md={12} lg={12}>
          <Link to="/">
            <img src={isDashboard ? Images.DashboardIconClicked : Images.DashboardIcon} style={{ cursor: "pointer" }}></img>
          </Link>
        </Grid>
        <Grid item md={12} lg={12}>
          <Link to="/users">
            <img src={isUser ? Images.UserIconClicked : Images.UsersIcon} style={{ cursor: "pointer" }}></img>
          </Link>
        </Grid>
        <Grid item md={12} lg={12}>
          <Link to="/news"><img src={isNews ? Images.FileListClicked : Images.FileList} style={{ cursor: "pointer" }}></img></Link>
        </Grid>
        <Grid item md={12} lg={12}>
          {/* <img src={Images.MsgsIcon} style={{ cursor: "pointer" }}></img> */}
          <Link to="/messages">  <img src={isMessage ? Images.MsgsIconClicked : Images.MsgsIcon} style={{ cursor: "pointer" }}></img> </Link>
        </Grid>
        <Grid item md={12} lg={12}>
          {/* <img src={Images.MsgsIcon} style={{ cursor: "pointer" }}></img> */}
          <Link to="/reported">  <img src={isReported ? Images.FlagIcon : Images.FlagIconClicked} style={{ cursor: "pointer" }}></img> </Link>
        </Grid>
      </Grid>
    </Grid>)
};

export default Footer;
