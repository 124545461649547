import React from "react";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from "@mui/lab/DateRangePicker";
import moment from "moment";
const DateRangerPicker = ({ startText, value, onChange, onOpen, onClick, isOpen, onClose, Image, containerStyle, pickerStyle, ...props }) => {
  // const [value, setValue] = React.useState(null);

  return (
    <div style={containerStyle}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
     
          outsideCurrentMonth={true}
          startText={startText}
          open={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          //open={this.state.isOpen}
          //endText="Check-out"
          value={value}
          onChange={onChange}
          renderInput={({ inputProps, ...startProps }, endProps) => {
            const startValue = inputProps.value;
            delete inputProps.value;
            
            return (
              <TextField
                sx={pickerStyle}
                InputProps={{
                  endAdornment: (
                    <img style={{ cursor: "pointer" }} src={Image} height={20} width={20} onClick={onClick} />
                  )
                }}
                {...startProps}
                inputProps={inputProps}
                value={`${startValue == "" ? "" : moment(startValue).format("MMMM YYYY")}  -  ${endProps?.inputProps?.value == "" ? "" : moment(endProps?.inputProps?.value).format("MMMM YYYY")}`}
              />
            );
          }}
        />
      </LocalizationProvider>
    </div>
  );
}

export default DateRangerPicker