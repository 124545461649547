import React, { Component } from "react";
import { connect } from "react-redux";
import { Fonts, Utils } from "../../config";
import Grid from '@mui/material/Grid';
import { View, TextField, Button, Popup } from '../../components'
import { AuthAction } from "../../store/actions";
import { LoginBox } from "../../components";
import { Images, Colors } from "../../config";
import { RiEyeLine, RiEyeCloseLine } from "react-icons/ri";
import moment from "moment";
import { Input } from "antd";
class SignIn extends Component {
    constructor() {
        super()
        this.state = {
            email: "",
            password: "",
            emailErrMsg: "",
            passErrMsg: "",
            iconClick: false,
            validEmail: true,
            validPass: true,
            isType: false
        }
    }
    signin = (e) => {
        e.preventDefault();
        const { email, password, validEmail, validPass } = this.state;
        const { SignIn, history } = this.props;
        let current_time = moment().format("YYYY-MM-DD HH:mm:ss");

        if (!email || !validEmail || !password || !validPass) {
            if (!email)
                this.setState({ emailErrMsg: "Required fields can not be left empty.", validEmail: false, })
            if (!validEmail)
                this.setState({ emailErrMsg: "Please enter a valid email address." })
            if (!password)
                this.setState({ passErrMsg: "Required fields can not be left empty.", validPass: false })
            if (!validPass)
                this.setState({ passErrMsg: "Password length must be atleast 8 char and must include an upper case and lower case char" })
        } else
            SignIn({ current_time, email, password }, () => history.push('/'))
    }

    validateEmail = (email) => {
        let validEmail = Utils.emailRegex.test(String(email).toLowerCase());
        this.setState({ email, validEmail, emailErrMsg: "" })
    }

    validatePass = (password) => {
        // let validPass = password.length > 5;
        let validPass = Utils.passwordRegex.test(String(password));
        this.setState({ password, validPass, passErrMsg: "" })
    }



    render() {
        const { email, password, emailErrMsg, passErrMsg, validEmail, validPass, isType } = this.state


        const { ShadowView } = View
        return (
            <LoginBox>

                <Grid container rowGap={5} item xl={3.5} lg={4.5} md={4.5} sm={10} xs={11} style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <div style={{ justifyContent: "center", width: "100%" }}><img src={Images.Logo} height={163} width={121}></img></div>
                    <ShadowView containerStyle={{ width: "100%", backgroundColor: Colors.White, borderRadius: "15px" }}>
                        <form onSubmit={this.signin}>
                            <Grid container spacing={2} style={{ flexDirection: "column" }}>
                                <Grid item>
                                    <p style={{ fontFamily: Fonts["Poppins-SemiBold"], fontWeight: "bold", fontSize: 14, color: Colors.Secondary, marginTop: '10px' }}>Welcome</p>
                                    <label style={{ fontFamily: Fonts["Poppins-Regular"], color: Colors.DarkBlue }}>Please Login to Continue</label>
                                </Grid>
                                <Grid item>
                                    <TextField.Basic

                                        // label={"Email"} //Outlined //Filled //Standard
                                        // variant={"standard"} //outlined //filled //standard
                                        type={"email"}
                                        defaultValue={email}
                                        placeholder={"Email"}
                                        fieldStyle={{ width: "60%", borderRadius: 11, padding: '15px', border: '2px solid #D4D4D4' }}
                                        error={!validEmail}
                                        //helperText={emailErrMsg}
                                        onChange={(e) => this.validateEmail(e.target.value)}
                                        customStyle={{
                                            backgroundColor: "white",
                                            // border: "none",

                                            // width: { sm: 250, md: 350 },
                                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {

                                                borderColor: Colors.Border
                                            },
                                            "& .MuiOutlinedInput-root:hover": {
                                                "& > fieldset": {
                                                    borderColor: Colors.Primary,

                                                }
                                            }
                                        }}
                                    />
                                    <span style={{ color: Colors.Black }}> {emailErrMsg} </span>
                                </Grid>
                                <Grid item>
                                    <Input
                                        type={!isType ? "password" : "text"}
                                        placeholder="Password"
                                        name="password"
                                        value={password}
                                        style={{ width: "60%", borderRadius: 11, padding: '15px', border: '2px solid #D4D4D4' }}
                                        suffix={
                                            !isType ? (
                                                <RiEyeCloseLine
                                                    onClick={() => {
                                                        this.setState({ isType: !isType });
                                                    }}
                                                />
                                            ) : (
                                                <RiEyeLine
                                                    onClick={() => {
                                                        this.setState({ isType: !isType });
                                                    }}
                                                />
                                            )
                                        }
                                        onChange={(e) => this.validatePass(e.target.value)}
                                    />
                                    <div style={{ color: Colors.Black }}> {passErrMsg} </div>
                                    <h4 onClick={() => this.props.history.push('/forget-password')} style={{ fontFamily: Fonts["Poppins-SemiBold"], color: Colors.Secondary, cursor: "pointer", marginTop: '10px' }}>Forgot Password?</h4>
                                </Grid>
                                <Grid item>
                                    <Button.Basic
                                        variant="outlined"
                                        style={{ backgroundColor: Colors.Secondary, textTransform: 'capitalize', color: Colors.White, width: "35%", borderRadius: 15, borderColor: Colors.Secondary, fontSize: 16 }}
                                       type='submit'
                                        text="Login"
                                        // onClick={}
                                        containerStyle={{ margin: "20px 0" }}
                                        size="large"
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </ShadowView>
                </Grid>
            </LoginBox>
        )
    }
}


function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        SignIn: (payload, cb) => dispatch(AuthAction.SignIn(payload, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);