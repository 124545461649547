import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import style from "./style.css";
import TextField from "@material-ui/core/TextField";
import { Colors, Images } from "../../config";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { FiCheck } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { DashboardAction } from "../../store/actions";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { RiCalendarLine } from "react-icons/ri";
import { makeStyles } from "@material-ui/core/styles";
import { MonthRangePicker } from "../../components";
import MenuItem from "@mui/material/MenuItem";
const genders = [
  {
    value: "confirmed",
    label: "confirmed",
  },
  {
    value: "pending",
    label: "pending",
  },
  {
    value: "responded",
    label: "responded",
  },
  {
    value: "expired",
    label: "expired",
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const Index = (props) => {
  const classes = useStyles();

  const {
    userImage,
    userCount,
    userHeading,
    isOrder,
    isTotal,
    isActive,
    isBanned,
    isOpen,
    handleOpen,
    handleClose,
    bg_Img,
  } = props;
  const [status, setStatus] = useState("");
  const [dropDown, setDropDown] = useState(false);
  const [dropUp, setDropUp] = useState(true);
  const [value, setValue] = useState([
    moment().subtract(1, "months"),
    moment(),
  ]);

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [gender, setGender] = React.useState("");

  const handleChange = (event) => {
    setGender(event); //confirmed,pending,responded,expired,all
    handleClose()
  };

  useEffect(() => {
    let currentDate = moment().format("YYYY-MM-DD");
    let previousDate = moment().subtract(1, "months").format("YYYY-MM-DD");
    isTotal
      ? dispatch(
        DashboardAction.GetUsersCardCount(currentDate, previousDate, "all")
      )
      : isActive
        ? dispatch(
          DashboardAction.GetUsersCardCount(currentDate, previousDate, "active")
        )
        : isBanned
          ? dispatch(
            DashboardAction.GetUsersCardCount(currentDate, previousDate, "banned")
          )
          : dispatch(
            DashboardAction.GetUsersCardCount(
              currentDate,
              previousDate,
              "deleted"
            )
          ); //"active,banned,deleted,newUsers,all"
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={10}
      md={2.8}
      lg={2.8}
      style={{
        boxShadow: "0 10px 30px -12.125px rgba(0,0,0,0.1)",
        border: "2px solid white",
        height: 120,
        borderRadius: 10,
        backgroundImage: `url(${Images.BackgroundImage})`,
        backgroundSize: 'cover'
      }}
    // onClick={()=>  setDropDown(true)}
    >
      <Grid
        item
        xs={11}
        sm={11}
        md={11}
        lg={11}
        // elevation={4}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          style={{
            justifyContent: "flex-start",
            display: "flex",
            marginTop: "6px",
          }}
        >
          <img style={{ width: 40, height: 40 }} src={userImage} />
        </Grid>
        {isOrder ? (
          <>
            <Grid item xs={7} sm={7} md={7} lg={7}>
              <div style={{ position: "relative", cursor: "pointer" }}>
                <div
                  style={{
                    padding: 3,
                    borderRadius: 20,
                    backgroundColor: "#1B2A46",
                    height: 30,
                    justifyContent: "center",
                    display: "flex",
                    marginTop: 7,
                    justifyContent: "space-around",
                  }}
                  onClick={() => {
                    handleOpen()
                  }}
                >
                  <div
                    style={{

                    }}
                  >
                    {" "}
                    <p style={{ color: "white" }}>
                      {gender === "" ? "All" : gender}
                    </p>
                  </div>
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {!isOpen ? (
                      <AiOutlineDown size={12} color={"#DB5C5C"} />
                    ) : (
                      <AiOutlineUp size={12} color={"#DB5C5C"} />
                    )}
                  </div>
                </div>
                <Grid
                  style={{ position: "absolute", top: 35 }}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  {isOpen ? (
                    <div
                      style={{
                        backgroundColor: "#1B2A46",
                        padding: 5,
                        borderRadius: 20,
                        cursor: "pointer",

                      }}
                    >
                      <div
                        onClick={() => handleChange("All")}
                        style={{
                          color: "white",
                          justifyContent: "flex-start",
                          display: "flex",
                        }}
                      >
                        <p style={{ margin: 5 }}>
                          All{" "}

                        </p>
                      </div>
                      <div
                        onClick={() => handleChange("Confirmed")}
                        style={{
                          color: "white",
                          justifyContent: "flex-start",
                          display: "flex",
                        }}
                      >
                        <p style={{ margin: 5 }}>
                          Confirmed{" "}

                        </p>
                      </div>
                      <div
                        onClick={() => handleChange("Pending")}
                        style={{
                          color: "white",
                          justifyContent: "flex-start",
                          display: "flex",
                        }}
                      >
                        <p style={{ margin: 5 }}>Pending</p>
                      </div>

                      <div
                        onClick={() => handleChange("Responded")}
                        style={{
                          color: "white",
                          justifyContent: "flex-start",
                          display: "flex",
                        }}
                      >
                        <p style={{ margin: 5 }}>Responded</p>
                      </div>
                      <div
                        onClick={() => handleChange("Expired")}
                        style={{
                          color: "white",
                          justifyContent: "flex-start",
                          display: "flex",
                        }}
                      >
                        <p style={{ margin: 5 }}>Expired</p>
                      </div>
                    </div>
                  ) : null}
                </Grid>
              </div>
            </Grid>

            <Grid
              item
              xs={0.5}
              sm={0.5}
              md={0.5}
              lg={0.5}
              style={{ marginTop: "6px" }}
            >

            </Grid>
          </>
        ) : (
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            style={{
              justifyContent: "flex-end",
              display: "flex",
              alignItems: "center",
            }}
          >

          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={11}
        sm={11}
        md={10}
        lg={10}
        style={{
          justifyContent: "flex-start",
          display: "flex",
          marginBottom: 0,
          marginTop: 10,
        }}
      >
        <h4 style={{ color: Colors.White }}>{userHeading}</h4>
      </Grid>
      <Grid
        item
        xs={11}
        sm={11}
        md={10}
        lg={10}
        style={{ justifyContent: "flex-start", display: "flex" }}
      >
        <p style={{ color: Colors.White }}>{userCount}</p>
      </Grid>
    </Grid>

    // </Grid>
  );
};

export default Index;
