import {
    SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAILURE,
    LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILURE,
    FORGETPASSWORD, FORGETPASSWORD_SUCCESS, FORGETPASSWORD_FAILURE,
    RESETPASSWORD, RESETPASSWORD_SUCCESS, RESETPASSWORD_FAILURE,
    VERIFYCODE, VERIFYCODE_SUCCESS, VERIFYCODE_FAILURE,
    GET_POSTS, GET_POSTS_SUCCESS, GET_POSTS_FAILURE,
    LOADER_FALSE, LOADER_TRUE,
    CHANGE_PASSWORD,CHANGE_PASSWORD_FAILURE,CHANGE_PASSWORD_SUCCESS
} from '../constants'


export default class AuthAction {
    static SignIn(payload, cb) {
        return {
            type: SIGNIN,
            payload,
            cb
        }
    }
    static SignInSuccess(payload) {
        return {
            type: SIGNIN_SUCCESS,
            payload
        }
    }
    static SignInFailure() {
        return {
            type: SIGNIN_FAILURE
        }
    }

    static Logout() {
        return {
            type: LOGOUT
        }
    }
    static LogoutSuccess() {
        return {
            type: LOGOUT_SUCCESS
        }
    }
    static LogoutFailure() {
        return {
            type: LOGOUT_FAILURE
        }
    }

    static ForgetPassword(payload, cb) {
        return {
            type: FORGETPASSWORD,
            payload,
            cb
        }
    }
    static ForgetPasswordSuccess(payload) {
        return {
            type: FORGETPASSWORD_SUCCESS,
            payload
        }
    }
    static ForgetPasswordFailure() {
        return {
            type: FORGETPASSWORD_FAILURE
        }
    }
    static ResetPassword(payload, cb) {
        return {
            type: RESETPASSWORD,
            payload,
            cb
        }
    }
    static ResetPasswordSuccess(payload) {
        return {
            type: RESETPASSWORD_SUCCESS,
            payload
        }
    }
    static ResetPasswordFailure() {
        return {
            type: RESETPASSWORD_FAILURE
        }
    }

    static VerifyCode(payload, cb) {
        return {
            type: VERIFYCODE,
            payload,
            cb
        }
    }
    static VerifyCodeSuccess(payload) {
        return {
            type: VERIFYCODE_SUCCESS,
            payload
        }
    }
    static VerifyCodeFailure() {
        return {
            type: VERIFYCODE_FAILURE
        }
    }

    static changePassword(payload, cb) {
        return {
            type: CHANGE_PASSWORD,
            payload,
            cb
        }
    }
    static changePasswordSuccess(payload) {
        return {
            type: CHANGE_PASSWORD_SUCCESS,
            payload
        }
    }
    static changePasswordFaliur() {
        return {
            type: CHANGE_PASSWORD_FAILURE
        }
    }

    static LoaderTrue() {
        return {
            type: LOADER_TRUE
        }
    }
    static LoaderFalse() {
        return {
            type: LOADER_FALSE
        }
    }

}