import React, { Component } from "react";
import { connect } from "react-redux";
import { Fonts, Utils, Images } from "../../config";
import Grid from "@mui/material/Grid";
import { View, TextField, Button, LoginBox } from "../../components";
import ReactCodeInput from "react-code-input";
import { AuthAction } from "../../store/actions";
import { Colors } from "../../config";
class VerifyCode extends Component {
  constructor() {
    super();
    this.state = {
      email: null,
      code: "",
      error: "",
      timer: false,
      countDown: 60,
      timerId: null,
      renderCodeInput: false,
    };
  }


  verifyCode = () => {
    let isValid = this.state.error;
    const { VerifyCode, history } = this.props;
    const usrEmail = this.props.location.state.email
    if (this.state.code.length == 0) {
      this.setState({ error: "Required fields can not be left empty " })
    }
    else {
      if (isValid == "") {
        VerifyCode(
          {
            email: usrEmail,
            code: this.state.code,
          },
          (data, error) => {
            data
              ? history.push({
                pathname: "/reset-password",
                state: {
                  email: usrEmail,
                },
              })
              : console.log("wrong code");
          }
        );
      } else {
        console.error();
      }
    }
  };

  handleChanged = (val) => {
    this.setState({
      code: val,
    });
    this.handleErrors(val);
  };

  handleErrors = (a) => {
    let error = "";
    if (a.length == 0) {
      error = "Please enter the code ";
    } else if (a.length < 4 && a.length > 0) {
      error = "Incomplete Code ";
    } else {
      error = "";
    }
    this.setState({ error: error });
    return error;
  };

  resendApiCall = () => {
    const { ForgetPassword } = this.props;
    const usrEmail = this.props.location.state.email
    this.setState({ code: "", renderCodeInput: !this.state.renderCodeInput });
    var id = setInterval(() => {
      if (this.state.countDown === 1) {
        this.setState({ timer: false, countDown: 60 });
        clearInterval(this.state.timerId);
      } else {
        this.setState({ countDown: this.state.countDown - 1 });
      }
    }, 1000);

    this.setState({ timerId: id, timer: true });
    ForgetPassword(usrEmail, () => {
      //console.log("Reset Fields");
    });
  };
  render() {
    const { email, password, emailErrMsg, passErrMsg, validEmail, validPass } =
      this.state;
    const { ShadowView } = View;
    const { history } = this.props;
    return (
      <LoginBox>
        <div className='goBack' onClick={() => history.replace('/login')}>
          <img src={Images.GoBack} />
          <p className='goBack_para_text'
          >Back to login</p>
        </div>
        <Grid container item xl={4} lg={5} md={8} sm={10} xs={11}>
          <ShadowView
            containerStyle={{ width: "100%", backgroundColor: Colors.White, borderRadius: "15px" }}
          >
            <Grid container spacing={2} style={{ flexDirection: "column", paddingBottom: 20, paddingTop: 20 }}>
              <Grid item>
                <p
                  style={{
                    fontFamily: Fonts["Poppins-SemiBold"],
                    fontWeight: "bold",
                    fontSize: 20, color: Colors.Secondary, marginTop: '10px'
                  }}
                >
                  Verification Code
                </p>
                <label
                  style={{
                    fontFamily: Fonts["Poppins-Regular"],
                    color: Colors.DarkBlue
                  }}
                >
                  Enter the verification code you just recieved in <br /> your
                  inbox.
                </label>
              </Grid>
              <Grid item>
                <ReactCodeInput
                  type="number"
                  inputStyle={{
                    height: "50px",
                    width: "35px",
                    textAlign: "center",
                    fontFamily: "monospace",
                    margin: "4px",
                    MozAppearance: "textfield",
                    borderRadius: "6px",
                    //fontSize: '14px',
                    //paddingLeft: '7px',
                    backgroundColor: "white",
                    color: "black",
                    border: "2px solid #2AA395",
                  }}
                  onChange={(e) => {
                    this.handleChanged(e);
                  }}
                />
              </Grid>
              <Grid item>
                <span style={{ color: Colors.Black }}>{this.state.error}</span>
              </Grid>
              <Grid item>
                <Button.Basic
                  style={{
                    backgroundColor: Colors.Secondary,
                    color: Colors.White,
                    width: "35%",
                    borderRadius: "15px",
                    borderColor: Colors.Secondary,
                    textTransform: 'capitalize'
                  }}
                  size="large"
                  variant="outlined"
                  text="Continue"
                  onClick={this.verifyCode}
                  containerStyle={{ margin: "20px 0" }}
                />
              </Grid>
            </Grid>
          </ShadowView>
          <Grid container style={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
            <label style={{ textDecoration: "underline", cursor: "pointer", color: Colors.White, fontStyle: 'italic' }} onClick={() => {
              this.resendApiCall();
            }}>Resend code</label></Grid>
        </Grid>

      </LoginBox>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    VerifyCode: (payload, cb) => dispatch(AuthAction.VerifyCode(payload, cb)),
    ForgetPassword: (email, cb) => {
      dispatch(AuthAction.ForgetPassword(email, cb));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCode);
