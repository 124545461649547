import React from "react";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const Floating = ({ variant, size, color, sx, text, icon, onClick, containerStyle, buttonStyle, ...props }) => {
    return (
        <div style={containerStyle}>
            <Fab
                variant={variant} //circular | extended
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    ...sx
                }}
                size={size} //small | medium | large
                color={color} //primary | secondary | inherit
                style={buttonStyle}
                onClick={onClick}
                {...props}
            >
                {icon || <AddIcon />}
                {text}
            </Fab>
        </div>
    );
}

export default Floating