import LineChart from "./LineChart"
import PieChart from "./PieChart"
import BarChart from "./BarChart"
import ResponsiveContainer from "./ResponsiveContainer"
export default {
    LineChart,
    PieChart,
    BarChart,
    ResponsiveContainer,
 
};