import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Colors, Images } from "../../config";
import { RiShutDownLine, RiEdit2Line } from "react-icons/ri";
import useMedia from "use-media";
import SideBar from "./sideBar";
import Footer from "./Footer";
import { AuthAction } from "../../store/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col, Modal } from 'antd';

import { Avatar, Menu, Dropdown, Divider } from 'antd';
import { UserOutlined, LogoutOutlined, } from "@ant-design/icons";

const DashboardHeader = ({
  customizeStyle,
  columnSpacing,
  rowSpacing,
  rowGap,
  click,
  ...props
}) => {
  const { children } = props;
  const history = useHistory()
  const isWide = useMedia({ minWidth: "900px" });
  const dispatch = useDispatch();
  const [userFullname, setFullName] = useState()
  const [nameAplha, setNameAplha] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)


  function getName() {
    const { full_name } = JSON.parse(localStorage.getItem('ratemydate'))
    setFullName(full_name)
    const fullName = full_name.split(' ');
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    const name = initials.toUpperCase()
    return setNameAplha(name)
  }

  useEffect(() => {
    getName()
  }, [])

  const handleCloseConfirmModal = () => {
    setIsModalOpen(false)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }
  const logout = () => {
    dispatch(AuthAction.Logout());
    setIsModalOpen(false)
  }
  const menu = (
    <Menu>
      <>
        <div className="userDetialsDiv">
          <p className="userNamePara">{userFullname}</p>
          <p className="loginAs">Admin</p>
          <Divider />
        </div>
        <Menu.Item>
          <div style={{ marginRight: "54px", cursor: "pointer" }} onClick={() => {
            history.push('change-password')
          }}>
            <RiEdit2Line size={16} style={{ color: Colors.White }} /> Change Password
          </div>
        </Menu.Item>
        <Menu.Item>
          <div style={{ marginRight: "54px", cursor: "pointer" }} onClick={() => {
            handleOpenModal()
            // dispatch(AuthAction.Logout());
            // alert('Logout Clicked')
          }}>
            <RiShutDownLine size={16} style={{ color: Colors.Danger }} /> Logout
          </div>
        </Menu.Item>
      </>
      <Modal
        visible={isModalOpen} footer={null} onCancel={() => handleCloseConfirmModal()} className='delConfirmModal'
      >
        <Row>
          <Col>
            <h2 style={{ color: '#FFFFFF' }}>
              Are you sure you want to logout ?
            </h2>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={24} style={{ display: 'flex', marginTop: '50px' }} >
            <Col md={12}>
              <button className='btnModal'
                onClick={() => logout()}
              >
                Yes
              </button>
            </Col>
            <Col md={12}>
              <button className='btnModal'
                onClick={() => handleCloseConfirmModal()}
              >No</button>
            </Col>
          </Col>
        </Row>
      </Modal>
    </Menu>
  );

  return (
    <Grid container>
      {isWide ? <SideBar /> : null}
      <Grid
        container
        item
        xs={!isWide ? 12 : 11}
        sm={!isWide ? 12 : 9}
        md={11}
        lg={11.2}
        style={{ height: "100%" }}
      >
        <Grid className="headerDiv"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >

          <Dropdown overlay={menu}>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <span className="userNameDiv">{nameAplha}</span>
            </a>
          </Dropdown>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={11.6}
          style={{ ...customizeStyle, width: '100%' }}
          rowGap={rowGap}
          rowSpacing={rowSpacing}
          columnSpacing={columnSpacing}
        >
          {children}
        </Grid>
        {!isWide ? <Footer /> : null}
      </Grid>
    </Grid>
  );
};

export default DashboardHeader;
