import Axios from "axios";
//import AsyncStorage from "@react-native-community/async-storage";
import { AuthAction } from "../../store/actions";
import Store from "../../store";
import moment from "moment";
//import { NavigationService } from "..";
import { API_BASE_URL } from "../variables";
import { Utils } from "../../config"
//import moment from "moment";
//const { baseUrl } = API_BASE_URL


Axios.interceptors.response.use((response) => {
    return response
}, ({ response }) => {
    
    if (response.status == 401) {
        //
        try {
            localStorage.removeItem("ratemydate");
            Store.dispatch(AuthAction.LogoutSuccess());
            window.location.reload();
            Utils.showSnackBar({ message: response.data.message, severity: "error" })
        }
        catch (err) {
        }
    }
    return response
})

Axios.interceptors.request.use(function (config) {
    if (localStorage.getItem("ratemydate")) {
        config.headers = {
            ...config?.headers,
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("ratemydate"))?.token} `,
            // 'userId': JSON.parse(localStorage.getItem("ratemydate"))?.userId,
            // "current_time": moment().format("YYYY-MM-DD HH:mm:ss")
        }
        return config;
    }
    else {
        return config;
    }
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
const CancelToken = Axios.CancelToken;


export default class ApiCaller {

    static Get = (endPoint = "", headers = {}, customUrl = "") => {
        
        if (headers["user_id"]) {
            headers["user-id"] = headers["user_id"]
            headers["access-token"] = headers["access_token"]
        }
        return Axios.get(customUrl ? customUrl : `${API_BASE_URL}${endPoint}`, {
            timeout: 10000,
            headers: { "Content-Type": "application/json", "access-control-allow-origin": "*", ...headers }
        }).then((res) => res).catch((err) => err.response)
    }

    static Post = (endPoint = "", body = {}, headers = {}) => {
        //
        if (headers["user_id"]) {
            headers["user-id"] = headers["user_id"]
            headers["access-token"] = headers["access_token"]
        }
        return Axios.post(`${API_BASE_URL}${endPoint}`, body, {
            timeout: 10000,
            headers: { "Content-Type": "application/json", "access-control-allow-origin": "*", ...headers }
        }).then((res) => res).catch((err) => err.response)
    }

    static Put = (endPoint = "", body = {}, headers = {}, customUrl = "") => {
        if (headers["user_id"]) {
            headers["user-id"] = headers["user_id"]
            headers["access-token"] = headers["access_token"]
        }
        return Axios.put((customUrl ? customUrl : `${API_BASE_URL}${endPoint}`), body, {
            timeout: 10000,
            headers: { "Content-Type": "application/json", ...headers }
        }).then((res) => res).catch((err) => err.response)
    }

    static Delete = (endPoint = "", headers = {}, body = {}, customUrl = "") => {
        if (headers["user_id"]) {
            headers["user-id"] = headers["user_id"]
            headers["access-token"] = headers["access_token"]
        }
        return Axios.delete((customUrl ? customUrl : `${API_BASE_URL}${endPoint}`), {
            timeout: 10000,
            headers,
            data: body
        }).then((res) => res).catch((err) => err.response)
    }
    static Patch = (endPoint = "", headers = {}, body = {}, customUrl = "") => {
        if (headers["user_id"]) {
            headers["user-id"] = headers["user_id"]
            headers["access-token"] = headers["access_token"]
        }
        return Axios.patch((customUrl ? customUrl : `${API_BASE_URL}${endPoint}`), {
            timeout: 10000,
            headers,
            data: body
        }).then((res) => res).catch((err) => err.response)
    }
}

export { CancelToken, ApiCaller };