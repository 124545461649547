import { Colors } from "../../config";

const styles = {
    container: {
        height:"100vh",
        display: "flex",
        flexFlow: "column wrap",
        backgroundColor: Colors.White,
        justifyContent: 'center', 
    },
    shadowViewContainer: {
        position: "relative",
        display: "flex",
        flexFlow: "row wrap",
        backgroundColor: Colors.White,
        justifyContent: 'center',
        // alignItems: 'center',
        margin: 20,
        padding: "60px 20px",
        // gap: "10px 20px"
    },
    ShadowViewSample: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    SampleViewElevation: {
        elevation: 5
    },
}

export default styles