import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    SignIn,
    ForgetPassword,
    VerifyCode,
    ResetPassword
} from "../../containers";
import AppRoutes from "./AppRoutes";

class AuthRoutes extends React.Component {
    render() {
        
        return (
            <div className="App">
                <Switch>
                    {!this.props.user?.id ? (
                            <Switch>
                                <Route exact path="/login" component={SignIn} />
                                 <Route exact path="/forget-password" component={ForgetPassword} />
                                <Route exact path="/verify-code" component={VerifyCode} /> 
                                <Route exact path="/reset-password" component={ResetPassword} />
                                <Route path="*" render={() => <Redirect to="/login" />} />
                            </Switch>
                    ) : (
                            <Switch>
                                <Route path="/" component={AppRoutes} />
                            </Switch>
                    )}
                </Switch>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.AuthReducer.user,
    }
}

export default withRouter(
    connect(mapStateToProps)(AuthRoutes)
)