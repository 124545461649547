import React from "react";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';

const BasicTimePicker = ({ value, onChange, containerStyle, sx, ...props }) => {
    // const [value, setValue] = React.useState(null);

    return (
        <div style={containerStyle}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    label="Select Time"
                    value={value || new Date()}
                    onChange={onChange}
                    renderInput={(params) => <TextField sx={{ width: 200, ...sx  }} {...params} {...props} />}
                />
            </LocalizationProvider>
        </div>
    );
}

export default BasicTimePicker