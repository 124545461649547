import React, { Component } from "react";
import { connect } from "react-redux";
import { Fonts, Utils, Images } from "../../config";
import Grid from '@mui/material/Grid';
import { View, TextField, Button, LoginBox } from '../../components'
import { AuthAction } from "../../store/actions";
import { Colors } from "../../config";

class ForgetPassword extends Component {
    constructor() {
        super()
        this.state = {
            email: "",
            password: "",
            emailErrMsg: "",
            passErrMsg: "",
            validEmail: true,
            validPass: true
        }
    }
    forgetPassword = () => {
        const { email, validEmail } = this.state;
        const { forgetpassword, history } = this.props;
        if (!email)
            this.setState({ emailErrMsg: "Required fields can not be left empty.", validEmail: false, })
        else if (!validEmail)
            this.setState({ emailErrMsg: "Please enter a valid email address" })
        else
            forgetpassword(email, (isTrue) => {
                isTrue ?
                    history.push({
                        pathname: "/verify-code",
                        state: {
                            email: email,
                        },
                    })
                    : console.log("");
            })
    }

    validateEmail = (email) => {
        let validEmail = Utils.emailRegex.test(String(email).toLowerCase());
        this.setState({ email, validEmail, emailErrMsg: "" })
    }

    render() {
        const { email, emailErrMsg, validEmail } = this.state
        const { ShadowView } = View
        const { history } = this.props;
        return (
            <LoginBox>
                <div className='goBack' onClick={() => history.replace('/login')}>
                    <img src={Images.GoBack} />
                    <p className='goBack_para_text'
                    >Back to login</p>
                </div>
                <Grid container item xl={4} lg={5} md={8} sm={10} xs={11}>
                    <ShadowView containerStyle={{ width: "100%", backgroundColor: Colors.White, borderRadius: 15 }}>
                        <Grid container spacing={2} style={{ flexDirection: "column", paddingBottom: 20, paddingTop: 20 }}>
                            <Grid item>
                                <p style={{
                                    fontFamily: Fonts["Poppins-SemiBold"],
                                    fontWeight: "bold",
                                    fontSize: 20,
                                    color: Colors.Secondary,
                                    marginTop: 10
                                }}>
                                    Reset Password</p>
                                <label style={{
                                    fontFamily: Fonts["Poppins-Regular"],
                                    color: Colors.DarkBlue
                                }}>
                                    Enter the email address that you used to <br /> create account.</label>
                            </Grid>
                            <Grid item>
                                <TextField.Basic

                                    // label={"Email"} //Outlined //Filled //Standard
                                    // variant={"standard"} //outlined //filled //standard
                                    type={"email"}
                                    defaultValue={email}
                                    placeholder={"Email"}
                                    fieldStyle={{ width: "60%", borderRadius: 11, padding: 15, border: '2px solid #D4D4D4' }}
                                    error={!validEmail}
                                    onChange={(e) => this.validateEmail(e.target.value)}
                                    customStyle={{
                                        backgroundColor: "white",
                                        // border: "none",

                                        // width: { sm: 250, md: 350 },
                                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            // borderColor: Colors.Border
                                            border: '2px solid #D4D4D4'
                                        },
                                        "& .MuiOutlinedInput-root:hover": {
                                            "& > fieldset": {
                                                borderColor: Colors.Primary
                                            }
                                        }
                                    }}
                                />
                                <span style={{ color: Colors.Black }}> {emailErrMsg} </span>
                            </Grid>
                            <Grid item>
                                <Button.Basic
                                    style={{
                                        backgroundColor: Colors.Secondary,
                                        textTransform: 'capitalize',
                                        color: Colors.White,
                                        width: "35%",
                                        borderRadius: "15px",
                                        borderColor: Colors.Secondary
                                    }}
                                    size="large"
                                    variant="outlined"
                                    text="Continue"
                                    onClick={this.forgetPassword}
                                    containerStyle={{ margin: "20px 0" }}
                                />
                            </Grid>
                        </Grid>
                    </ShadowView>
                </Grid>
            </LoginBox>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        forgetpassword: (payload, cb) => dispatch(AuthAction.ForgetPassword(payload, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);