import React, { Component } from "react";
import { connect } from "react-redux";
import { Fonts, Utils, Images } from "../../config";
import Grid from '@mui/material/Grid';
import { View, TextField, Button, LoginBox } from '../../components'
import { AuthAction } from "../../store/actions";
import { Colors } from "../../config";
import { FiCheck } from "react-icons/fi";
import { RiEyeLine, RiEyeCloseLine } from "react-icons/ri";
import moment from "moment";
import { message, Input } from 'antd'

class ResetPassword extends Component {
    constructor() {
        super()
        this.state = {
            oldpassword: "",
            currentpassword: "",
            oldpasswordErrMsg: "",
            currentpasswordErrMsg: "",
            validoldpassword: true,
            validcurrentpassword: true,
            iconClick1: false,
            isTypePass: false,
            isTypeCorrect: false,
            // check1: false,
            // check2: false,
            // check3: false,
            email: ""

        }
    }
    componentDidMount() {
        const { history } = this.props;
        if (history.location.state != undefined) {
            if (history.location.state.email != undefined) {
                this.setState({
                    email: history.location.state.email,
                });
            } else {
                history.push({
                    pathname: "/login",
                });
            }
        } else {
            history.push({
                pathname: "/login",
            });
        }
    }


    resetPassword = () => {
        let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
        const { oldpassword, currentpassword, validoldpassword, validcurrentpassword } = this.state;

        const { ResetPassword, history } = this.props;

        const { email } = history.location.state
        if (!oldpassword || !validoldpassword || !currentpassword || oldpassword !== currentpassword) {
            if (!oldpassword)
                this.setState({ oldpasswordErrMsg: "Required fields can not be left empty.", validoldpassword: false, })
            if (!validoldpassword)
                this.setState({ oldpasswordErrMsg: "Password length must be atleast 8 char and must include an upper case and lower case char" })
            if (!currentpassword)
                this.setState({ currentpasswordErrMsg: "Required fields can not be left empty.", validcurrentpassword: false })
            if ((oldpassword !== currentpassword) && currentpassword)
                this.setState({ currentpasswordErrMsg: "new password and confirm password should be the same" })
        }
        else
            ResetPassword({ email, password: currentpassword, current_time }, () => this.props.history.push('/login'))
    }


    // validatePass = (password) => {
    //     // let validPass = password.length > 5;
    //     let validoldpassword = Utils.passwordRegex.test(String(password));
    //     this.setState({ password, oldpassword: password, validoldpassword, oldpasswordErrMsg: "" })
    //     if (password.length >= 8) {
    //         this.setState({ check1: true });
    //     } else {
    //         this.setState({ check1: false });
    //     }

    //     if (/[A-Z]/.test(password)) {
    //         this.setState({ check2: true });
    //     } else {
    //         this.setState({ check2: false });
    //     }

    //     if (/[a-z]/.test(password)) {
    //         this.setState({ check3: true });
    //     } else {
    //         this.setState({ check3: false });
    //     };
    // }



    render() {
        const { email, currentpassword, oldpassword, currentpasswordErrMsg, oldpasswordErrMsg, validoldpassword, validcurrentpassword, isTypePass, isTypeCorrect } = this.state
        const { ShadowView } = View;
        const { history } = this.props;

        return (
            <LoginBox>
                <div className='goBack' onClick={() => history.replace('/login')}>
                    <img src={Images.GoBack} />
                    <p className='goBack_para_text'
                    >Back to login</p>
                </div>
                <Grid container item xl={4} lg={5} md={8} sm={10} xs={11}>
                    <ShadowView containerStyle={{ width: "100%", backgroundColor: Colors.White, borderRadius: 15 }}>
                        <Grid container spacing={2} style={{ flexDirection: "column", paddingBottom: 20, paddingTop: 20 }}>
                            <Grid item>
                                <p style={{ fontFamily: Fonts["Poppins-SemiBold"], fontWeight: "bold", fontSize: 20, color: Colors.Secondary }}>Reset Password</p>
                                <label style={{ fontFamily: Fonts["Poppins-Regular"], color: Colors.DarkBlue }}>Choose a new password Make sure its strong <br /> and unique.</label>
                            </Grid>
                            <Grid item>
                                <Input
                                    type={!isTypePass ? "password" : "text"}
                                    placeholder="Password"
                                    name="password"
                                    // value={password}
                                    style={{ width: "60%", borderRadius: 11, padding: '15px', border: '2px solid #D4D4D4' }}
                                    suffix={
                                        !isTypePass ? (
                                            <RiEyeCloseLine
                                                onClick={() => {
                                                    this.setState({ isTypePass: !isTypePass });
                                                }}
                                            />
                                        ) : (
                                            <RiEyeLine
                                                onClick={() => {
                                                    this.setState({ isTypePass: !isTypePass });
                                                }}
                                            />
                                        )

                                    }

                                    onChange={(e) => {
                                        this.setState({ oldpassword: e.target.value, oldpasswordErrMsg: "" })
                                    }}
                                />
                                {/* <TextField.Basic
                                    // label={"Password"} //Outlined //Filled //Standard
                                    // variant={"filled"} //outlined //filled //standard
                                    type={this.state.iconClick1 ? "email" : "password"}
                                    defaultValue={oldpassword}
                                    placeholder={"New Password"}
                                    fieldStyle={{ width: "60%", borderRadius: 11, padding: 15, border: '2px solid #D4D4D4' }}
                                    error={!validoldpassword}
                                    InputProps={{
                                        endAdornment: (
                                            !this.state.iconClick1 ?
                                                <RiEyeCloseLine onClick={() => this.setState({ iconClick1: !this.state.iconClick1 })} color={Colors.Secondary} />
                                                : <RiEyeLine onClick={() => this.setState({ iconClick1: !this.state.iconClick1 })} color={Colors.Secondary} />
                                        )
                                    }}



                                    // helperText={passErrMsg}
                                    customStyle={{
                                        backgroundColor: "white",


                                        width: { sm: 250, md: 350 },
                                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            borderColor: Colors.Primary
                                        },
                                        "& .MuiOutlinedInput-root:hover": {
                                            "& > fieldset": {
                                                borderColor: Colors.Primary
                                            }
                                        }

                                    }}
                                    onChange={(e) => {
                                        this.setState({ oldpassword: e.target.value, oldpasswordErrMsg: "" })
                                    }}
                                // onChange={(e) => this.validatePass(e.target.value)}
                                /> */}
                                <div style={{ color: Colors.Black }}> {oldpasswordErrMsg} </div>

                            </Grid>

                            <Grid item>
                                {/* <TextField.Basic
                                    // label={"Password"} //Outlined //Filled //Standard
                                    // variant={"filled"} //outlined //filled //standard
                                    type={this.state.iconClick ? "email" : "password"}
                                    defaultValue={currentpassword}
                                    placeholder={"Confirm Password"}
                                    fieldStyle={{ width: "60%", borderRadius: 11, padding: 15, border: '2px solid #D4D4D4' }}
                                    error={!validcurrentpassword}
                                    InputProps={{
                                        endAdornment: (
                                            !this.state.iconClick ?
                                                <RiEyeCloseLine onClick={() => this.setState({ iconClick: !this.state.iconClick })} color={Colors.Secondary} />
                                                : <RiEyeLine onClick={() => this.setState({ iconClick: !this.state.iconClick })} color={Colors.Secondary} />
                                        )
                                    }}



                                    // helperText={passErrMsg}
                                    customStyle={{
                                        backgroundColor: "white",


                                        width: { sm: 250, md: 350 },
                                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            borderColor: Colors.Primary
                                        },
                                        "& .MuiOutlinedInput-root:hover": {
                                            "& > fieldset": {
                                                borderColor: Colors.Primary
                                            }
                                        }

                                    }}
                                    onChange={(e) => {
                                        this.setState({ currentpassword: e.target.value, currentpasswordErrMsg: "" })

                                    }}
                                /> */}






                                <Input
                                    type={!isTypeCorrect ? "password" : "text"}
                                    placeholder="Password"
                                    name="password"
                                    // value={password}
                                    style={{ width: "60%", borderRadius: 11, padding: '15px', border: '2px solid #D4D4D4' }}
                                    suffix={
                                        !isTypeCorrect ? (
                                            <RiEyeCloseLine
                                                onClick={() => {
                                                    this.setState({ isTypeCorrect: !isTypeCorrect });
                                                }}
                                            />
                                        ) : (
                                            <RiEyeLine
                                                onClick={() => {
                                                    this.setState({ isTypeCorrect: !isTypeCorrect });
                                                }}
                                            />
                                        )

                                    }

                                    onChange={(e) => {
                                        this.setState({ currentpassword: e.target.value, currentpasswordErrMsg: "" })

                                    }}
                                />
                                <div style={{ color: Colors.Black }}> {currentpasswordErrMsg} </div>

                            </Grid>
                            {/* <Grid item style={{ display: "flex", justifyContent: "center" }}>
                                    <div style={{ backgroundColor: Colors.Dark, width: "60%", height: 125, borderRadius: 15, paddingLeft: 12 }}>
                                        <Grid item lg={12} md={12} style={{ textAlign: "left" }}><p style={{ color: Colors.White }}>  <FiCheck color={this.state.check1 ? Colors.Secondary :'white' } />      8+ Characters</p> </Grid>
                                        <Grid item lg={12} md={12} style={{ textAlign: "left" }}><p style={{ color: Colors.White }}>  <FiCheck color={this.state.check2 ? Colors.Secondary :'white' } />     1 Uppercase</p></Grid>
                                        <Grid item lg={12} md={12} style={{ textAlign: "left" }}><p style={{ color: Colors.White }}><FiCheck color={this.state.check3 ? Colors.Secondary :'white' }/>     1 Lowercase</p></Grid>
                                    </div>

                                </Grid> */}

                            <Grid item>
                                <Button.Basic
                                    style={{
                                        backgroundColor: Colors.Secondary,
                                        textTransform: 'capitalize',
                                        color: Colors.White,
                                        width: "35%",
                                        borderRadius: "15px",
                                        borderColor: Colors.Secondary,
                                        marginTop: 55
                                    }}
                                    size="large"
                                    variant="outlined"
                                    text="Update"
                                    onClick={this.resetPassword}
                                    containerStyle={{ margin: "20px 0" }}
                                />
                            </Grid>
                        </Grid>
                    </ShadowView>
                </Grid>
            </LoginBox>
        )
    }
}



function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        ResetPassword: (payload, cb) => dispatch(AuthAction.ResetPassword(payload, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);