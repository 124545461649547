import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import UserReducer from './UserReducer';
import DashboardReducer from './DashboardReducer';
import NewsReducer from './NewsReducer';
import MessageReducer from './MessageReducer';
import ReportedUser_Reducer from './ReportedUsersReducer';

const RootReducer = combineReducers({
    AuthReducer,
    UserReducer,
    DashboardReducer,
    NewsReducer,
    MessageReducer,
    ReportedUser_Reducer
});

export default RootReducer;