import { DashboardAction } from "../actions";
import { ApiCaller } from "../../config";
import { put, delay } from "redux-saga/effects";
import { Utils } from "../../config";
import moment from "moment";
import { GETUSERCARDSCOUNT_ENDPOINT,NEWUSERSGRAPH_ENDPOINT,ANDROIDGRAPH_ENDPOINT } from "../../config/variables";
//const abc=[]
export default class DashboardMiddleware {
 
    static *GetUserCardCount(params) {
        const { to, from, userType, cb } = params;

        try {
            let res = yield ApiCaller.Get(`${GETUSERCARDSCOUNT_ENDPOINT}?user=${userType}&to=${to}&from=${from}`);

          if (res.status == 200) {
            yield put(DashboardAction.GetUsersCardCountSuccess(res.data.data));
          } else {
            yield put(DashboardAction.GetUsersCardCountFailure());
            //   Info("error", res.data.error.message);
              Utils.showSnackBar({message: res.data.error.message, severity:"error" })
            if (cb) {
              cb(false);
            }
          }
        } catch (err) {
          yield put(DashboardAction.GetUsersCardCountFailure());
        }
      }
      
 // UserGraph 

  
  static * UserGraph(action) {
    
    //const { params } = action.params;
    try {
      const { params: { to, from } } = action;
      let res=  yield ApiCaller.Get(`${NEWUSERSGRAPH_ENDPOINT}?startDate=${to}&endDate=${from}`);
      

      if (res.status == 200) {
        //localStorage.setItem("ratemydate", JSON.stringify(res.data.success.data));
        //   Info("success", res.data.data.message);
        //Utils.showSnackBar({ message: res.data.success.message })
        // if (cb) {
        //   cb(true);
        // }
        yield put(DashboardAction.userGraphSuccess(res.data.data));
      } else {
        yield put(DashboardAction.userGraphError());
        //   Info("error", res.data.error.message);
        Utils.showSnackBar({ message: res.data.error.message, severity: "error" })
        // if (cb) {
        //   cb(false);
        // }
      }
    } catch (err) {
      yield put(DashboardAction.userGraphError());
    }
  }


  //Android and IO Users

    
  static *AndroidGraph(action) {
    
    // const { params } = action.params;
    try {
      // const {  cb,...params } = params;
      const qureyParams = new URLSearchParams(action.params).toString();
      let res = yield ApiCaller.Get(`${ANDROIDGRAPH_ENDPOINT}?${qureyParams}`);
      
      if (res.status == 200) {
        //localStorage.setItem("ratemydate", JSON.stringify(res.data.success.data));
        //   Info("success", res.data.data.message);
        //Utils.showSnackBar({ message: res.data.success.message })
        // if (cb) {
        //   cb(true);
        // }
        yield put(DashboardAction.androidGraphSuccess(res.data.data));
      } else {
        yield put(DashboardAction.androidGraphError());
        //   Info("error", res.data.error.message);
        Utils.showSnackBar({ message: res.data.error.message, severity: "error" })
        // if (cb) {
        //   cb(false);
        // }
      }
    } catch (err) {
      yield put(DashboardAction.androidGraphError());
    }
  }

 

}
