import React from "react";
import propTypes from "prop-types";
import Grid from "@mui/material/Grid";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { withTheme } from "styled-components";
import camelCaseToTitle from "../../config/util/index";
var dataKey;
const CustomTooltip = ({ payload }) => {

  return (
    <>
      {payload.map((item) => {
        // 
        if (item.value === dataKey) {
          return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div item
                style={{
                  backgroundColor: item.payload.iOS > item.payload.android ? "#FFC300" : item.stroke,
                  whiteSpace: "nowrap",
                  width: "52px",
                  height: "auto",
                  borderRadius: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <p
                  className="recharts-tooltip-label"
                  style={{
                    color: "black",
                    margin: 0
                  }}
                >
                  {item.payload.android ? item.payload.android : item.payload.iOS ? item.payload.iOS : item.payload.user}
                  <br />
                  {item.payload.name}
                </p>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};
class renderLineChart extends React.Component {
  state = {
    updateXAxis: false,
  };
  handleClick = (e) => {
    // 
    dataKey = e.activeLabel;
  };
  componentWillUpdate(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.setState({ updateXAxis: !this.state.updateXAxis });
    }
  }
  renderLegend = (props) => {
    const { payload } = props;
    const COLORS = ["#415465", "#2BA596", "#F8604E", "#FF8042"];
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {payload
          .filter((item) => item.dataKey !== "name")

          // item => 

          .map((entry, index) => (

            <>
              <div
                style={{
                  borderRadius: 15,
                  width: 15,
                  height: 15,
                  backgroundColor:
                    entry.value === "iOS" ? "red" : COLORS[index],
                  margin: 20,
                }}
              ></div>
              <p style={{ margin: 0 }}>{entry.value}</p>
            </>
          ))}
      </div>
    );
  };

  render() {
    var obj;
    // 
    if (this.props.data[0]) obj = Object.keys(this.props.data[0]);
    const COLORS = ["#CC0E74", "#FFC300", "#F8604E", "#FF8042"];
    obj.shift()
    // 
    return (
      <div>
        <LineChart
          width={this.props.width}
          height={this.props.height}
          data={this.props.data.length !== 0 ? this.props.data : [{ empty: 0 }]}
          margin={this.props.margin}
          onMouseMove={e => {
            // 
            this.handleClick(e)
          }}
        >
          <XAxis
            dataKey="name"
            padding={{ left: 30, right: 30 }}
            axisLine={{ stroke: "#fde8f1" }}
          />
          <Tooltip />
          <YAxis padding={{ top: 155 }} axisLine={{ stroke: "#fde8f1" }} />
          {obj.map((item, index) => {
            // 
            return (
              <Line
                key={index.toString()}
                type={this.props.lineType}
                dataKey={item}
                stroke={index == "1" ? "#415465" :
                  "#2BA596"}
              />
            );
          })}

          <Legend
            verticalAlign="bottom"
            height={36}
            content={this.renderLegend}
          />
        </LineChart>
      </div>
    );
  }
}

renderLineChart.defaultProps = {
  width: "100%",
  height: 300,
  margin: { top: 0, right: 0, left: 0, bottom: 0 },
  lineType: "monotone",
  isCartReq: false,
  cartStroke: "#f0f0f0",
  cartDash: 5,
  isTooltip: true,
};

renderLineChart.propTypes = {
  data: propTypes.array.isRequired,
  lineColor: propTypes.array.isRequired,
};
export default withTheme(renderLineChart);
