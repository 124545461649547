import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Layout,
  TextField
} from "../../components";
import { Colors } from "../../config";
import { HiOutlineSearch } from "react-icons/hi";
import { UserAction } from "../../store/actions";
import { Utils } from "../../config";
import { Grid } from "@mui/material";
import { Table, Avatar, Select, Switch, Pagination } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { RiCheckLine } from 'react-icons/ri'

const { history } = Utils;
const { Option } = Select;

class Users extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      current: 1,
      like: "",
      userStatus: '',
    };
  }

  navigate = () => {
    history.goBack();
  };


  componentDidMount() {
    const { GetUsers } = this.props
    GetUsers("", "", 1);
  }
  handleSearch = (like) => {
    const { GetUsers } = this.props;
    const { page, userStatus } = this.state;
    GetUsers(like, userStatus, 1);
    this.setState({ like: like, current: 1 });
  }
  handleChange = (value) => {

    const { GetUsers } = this.props;
    const { like } = this.state;
    this.setState({
      userStatus: value
    })

    GetUsers(like, value, 1);
    // value==='active'?1:value==='banned'?2:0

  }

  handlePages = (pageNumber) => {
    const limit = 10;
    let offset = (pageNumber - 1) * limit;
    const { GetUsers } = this.props;
    const { like, userStatus } = this.state;
    GetUsers(like, userStatus, pageNumber);
    this.setState({ offset: offset, current: pageNumber, page: pageNumber });
  };

  render() {
    const { userCount, users, history, UpdateUser_Status } = this.props;

    const { current, isStatusClicked } = this.state;



    const columns = [
      {
        title: '',
        dataIndex: 'profile_image_thumbnail',
        key: 'profile_image_thumbnail',
        render: (i) => <div>{i ? <Avatar src={i} /> : <Avatar icon={<UserOutlined />} />}</div>
      },
      {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'full_name',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'No. of Profile',
        dataIndex: 'profiles_count',
        key: 'profiles_count',
        render: (r) => <div>{r ? r : 0}</div>
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (e) =>
          <div>
            {e == 0 ?
              <p className="tblUnverified">Unverified</p> : e == 1 ? <p className="tblActive">Active</p> : <p className="tblBanned">In-Active</p>
            }</div>

      },
      {
        title: 'Action',
        dataIndex: 'status',
        key: 'status',
        render: (isactive, user) => <div onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
          {
            <div>
              <Switch
                checked={isactive == 1 ? false : true}
                onChange={(e) => {
                  
                  UpdateUser_Status({
                    userID: user.id,
                    // status: user.status == true ? 0 : 1,
                    // status: user.status == 1 ? 2 : user.status == 2 ? 1 : 1,
                    // status: user.status == 1 ? 'inactive' : user.status == 2 ? 'active' : 'active',
                    status: user.status == 1 ? 2 : user.status == 2 ? 1 : 1,
                    filterStatus: this.state.userStatus,
                    page: this.state.page,
                    limit: 10,
                    like: ''
                  })
                }} />
            </div>

          }

        </div>
      }
    ]

    return (
      <Layout.DashboardHeader
        columnSpacing={5}
        customizeStyle={{
          display: "flex",
          padding: 25,
          backgroundColor: "#fffcfc",
          marginLeft: 2,
          width: '20rem'
        }}
      >
        <div className="usersSearchDiv">
          <div className="userListHeading">
            User Details
          </div>
          <div className="userFilterDiv">

            <div className="status_filter">
              <Select
                className="statusSearch"
                defaultValue=""
                dropdownStyle={{ backgroundColor: '#1c2b3f', color: '#FFFFFF' }}
                // style={{ width: 200, marginRight: 10 }}
                menuItemSelectedIcon={
                  <RiCheckLine
                    style={{ color: '#2BA596', height: "100%" }}
                  />
                }


                onChange={(e) => this.handleChange(e)}
              >

                <Option value="">All Users</Option>
                <Option value="active">Active User</Option>
                <Option value="inactive">In-Active User</Option>
                <Option value="unverified">Unverified Users</Option>
              </Select>
            </div>

            <div className="textSearch">
              <TextField.SearchTextField
                InputProps={{
                  maxlength: 250,
                  startAdornment: (
                    <HiOutlineSearch size={20} color={Colors.Secondary} />
                  )
                }}
                style={{ borderRadius: '11px' }}
                sx={{
                  borderRadius: "11px",
                  border: `2px solid ${Colors.Primary}`,
                  borderRadius: 5,
                  width: "270px",
                  backgroundColor: "#1C2B3F",
                  "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    color: Colors.White,

                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    // (default alpha is 0.38)
                  },
                  ".MuiInputBase-adornedStart": {
                    color: "white !important"
                  },
                  "&.css-12vqcob-MuiInputBase-input-MuiOutlinedInput-input": {
                    color: "white"
                  },

                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {

                    display: "none",
                  },

                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      display: "none",
                    },
                  },
                }}
                placeholder="Search"

                dropdownIcon
                onChange={(e) => {
                  this.handleSearch(e.target.value);
                }}
              />
            </div>

          </div>

        </div>

        <Grid
          item sm={12} xl={12} md={12} lg={12}
          style={{ overflow: 'scroll', paddingLeft: '0' }}
          className="userListDiv"
        >

          <Table dataSource={users} columns={columns} pagination={false} style={{ cursor: 'pointer' }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  history.push({
                    pathname: `/users/user-detail`,
                    state: {
                      id: record.id,
                    },
                  });
                }
              };
            }}
          />
        </Grid>
        <Grid container item md={12} lg={12} className="userList_pagination" style={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
          <Pagination
            responsive="true"
            showLessItems="true"
            current={current}
            pageSize={10}
            total={userCount ? userCount : 0}
            onChange={(p) => {
              this.handlePages(p)
            }}
          />
        </Grid>
      </Layout.DashboardHeader>
    );
  }
}

function mapStateToProps(state) {
  return {

    users: state.UserReducer.users,
    userCount: state.UserReducer.userCount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    GetUsers: (like, status, offset) => dispatch(UserAction.GetUsers(like, status, offset)),
    // ---------------------------------------------------------------------------------------
    UpdateUser_Status: (payload) => dispatch(UserAction.UpdateUser_Status(payload))

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
