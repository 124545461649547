import SideBar from "./sideBar"
import DashboardHeader from "./DashboardHeader"
import Footer from "./Footer"
// import Floating from "./Floating"
// import Toggle from "./Toggle"

export default {
    SideBar,
    DashboardHeader,
    Footer
    // Floating,
    // Toggle
};