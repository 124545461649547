import React, { Component } from 'react'
import { connect } from "react-redux";
import { NewsAction } from "../../store/actions";
import { Row, Col } from 'antd';
import { Colors, Images } from '../../config'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { AiOutlineFileImage } from 'react-icons/ai'


class NewsCard extends Component {
    constructor() {
        super()
        this.state = {
            open: false
        }
    }
    handleDelete = () => {
        const { Delete_News } = this.props;
        const { currentPage, details } = this.props

        const objDelNews = {
            pageNumber: currentPage,
            search: '',
            id: details?.id
        }
        Delete_News(objDelNews)
        this.setState({
            open: false
        })
    }

    render() {
        const handleOpen = () => {
            this.setState({
                open: true
            })
        }
        const handleClose = () => {
            this.setState({
                open: false
            })
        }

        const { image_thumbnail, title, short_desc, id } = this.props.details
        const { open } = this.state;

        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundImage: 'linear-gradient(#2ead9d, #115c54)',
            border: 'none',
            borderRadius: '11px',
            boxShadow: 24,
            p: 4,
        };

        return (
            <>
                <Row gutter={[10, 10]} className='news_card' onClick={() => this.props.history.push({
                    pathname: '/news/news-Details',
                    state: {
                        id,
                    }
                })}>
                    <Col sm={24}
                        md={4}
                        lg={3}
                    >
                        {image_thumbnail ?
                            <img src={image_thumbnail} style={{ width: '100px', borderRadius: '11px' }} /> :
                            <AiOutlineFileImage />
                        }
                    </Col>
                    <Col
                        // span={18}
                        sm={24}
                        md={17}
                    >
                        <Row justify='left'>
                            <Col span={24} align="left">
                                <h1 style={{ fontSize: 20, color: Colors.Primary }}>
                                    {title}
                                </h1>
                            </Col>
                            <Col
                                span={24}
                                align="left">
                                <p style={{ fontSize: 14, color: Colors.Black }}>
                                    {short_desc}
                                </p>
                            </Col>
                        </Row>
                    </Col >
                    <Col
                        // span={2} 
                        sm={24}
                        md={2}
                    >
                        <Row justify="space-around"
                            sm={24}
                            md={24}
                            gutter={[15, 15]}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <Col span={12}
                                sm={5, 10}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}

                            >
                                <img style={{ cursor: 'pointer' }} src={Images.EditIcon} onClick={()=>this.props. getCardId(id)} />
                            </Col>
                            <Col span={12}
                                sm={5, 10}
                            >
                                <img style={{ cursor: 'pointer' }} src={Images.DeleteIcon} onClick={handleOpen} />
                            </Col>
                        </Row>
                    </Col>
                </Row >
                <hr />
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: '#FFFFFF' }}>
                                Are you sure you want to delete ?
                            </Typography>
                            <Grid span={12} style={{ display: 'flex', marginTop: '50px' }} >
                                <Col md={12}>
                                    <button className='btnModal' onClick={() => this.handleDelete()}>Yes</button>
                                </Col>
                                <Col md={12}>
                                    <button className='btnModal' onClick={handleClose}>No</button>
                                </Col>
                            </Grid>
                        </Box>
                    </Modal>
                </div>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {

        users: state.UserReducer.users,
        userCount: state.UserReducer.userCount,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        Delete_News: (id) => dispatch(NewsAction.Delete_News(id)),

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewsCard)