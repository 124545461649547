import { createStore, applyMiddleware } from 'redux';
import RootReducer from './reducers';
import createSagaMiddleware from 'redux-saga'
import { Sagas } from "./sagas"

const sagaMiddleware = createSagaMiddleware()

const Store = createStore(
    RootReducer,
    {},
    applyMiddleware(
        sagaMiddleware
    )
);

sagaMiddleware.run(Sagas)

export default Store;